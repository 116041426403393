import style from './DataUpdateInfo.module.css';

import React from 'react';
import Api from 'components/Api';
import Button from 'components/ui/Button';
import useTranslation from 'components/hooks/useTranslation';

export default function DataUpdateInfo({ planMinInterval = 15 }) {
  const { t } = useTranslation();

  return (
    <div className="DataUpdateInfo">
      <div className="mt-4">
        <span
          className={style.text}
          dangerouslySetInnerHTML={{ __html: t('data.update.info', [planMinInterval]) }}
        />
      </div>

      <Button
        icon="rocket_launch"
        className={style.buttonAction}
        onClick={() => {
          const url = Api.buildUrl('/spr/bng/marketplace', {
            currentTab: 'plans',
          });
          window.location.replace(url);
        }}
      >
        {t('upgrade.now')}
      </Button>
    </div>
  );
}
