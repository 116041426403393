import './WhatsAppDialog.css';

import React, { useEffect, useMemo, useState } from 'react';

import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { BngTable } from 'components/bng/ui/BngTable';
import StackTraceDialog from 'components/bng/pages/errors/StackTraceDialog';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { ConsumptionItem } from 'components/bng/accounts/ConsumptionTab';
import PaginateTable from 'components/ui/common/PaginateTable';
import Utils from 'components/Utils';
import AddonType from 'components/bng/accounts/AddonType';

const TABLE_INITIAL_PASS = 6;
const TABLE_PER_PAGE_LIMIT = 10;

export default function WhatsAppDialog({ accountId, plan, closeModal }) {
  const context = useBimContext();

  return (
    <Dialog title={context.msg.t('addon.whats.api.name')} onClose={closeModal} className="WhatsAppDialog xlarge">
      <Dialog.Body>
        <WhatsAppConsumption accountId={accountId} plan={plan} />
      </Dialog.Body>
    </Dialog>
  );
}

export function WhatsAppConsumption({ account, plan }) {
  const context = useBimContext();

  const [whatsRequestsPage, setWhatsRequestsPage] = useState({
    totalElements: 0,
    content: [],
  });
  const [sentMessagesCount, setSentMessagesCount] = useState('0');
  const [planCapacity, setPlanCapacity] = useState(800);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchRequests = async (page = 0, size = TABLE_INITIAL_PASS) => {
    const requests = await Api.MobileMessageRequest.findRequests(account.id, page, size);
    setWhatsRequestsPage(requests);
  };

  const fetchResourceUsage = async () => {
    const startDate = moment().startOf('month');
    const endDate = moment().endOf('month');
    const result = await Api.MobileMessageRequest.findResourceUsage(account.id, startDate, endDate);
    setSentMessagesCount(result.data.sentMessages);
  };

  const exportToCsv = async () => {
    try {
      const startDate = moment().startOf('month');
      const endDate = moment().endOf('month');
      Api.MobileMessageRequest.exportToCsv(account.id, startDate, endDate);
    } catch (e) {
      console.error('Error on exportToCsv', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const fetchData = async (page = 0, size = TABLE_INITIAL_PASS) => {
    try {
      await fetchRequests(page, size);
      const whatsInfo = await Api.Account.findAccountAddonInfo(account.id, AddonType.WHATS_APP.key);
      setPlanCapacity(whatsInfo.props.params?.value || 800);
    } catch (e) {
      console.error('Error on findRequests', { accountId: account.id, page, size }, e);
      UiMsg.ajaxError(null, e);
      return;
    }

    try {
      await fetchResourceUsage();
    } catch (e) {
      console.error('Error on findResourceUsage', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const handleChangePage = async (pageNumber, firstLoad, perPage) => {
    await fetchData(Number(pageNumber) - 1, perPage);
  };

  const handleChangeNumberPerPage = async (perPage, pageNumber) => {
    await fetchData(Number(pageNumber) - 1, perPage);
  };

  const capacity = useMemo(
    () => ({
      additionalCapacity: '0',
      availableCapacity: '0',
      calculatedPercent: null,
      capacity: planCapacity,
      currentUsage: sentMessagesCount,
      customizableInPlan: false,
      detailHtml: null,
      formattedAdditionalCapacity: '0',
      formattedCapacity: '10',
      formattedPercentUsage: '0',
      formattedUsage: sentMessagesCount,
      icon: null,
      label: 'SENT_MESSAGES',
      renderType: 'CHART',
      resource: 'MESSAGES',
      styleClass: 'infobox-red',
      totalCapacity: '0',
    }),
    [sentMessagesCount, planCapacity],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        label: context.msg.t('type'),
        render: ({ resourceType, resourceName }) => {
          if (resourceType === 'SHARE') {
            return (
              <div className={'messaging-table-user-resource-type'}>
                <span title={context.msg.t('sharing')} className="material-icons resource-type">
                  share
                </span>
                <span>{context.msg.t('sharing') + ': ' + resourceName}</span>
              </div>
            );
          }
          return (
            <div className={'messaging-table-user-resource-type'}>
              <span title={context.msg.t('scheduling')} className="material-icons resource-type">
                schedule
              </span>
              <span>{context.msg.t('scheduling') + ': ' + resourceName}</span>
            </div>
          );
        },
      },
      {
        label: context.msg.t('sent.by.created.by'),
        render: ({ sender }) => {
          if (!sender) {
            return;
          }
          return <span className={'messaging-table-user-display-name'}>{sender.displayName}</span>;
        },
      },
      {
        label: context.msg.t('success.error'),
        render: (whatsRequest) => {
          let success = 0;
          let failed = 0;
          whatsRequest.mobileMessages.forEach((message) => {
            if (message.status === 'SENT') {
              success++;
            } else {
              failed++;
            }
          });
          failed += whatsRequest.excludedMembersCount;
          return (
            <div className={'messaging-table-user-success-error'}>
              <span className="material-icons success">check</span>
              <div className={'messaging-table-user-success-error between-items'}>
                <span>{success}</span>
                <span className="material-icons failed">close</span>
              </div>
              <span>{failed}</span>
            </div>
          );
        },
      },
      {
        label: context.msg.t('sending.date'),
        render: ({ endDate }) => Utils.Date.formatDateTime(endDate),
      },
      {
        label: context.msg.t('logs'),
        render: (whatsRequest) => (
          <>
            <BngIconButton
              icon="assignment"
              title={context.msg.t('see.details')}
              onClick={() => {
                StackTraceDialog({
                  title: 'Log',
                  html: whatsRequest.log,
                  copyText: context.msg.t('copy.log'),
                  requestId: whatsRequest.id,
                  onConfirm: async () => {
                    try {
                      await Utils.copyToClipboard(whatsRequest.log);
                      UiMsg.ok(context.msg.t('copied.log.to.clipboard'));
                    } catch (e) {
                      console.error('Erro while trying to copy log text to clipboard', e);
                      UiMsg.error(context.msg.t('copied.log.to.clipboard.fail'));
                    }
                  },
                });
              }}
            />
          </>
        ),
      },
    ];
  }, []);

  return (
    <div className="WhatsAppConsumption">
      <ConsumptionItem consumption={capacity} index={0} field={{ value: 0 }} selectPlan={plan} />

      <div className="whatsapp-table-wrapper">
        <div className="whatsapp-dialog-title">
          <h5 className="whatsapp-dialog-title-text">{context.msg.t('admin.panel')}</h5>
          <BngIconButton icon="file_download" title={context.msg.t('export.to.csv')} onClick={exportToCsv} />
        </div>
        <PaginateTable
          handleChangeNumberPerPage={handleChangeNumberPerPage}
          handleChangePage={handleChangePage}
          initialPass={TABLE_INITIAL_PASS}
          perPageLimit={TABLE_PER_PAGE_LIMIT}
          renderChangerNumberPerPage={false}
          totalItens={whatsRequestsPage.totalElements}
          externalChange={false}
          height={435}
        >
          <BngTable cols={tableColumns} rows={whatsRequestsPage.content} stickyHeader={true} />
        </PaginateTable>
      </div>
    </div>
  );
}
