import React, { useEffect, useRef, useState } from 'react';

export default function HtmlComponent({ html, className = '', maxHeight, ...props }) {
  const $frameRef = useRef(null);
  const [frameHeight, setFrameHeight] = useState('100%');

  useEffect(() => {
    if (!$frameRef.current) return;

    const contentDocument =
      $frameRef.current.contentDocument ||
      $frameRef.current.contentWindow ||
      $frameRef.current.contentDocument.document;

    contentDocument.open();
    try {
      contentDocument.write(`
        <style>
          html, body {
            height: fit-content;
          }
          body { 
            margin-left: 20px; 
            margin-bottom: 0;     
          }
        </style>
        ${html}
      `);
    } finally {
      contentDocument.close();
    }

    const limitFrameHeight = () => {
      const calculatedHeight = contentDocument?.documentElement?.scrollHeight ?? maxHeight;
      const newHeight = Math.min(calculatedHeight, maxHeight);
      setFrameHeight(`${newHeight}px`);
    };

    if (maxHeight) {
      $frameRef.current?.addEventListener('load', limitFrameHeight);
    }

    return () => {
      $frameRef.current?.removeEventListener('load', limitFrameHeight);
    };
  }, [$frameRef.current, html]);

  return (
    <div className={`HtmlComponent w-100 h-100 ${className}`} {...props}>
      <iframe ref={$frameRef} width="100%" height={frameHeight} style={{ border: 0 }} />
    </div>
  );
}
