import Axios from 'axios';
import bimEventBus from 'BimEventBus';

export const EVENT = 'oAuthCallback';

const oAuthCallback = (e, name, cleanupFn = _.noop) => {
  const refreshToken = e.data?.refreshToken;
  if (!refreshToken) {
    return;
  }

  try {
    document.querySelector('.google-sheets-refresh-token')?.setAttribute('value', refreshToken);
    document.querySelector('.google-sheets-check-sheets-url')?.click();
    bimEventBus.emit(EVENT, { refreshToken, name });
  } finally {
    cleanupFn();
  }
};

class StructuresPageUtils {
  currentListener = null;

  authenticationRedirect({ uri, params, name }) {
    if (this.currentListener) {
      window.removeEventListener('message', this.currentListener);
    }

    const left = screen.width / 2 - 600 / 2;
    const top = screen.height / 2 - 700 / 2;

    const url = Axios.getUri({
      url: uri,
      params: params,
    });

    const listenerFnRef = (e) =>
        oAuthCallback(e, name, () => {
          window.removeEventListener('message', listenerFnRef);
        });
    window.addEventListener('message', listenerFnRef);
    this.currentListener = listenerFnRef;

    window.open(
        url,
        'auth_window',
        `scrollbars=yes,menubar=no,height=700,width=600,left=${left},top=${top},resizable=no,toolbar=no,location=no,status=no`
    );
  }
}

export default new StructuresPageUtils();
