import styles from './BimUniversityPage.module.css';
import React, { useEffect, useState } from 'react';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';
import UiMsg from 'components/ui/UiMsg';

export const openBimAcademy = async ({ context }) => {
  const academyAddonEnabled =
    !context.accountEnabledForBilling || checkAddonEnabled(AddonType.BIM_UNIVERSITY.key, false);
  if (academyAddonEnabled) {
    if (context.isInbox || !context.university.enabled) {
      window.open(context.university.url, '_blank').focus();
    } else {
      try {
        Api.University.redirectToUniversityPage();
      } catch (e) {
        console.error('Could not load university...');
      }
    }
  } else {
    try {
      const accountId = context.accountId;
      const additionalInfo = await Api.Account.fetchAdditionalInfo(accountId, AddonType.BIM_UNIVERSITY.key, false);
      const marketplaceUrl = Api.buildUrl(`/spr/bng/marketplace`, {
        currentTab: 'item',
        itemId: additionalInfo.id,
        type: 'ADDITIONAL',
      });
      window.location.replace(marketplaceUrl);
    } catch (e) {
      console.error('Error on function openBimAcademy()', e);
      UiMsg.ajaxError(null, e);
    }
  }
};

export default function BimUniversityPage() {
  const context = useBimContext();

  const [loginInfo, setLoginInfo] = useState();

  const fetchLoginInfo = async () => {
    try {
      const { link } = await Api.University.generateAccess(context.project.id);
      setLoginInfo(link);
    } catch (e) {
      console.error('Error on fetchLoginInfo()', e);
    }
  };

  useEffect(() => {
    fetchLoginInfo();
  }, []);

  return (
    <div className="BimUniversityPage">
      <iframe className={`${styles.universityFrame}`} src={loginInfo} allowFullScreen></iframe>
      <UiBlocker block={true} className={styles.universityBlocker}>
        <div className={`${styles.loadingFrame}`}></div>
      </UiBlocker>
    </div>
  );
}
