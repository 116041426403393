import { create } from 'zustand';
import UUID from 'bng/utils/UUID';
import Api from 'components/Api';
import FilterService from 'components/filter/FilterService';
import { useGlobalLoader } from 'bng/common/GlobalLoader';
import bimEventBus from 'BimEventBus';
import DashGridEvents from 'components/ui/dashboard/components/DashGridEvents';
import FilterUtils from 'components/filter/FilterUtils';
import UiMsg from 'components/ui/UiMsg';
import { ceData } from 'components/CeData';
import Utils from 'components/Utils';
import zustandUseEquals from 'bng/utils/zustandUseEquals';

let INVALID_PERIODICITY_ALERTED;

function alertIfContainInvalidFilters(filters) {
  const currentDate = new Date();
  for (const filter of filters) {
    const member = filter.members.find((member) => !FilterUtils.isPeriodicityValidOnDate(member, currentDate));
    // Prevent duplicated alert on recreations
    if (member && member !== INVALID_PERIODICITY_ALERTED) {
      INVALID_PERIODICITY_ALERTED = member;
      UiMsg.warn(
        `${ceData.context.msg.t('attention')}!`,
        ceData.context.msg.t('invalid.date.filter.alert', [ceData.context.msg.t(member)]),
      );
      setTimeout(() => {
        if (INVALID_PERIODICITY_ALERTED === member) {
          INVALID_PERIODICITY_ALERTED = '';
        }
      }, 1000);
      break;
    }
  }
}

const CHANGE_TYPES_TO_SKIP_LOAD = ['SAVE_INITIAL_FILTERS', 'LAYOUT'];

export const processFilterAfterChange = ({ originalFilter, findMembers, onUpdate, forceClear }) => {
  if (!originalFilter) {
    return;
  }

  if (_.isEmpty(findMembers())) {
    const members = _.cloneDeep(originalFilter[forceClear ? 'restrictedMembers' : 'defaultMembers'] || []);
    onUpdate({ members });
  }

  const currentMembers = findMembers();
  if (
    originalFilter.containMemberRestriction &&
    originalFilter.restrictedMembers.length > 0 &&
    originalFilter.restrictedMembers.length === currentMembers.length &&
    originalFilter.restrictedMembers.every((m) => findMembers().includes(m))
  ) {
    onUpdate({ restrictionType: originalFilter.restrictionType });
  }

  if (!_.isEmpty(originalFilter.runtimeRestriction)) {
    onUpdate({ runtimeRestriction: originalFilter.runtimeRestriction });
  }
};

const useDashboardPageCtx = create((set, get) => {
  return {
    dash: null,
    changes: [],
    selectedItems: [], // TODO Dummy just to update DashGrid (extract from DashGrid to here)
    itemState: {},
    editContainerProps: null,
    dashGridCustomSelect: null,
    moveItemProps: null,
    loading: false,
    dashKey: UUID.generate(),
    itemOverrides: {},
    filters: [],
    drillStates: {},
    itemRenderResults: {},

    resetState() {
      set({
        dash: null,
        changes: [],
        selectedItems: [],
        itemState: {},
        editContainerProps: null,
        dashGridCustomSelect: null,
        moveItemProps: null,
        loading: false,
        dashKey: UUID.generate(),
        itemOverrides: {},
        filters: [],
        drillStates: {},
        itemRenderResults: {},
      });
    },

    async addChange({ type, data, updateDashKey = false }) {
      const { changes, path, viewType, itemOverrides, filters } = get();

      const newChange = {
        id: UUID.generate(),
        type,
        data: { id: UUID.generate(), breakpoint: viewType(), ...data },
      };

      const result = {
        changes: [...changes, newChange],
      };

      if (type !== 'LAYOUT') {
        await useGlobalLoader.getState().runTask(async () => {
          const { errors } = await Api.Dash.validateChanges({ path, changes: result.changes });
          const match = errors.find((e) => e.id === newChange.id);
          if (match) {
            throw new Error(match.error);
          }
        });
      }

      if (updateDashKey) {
        result.dashKey = UUID.generate();
      }

      if (type === 'CHANGE_ANALYSIS_VIEW_TYPE') {
        result.itemOverrides = { ...itemOverrides };
        delete result.itemOverrides[data.id];
      } else if (type === 'UPDATE_FILTERS') {
        result.filters = filters.filter((f) => !data.filters.some((uf) => uf.id === f.id));
      }

      set(() => {
        return result;
      });
    },

    findFilters() {
      return [];
    },

    editContainer(props) {
      set(() => {
        return { editContainerProps: props };
      });
    },

    updateMdx(id, newItemState) {
      set((state) => {
        const itemState = { ...state.itemState };
        if (newItemState) {
          itemState[id] = newItemState;
        } else {
          delete itemState[id];
        }
        return { itemState };
      });
    },

    moveItem(props) {
      set(() => {
        return { moveItemProps: props };
      });
    },

    changeViewType(viewType = 'DESKTOP', searchParams, setSearchParams) {
      searchParams.set('viewType', viewType);
      setSearchParams(searchParams);
    },

    viewType() {
      return Utils.History.currentUrlSearchParams().get('viewType') || 'DESKTOP';
    },

    async fetchDash({ path, initialFiltersRef, initialFilters, initialData }) {
      const state = get();
      set(() => ({ loading: true }));
      const isDashFirstLoad = !state.dash || state.dash.dashboardPath !== path;
      try {
        let filters = state.filters;
        const changes = isDashFirstLoad ? [] : state.changes;

        const dash = await useGlobalLoader.getState().runTask(async () => {
          let currentDash = state.dash;
          // Layout change don't need to reload
          const lastChange = changes[changes.length - 1];
          if (
            currentDash &&
            lastChange &&
            !lastChange.__alreadySkipped &&
            CHANGE_TYPES_TO_SKIP_LOAD.includes(lastChange.type)
          ) {
            lastChange.__alreadySkipped = true;
            if (lastChange.type === 'LAYOUT') {
              currentDash = _.cloneDeep(currentDash);
              currentDash.gridData.layouts[lastChange.data.breakpoint] = _.cloneDeep(lastChange.data.dashGridLayout);
            }
          } else {
            if (isDashFirstLoad && initialData) {
              currentDash = initialData;
            } else {
              let dashFilters = filters;

              if (isDashFirstLoad) {
                if (!_.isEmpty(initialFilters)) {
                  dashFilters = initialFilters?.filter(
                    (filter) => !_.isEmpty(filter.members ?? filter.selectedMembers),
                  );
                } else if (initialFiltersRef) {
                  try {
                    const { value } = await Api.TemporaryStorage.find(initialFiltersRef, false);
                    if (value) {
                      dashFilters = JSON.parse(value);
                    }
                  } catch (e) {
                    console.warn('Error while trying to parse initialFiltersRef value', e);
                  }
                }
                if (!_.isEmpty(dashFilters)) {
                  filters = dashFilters;
                }
              }

              const { filter } = FilterService.createFilterParam(dashFilters, true);
              if (!_.isEqual(state.filters, filter)) {
                filters = filter;
              }
              const itemOverrides = Object.values(state.itemOverrides).reduce((acc, el) => {
                return {
                  ...acc,
                  [el.id]: {
                    path: el.path,
                    viewType: el.viewType,
                  },
                };
              }, {});
              currentDash = await Api.Dash.loadDash({
                path,
                filter,
                changes,
                itemOverrides,
              });
            }
          }
          return currentDash;
        });

        set(() => {
          const update = { path, dash, filters };
          if (isDashFirstLoad) {
            update.drillStates = {};
            update.itemRenderResults = {};
            update.itemOverrides = {};
          }
          return update;
        });
      } catch (e) {
        const status = e.response?.status;
        if (status === 404) {
          window.location.replace(Api.buildUrl('/pages/errors/application-resource-not-found.iface'));
        } else if (status === 403) {
          window.location.replace(Api.buildUrl('/pages/errors/403.iface'));
        } else {
          throw e;
        }
      } finally {
        set(() => ({ loading: false }));
      }
    },

    updateDashKey() {
      set(() => ({ dashKey: UUID.generate() }));
    },

    updateItemVisualization({ action, item }) {
      set((state) => {
        const itemOverrides = { ...state.itemOverrides };
        if (action === 'OVERRIDE') {
          itemOverrides[item.id] = item;
        } else {
          delete itemOverrides[item.id];
        }
        return { itemOverrides };
      });
    },

    filterChangeHandler(
      filters = [],
      forceClear = false,
      appendAndReplace = false,
      additionalProps = { clearFilters: false },
    ) {
      const state = get();

      const dash = state.dash;
      filters = filters.map((filter) => {
        const originalFilter = dash.gridData.filters[filter.id];
        let newFilter;
        if (filter.hasOwnProperty('members')) {
          newFilter = _.cloneDeep(filter);
        } else {
          newFilter = {
            id: filter.id,
            members: filter.selectedMembers.map((m) => m.value),
          };
        }

        newFilter.restrictionType = 'SHOW_SELECTED';
        newFilter.members = newFilter.members.filter((m) => !_.isEmpty(m));

        processFilterAfterChange({
          originalFilter,
          forceClear,
          findMembers: () => newFilter.members,
          onUpdate: ({ members, restrictionType, runtimeRestriction }) => {
            if (members) {
              newFilter.members = members;
            }

            if (restrictionType) {
              newFilter.restrictionType = restrictionType;
            }

            if (runtimeRestriction) {
              newFilter.runtimeRestriction = runtimeRestriction;
            }
          },
        });

        return newFilter;
      });

      if (appendAndReplace || additionalProps.clearFilters) {
        let currentFilters = _.cloneDeep(state.filters);
        for (const filter of filters) {
          const existentFilter = currentFilters.find((f) => f.id === filter.id);
          if (existentFilter && !additionalProps.clearFilters) {
            existentFilter.members = filter.members;
            if (filter.restrictionType) {
              existentFilter.restrictionType = filter.restrictionType;
            }
          } else if (additionalProps.clearFilters) {
            filter.members = [];
            currentFilters = currentFilters.filter((f) => f.id !== filter.id);
            currentFilters.push(filter);
          } else {
            currentFilters.push(filter);
          }
        }
        filters = currentFilters;
      }

      if (!_.isEqual(state.filters, filters)) {
        alertIfContainInvalidFilters(filters);
        set(() => ({ filters }));
      }
      return filters;
    },

    updateDrillState(id, drillState) {
      set(({ drillStates }) => {
        const newDrillStates = _.cloneDeep(drillStates);
        _.set(newDrillStates, `${id}.drillState`, drillState);
        if (!_.isEqual(newDrillStates, drillStates)) {
          return { drillStates: newDrillStates };
        }
        return {};
      });
    },

    clearDrillStates() {
      set(() => {
        return { drillStates: {} };
      });
    },

    updateItemRenderResult(id, result) {
      set(({ itemRenderResults }) => {
        itemRenderResults = { ...itemRenderResults };
        itemRenderResults[id] = result;
        return { itemRenderResults };
      });
    },

    clearItemRenderResults() {
      set(() => {
        return { itemRenderResults: {} };
      });
    },
  };
});

bimEventBus.on(DashGridEvents.RENDER_IN_PLACE, useDashboardPageCtx.getState().updateItemVisualization);

useDashboardPageCtx.cached = function (fn) {
  return useDashboardPageCtx(zustandUseEquals(fn));
};

export default useDashboardPageCtx;
