import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import Icon from "components/ui/common/Icon";

class DashboardItemMenuKPIMonitor extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {
        open: false,
        kpiMonitor: null
    };

    openMonitor = async () => {
        if (!this.state.open) {
            this.props.onOpen(event);
            try {
                const data = await Api.Monitor.findKpiData(this.props.item.path);
                this.setState({kpiMonitor: data, open: true});
            } catch (e) {
                console.error(e);
                UiMsg.ajaxError(null, e);
            }
        } else {
            this.setState({open: false});
        }
    };

    forceClose() {
        this.setState({open: false});
    };

    generateLink = () => {
        return `${Api.baseUrl()}/load.iface?${j.param({content: this.props.item.path, breadcrumb: true})}`;
    };

    formatMonitorTitle = (rule) => {

        let message = "";

        if (rule.actionConfigured) {
            rule.actions.forEach(function (action) {
                action.deviceMessages.forEach(function (deviceMessage) {
                    message += deviceMessage.device + ': ' + deviceMessage.message;
                });
            });
        }

        return message;
    };

    renderbands = () => {
        const bands = this.state.kpiMonitor.bands;
        return (
            <div style={{padding: '14px 0'}}>
                {bands.map((band, index) => {
                    return (
                        <div key={index} style={{display: 'flex', padding: '2px 0'}}>
                            <Icon icon="radio_button_checked" style={{color: band[0], fontSize: '18px'}}/>

                            <div className="monitorKPIBandFor">{band[1]}</div>
                            {(band[3].actions.length > 0) &&
                            <div className="monitorKPIActive">
                                <Icon icon="icon-envelope-alt" title={this.formatMonitorTitle(band[3])}/>
                            </div>
                            }
                        </div>
                    )
                })}
            </div>
        );
    };

    renderInterval = () => {
        const monitor = this.state.kpiMonitor.monitor.schedulings[0];
        return (
            <div>
                <div style={{display: 'none'}}>{this.props.context.msg.t(monitor.type)}</div>
                <div>{monitor.representativeString}</div>
            </div>
        );
    };

    render() {
        return (
            <li className={`bt-item-new-menu-inside ${this.state.open ? 'active' : ''}`}>
                <DashboardItemMenuTitle title={this.props.context.msg.t('monitoring')}
                                        icon="icon-bullhorn"
                                        more={true}
                                        onClick={this.openMonitor}
                />

                {this.state.open &&
                <ul className="bng-dropdown container-dash-item-newmenu sub-container-dash-item-newmenu-monitor">
                    <li>
                        <div style={{textAlign: 'center'}}>
                            {this.state.kpiMonitor.monitor ? this.props.context.msg.t('monitoring.enabled') : this.props.context.msg.t('monitoring.disabled')}
                        </div>

                        {this.state.kpiMonitor.monitor &&
                        <div>
                            {this.renderbands()}
                            {this.renderInterval()}

                            {this.props.editMode &&
                            <div style={{textAlign: 'right', padding: '14px 0 0 0'}}>
                                <a href={this.generateLink()} className="btn btn-mini btn-primary">
                                    {this.props.context.msg.t('monitor.dashboard.edit.on.kpi')}
                                </a>
                            </div>
                            }

                        </div>
                        }

                        {!this.state.kpiMonitor.monitor &&
                        <div style={{textAlign: 'right', padding: '14px 0 0 0'}}>
                            {this.props.editMode ? (
                                <a href={this.generateLink()} className="btn btn-mini btn-primary">
                                    {this.props.context.msg.t('monitor.dashboard.create.on.kpi')}
                                </a>
                            ) : (
                                <div
                                    style={{textAlign: 'center'}}>{this.props.context.msg.t('monitor.dashboard.create.on.kpi.description')}</div>
                            )
                            }
                        </div>
                        }

                    </li>
                </ul>
                }

            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuKPIMonitor);