import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import ContextEnhancer from 'components/ContextEnhancer';
import { MODALS } from 'components/ui/redux/Actions';
import Utils from 'components/Utils';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';
import BngExportDialog from 'components/bng/exportScheduling/BngExportDialog';

class DashboardItemMenuShare extends React.Component {
  static propTypes = {
    selectedFilters: PropTypes.array,
    dashGridItemRef: PropTypes.func,
    item: PropTypes.object,
    bigtableFilterModel: PropTypes.any,
    bigtableSortModel: PropTypes.any,
  };

  static defaultProps = {
    selectedFilters: [],
    dashGridItemRef: _.noop,
    item: {},
  };

  state = {
    open: false,
    loading: false,
  };

  openOptions = (event) => {
    if (!this.state.open) this.props.onOpen(event);
    this.setState({ open: !this.state.open });
  };

  forceClose() {
    this.setState({ open: false });
  }

  copyLinkToClipboard = async () => {
    this.$input.value = Api.loadObjectUrl({
      content: this.props.item.path,
      fullUrl: true,
    });
    this.$input.select();
    document.execCommand('copy');
    UiMsg.ok(this.props.context.msg.t('public.link.copy.clipboard'));
  };

  openShareDialog = (messageType) => {
    let { item, bigtableFilterModel, bigtableSortModel } = this.props;

    if (Utils.Object.isBigTable(item.path)) {
      bigtableFilterModel = Utils.BigTable.returnFilterModelForPath(this.props.item.path, bigtableFilterModel);
      bigtableSortModel = Utils.BigTable.returnSortModelForPath(this.props.item.path, bigtableSortModel);
    }

    const { mdxOverrides = [] } = window.__CURRENT_DASHGRID?.stateForExport() ?? {};
    const mdx = mdxOverrides.find((mo) => mo.id === this.props.item.id)?.mdx;

    window.ReduxStore.dispatch(
      MODALS.open(BngExportDialog, {
        contents: [
          {
            name: item.caption,
            path: item.path,
            exportType: Utils.Scheduling.EXPORT_TYPE.PDF,
          },
        ],
        messageType,
        schedulingType: Utils.Scheduling.SCHEDULING_TYPE.IMMEDIATE,
        shareOpts: {
          enabled: true,
          params: {
            filters: this.exportFilters(),
            bigtableFilterModel,
            bigtableSortModel,
            mdx,
          },
        },
      })
    );
  };

  exportFilters = () => {
    const { ref } = this.props.dashGridItemRef() ?? {};
    return ref?.getFilters() ?? this.props.selectedFilters;
  };

  exportPdf = async () => {
    try {
      if (Utils.Object.isBigTable(this.props.item.path)) {
        await this.bigtableExportPdf();
      } else {
        await Api.Export.exportPdfFromDash(this.props.item.path, this.exportFilters(), this.props.item);
      }
    } catch (e) {
      console.error('Error on exportPdf()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  bigtableExportPdf = async () => {
    try {
      await Api.BigTable.exportPdfFromDash(
        this.props.item.path,
        this.exportFilters(),
        this.props.item,
        Utils.BigTable.returnFilterModelForPath(this.props.item.path, this.props.bigtableFilterModel),
        Utils.BigTable.returnSortModelForPath(this.props.item.path, this.props.bigtableSortModel),
      );
    } catch (e) {
      console.error('Error on bigtableExportPdf()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  exportCsv = async () => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    try {
      await Api.BigTable.exportCsvFromDash(
        this.props.item.path,
        this.exportFilters(),
        Utils.BigTable.returnFilterModelForPath(this.props.item.path, this.props.bigtableFilterModel),
        Utils.BigTable.returnSortModelForPath(this.props.item.path, this.props.bigtableSortModel),
      );
    } catch (e) {
      console.error('Error on exportCsv()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      this.setState({ loading: false });
    }
  };

  exportExcel = async () => {
    try {
      await Api.Analysis.exportExcelFromDash(this.props.item.path, this.exportFilters(), this.props.item);
    } catch (e) {
      console.error('Error on exportExcel()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  render() {
    const { item, context } = this.props;
    const isAnalysis = Utils.Object.isAnalysis(item.path);
    const isBigTable = Utils.Object.isBigTable(item.path);
    const messagingDisabled = !context.user.allowMobileMessaging;

    return (
      <li className={`bt-item-new-menu-inside ${this.state.open ? 'active' : ''}`}>
        <DashboardItemMenuTitle title={context.msg.t('share')} onClick={this.openOptions} icon="share" more={true} />
        {this.state.open && (
          <ul className="bng-dropdown container-dash-item-newmenu sub-container-dash-item-newmenu-config">
            <li className={'bt-item-new-menu-inside'}>
              <DashboardItemMenuTitle
                title={context.msg.t('share.email')}
                onClick={this.openShareDialog.bind(this, Utils.Scheduling.DELIVERY_TYPE.EMAIL)}
                icon="email"
                more={false}
              />
            </li>
            <li className={`bt-item-new-menu-inside ${messagingDisabled ? 'disabled' : ''}`}>
              <div>
                <DashboardItemMenuTitle
                  title={context.msg.t('share.whatsapp')}
                  onClick={(e) =>
                    messagingDisabled || !checkAddonEnabled(AddonType.WHATS_APP.key, true)
                      ? e.preventDefault()
                      : this.openShareDialog(Utils.Scheduling.DELIVERY_TYPE.WHATSAPP)
                  }
                  icon="fa fa-whatsapp"
                  more={false}
                />
              </div>
            </li>
            <li className={'bt-item-new-menu-inside disabled'} title={context.msg.t('is.coming')}>
              <DashboardItemMenuTitle
                title={context.msg.t('share.telegram')}
                onClick={(e) => e.preventDefault()}
                icon="fa fa-telegram"
                more={false}
              />
            </li>
            <li className={'bt-item-new-menu-inside'}>
              <DashboardItemMenuTitle
                title={context.msg.t('printer.export.to.pdf')}
                onClick={this.exportPdf}
                icon="insert_drive_file"
                more={false}
              />
            </li>
            {isBigTable && (
              <li className={'bt-item-new-menu-inside'}>
                <DashboardItemMenuTitle
                  title={context.msg.t('export.to.csv')}
                  onClick={this.exportCsv}
                  icon="grid_on"
                  more={false}
                />
              </li>
            )}
            {isAnalysis && (
              <li className={'bt-item-new-menu-inside'}>
                <DashboardItemMenuTitle
                  title={context.msg.t('export.to.excel')}
                  onClick={this.exportExcel}
                  icon="grid_on"
                  more={false}
                />
              </li>
            )}
            <li className={'bt-item-new-menu-inside'}>
              <input
                ref={(el) => (this.$input = el)}
                className="AuxCopyInput"
                type="text"
                style={{ position: 'absolute', opacity: 0, zIndex: -1 }}
              />
              <DashboardItemMenuTitle
                title={context.msg.t('copy.link')}
                onClick={this.copyLinkToClipboard}
                icon="file_copy"
                more={false}
              />
            </li>
          </ul>
        )}
      </li>
    );
  }
}

export default connect((state) => {
  return {
    bigtableFilterModel: state.bigTable.data.bigtableFilterModel,
    bigtableSortModel: state.bigTable.data.bigtableSortModel,
  };
})(ContextEnhancer(DashboardItemMenuShare));
