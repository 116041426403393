import styles from './ConnectionsTab.module.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import BngDropdown from 'components/bng/ui/BngDropdown';
import { MODALS } from 'components/ui/redux/Actions';
import ConnectionsDialog from 'components/ui/in-memory/ConnectionsDialog';
import Tooltip from 'components/ui/Tooltip';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useTranslation from 'components/hooks/useTranslation';
import BngSearch from 'components/bng/ui/BngSearch';
import BngLogDialog from 'components/bng/logs/BngLogDialog';
import Utils from 'components/Utils';
import BngButton from 'components/bng/ui/BngButton';
import useBimQueryPageCtx from 'components/bng/pages/admin/structures/bimQuery/useBimQueryPageCtx';
import BimQueryConfigurationMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConfigurationMenuItem';
import { RIGHT_MENU_TOGGLE_SUBMENU } from 'components/ui/right-menu/RightMenu';
import bimEventBus from 'BimEventBus';
import LoadingSvg from 'components/ui/loading/LoadingSvg';

const columnIconSelector = (columnType) => {
  switch (columnType) {
    case 'NUMERIC':
      return '123';
    case 'DATE':
      return 'schedule';
    case 'BOOL':
      return 'memory';
    case 'JSON':
      return '{JSON}';
    default:
      return 'abc';
  }
};

function ConnectionSelector({
  connections = [],
  selectedConnection = 0,
  fetchConnections = _.noop,
  setValues = _.noop,
  values = {},
  isEditing = false,
  loading = false,
}) {
  const { msg, project } = useBimContext();
  const dispatch = useReduxDispatch();

  const [opened, setOpened] = useState(false);

  const isConnectionSelected = selectedConnection > 0 || selectedConnection === 'BIMWarehouse';

  const connection = useMemo(() => connections.find((con) => con.id === selectedConnection), [
    connections,
    selectedConnection,
  ]);

  return (
    <BngDropdown
      startOpened={selectedConnection === 0 && !isEditing}
      customButton={({ openDropdown }) => (
        <div className={`${styles.ConnectionSelectorWrapper}`}>
          <div
            className={`${styles.ConnectionSelector} opened-${opened}`}
            onClick={(event) => {
              setOpened((prev) => !prev);
              openDropdown(event);
            }}
          >
            <div className={`${styles.connectionNameWrapper}`}>
              {connection ? (
                <>
                  <img
                    src={
                      connection.id === 'BIMWarehouse'
                        ? Api.buildUrl('/images/data/logo.png')
                        : Api.buildUrl('/images/data/db.png')
                    }
                    alt={connection.name}
                    className={`${styles.connectionIcon}`}
                  />
                  {connection.name}
                </>
              ) : (
                msg.t('select.one.connection')
              )}
            </div>
            <Icon icon="arrow_drop_down" />
          </div>
          {isConnectionSelected && (
            <Icon
              icon="close"
              onClick={() => {
                setValues({
                  ...values,
                  connection: 0,
                  fieldConfigs: [],
                  sql: '',
                  dimensions: [],
                  dataRecyclingFieldName: '',
                });
              }}
            />
          )}
        </div>
      )}
      popperOpts={{
        placement: 'bottom-start',
      }}
      className={`ConnectionSelector ${styles.ConnectionSelectorButton}`}
      onClose={() => setOpened((prev) => !prev)}
      popperClassName={`ConnectionListOptionPopper ${styles.ConnectionListOptionPopper} `}
      customOptions={({ closeDropdown }) => (
        <div className={`${styles.ConnectionsOptionsDropdown}`}>
          {connections.map((connection, index) => {
            return (
              <div
                className={`${styles.ConnectionListOption} ${index === 0 ? 'FirstConnection' : ''}`}
                onClick={() => {
                  setValues({
                    ...values,
                    connection: connection.id,
                    fieldConfigs: [],
                    sql: '',
                    dimensions: [],
                  });
                  closeDropdown();
                }}
              >
                {connection.id === 'BIMWarehouse' ? (
                  <>
                    <img
                      className={`ConnectionTab-ConnectionListOption-db-logo`}
                      src={Api.buildUrl('/images/data/logo.png')}
                      alt="Icon"
                    />
                    <div>
                      <div>{`${msg.t('bim.query.export.my.data.lake.connection.title')}`}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      className={`ConnectionTab-ConnectionListOption-db-logo`}
                      src={Api.buildUrl('/images/data/db.png')}
                      alt="Icon"
                    />
                    <div>
                      <div className={`${styles.connectionNameWrapper}`}>{`${msg.t('name')}: ${connection.name}`}</div>
                      <div>{connection?.type?.name || ''}</div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
          {loading && (
            <div className={`${styles.ConnectionListOption} loading-connection`}>
              <LoadingSvg />
            </div>
          )}
          <div
            className={`${styles.ConnectionListOption} new-database-connection`}
            onClick={() => {
              dispatch(
                MODALS.open(ConnectionsDialog, {
                  projectId: project.id,
                  onClose: () => fetchConnections(),
                })
              );
              closeDropdown();
            }}
          >
            <Icon icon="add_circle" />
            <div className={`${styles.AddConnectionLabel}`}>{msg.t('manage.connections')}</div>
          </div>
        </div>
      )}
    />
  );
}

function RenderList({
  schemas = [],
  fetchChildList = _.noop,
  childProp = 'tables',
  parent = {},
  handleSelection = _.noop,
  schema = '',
  selectedConnection = 0,
  className = styles.listWrapper,
  filter = '',
}) {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (filter) {
      const expandedSchemas = schemas
        .map((item, idx) => (item.hasOwnProperty('tables') ? idx : null))
        .filter((idx) => idx !== null);
      setExpanded(expandedSchemas);
    } else {
      setExpanded([]);
    }
  }, [filter, schemas]);


  const handleExpand = (idx, schemaName, tableName) => {
    fetchChildList(schemaName, tableName, childProp);

    const expandedClone = expanded.slice();
    const indexOf = expandedClone.indexOf(idx);
    if (indexOf < 0) {
      expandedClone.push(idx);
    } else {
      expandedClone.splice(indexOf, 1);
    }
    setExpanded(expandedClone);
  };

  return (
    <>
      {schemas.map((item, idx) => {
        const isExpanded = expanded.indexOf(idx) >= 0;
        const children = item[childProp];
        const hasChild = children?.length > 0;
        const isSchema = item.hasOwnProperty('tables');
        const isTable = item.hasOwnProperty('columns');
        const schemaName = isSchema ? item.name : schema;

        let itemProps = {};

        if (!children) {
          itemProps.title = (
            <>
              <b>{item.name}</b>
              <div>
                {t('bim.query.menu.item.tooltip.from.table')}: <b>{item.key?.table}</b>
              </div>
              <div>
                {t('bim.query.menu.item.tooltip.column.name.from.table')}: <b>{item.key?.name}</b>
              </div>
              <div>
                {t('bim.query.menu.item.tooltip.column.type')}: <b>{item.type}</b>
              </div>
            </>
          );
        }

        const Tip = !children ? Tooltip : 'div';

        return (
          <Tip {...itemProps} key={idx}>
            <div
              className={`${className} ${isExpanded ? 'open' : 'closed'}`}
              draggable={!isSchema}
              onDragStart={(event) => {
                event.persist();
                handleSelection(
                  item,
                  childProp,
                  parent,
                  event.dataTransfer,
                  schemaName,
                  isTable ? item.name : null,
                  false
                );
                event.stopPropagation();
              }}
              onDragEnd={(event) => {
                event.persist();
                handleSelection(item, childProp, parent, null, schemaName, isTable ? item.name : null, false);
                event.stopPropagation();
              }}
            >
              <div
                key={`${parent.id || 0}${idx}`}
                className={`${styles.schemaName}`}
                onClick={() => handleExpand(idx, schemaName, isTable ? item.name : null)}
              >
                <Icon
                  className={`${styles.schemaNameArrowIcon} ${isExpanded ? 'open' : 'closed'} ${
                    !hasChild ? 'hide' : ''
                  }`}
                  icon="arrow_right"
                />
                <Icon
                  className={`${styles.listItemIcon} ${item.type} `}
                  icon={(isSchema && 'schema') || (isTable && 'table_view') || columnIconSelector(item.type)}
                />
                <div className={`${styles.itemName} ${!children ? 'column' : ''}`}>{item.name}</div>
                {['FOREIGN', 'PRIMARY'].includes(item?.key?.type) && (
                  <Icon className={`${styles.keyIcon} ${item.key.type}`} icon="key" />
                )}
              </div>
              <div className={`${styles.subListWrapper} ${isExpanded ? 'open' : 'closed'}`}>
                {isExpanded && (
                  <RenderList
                    schemas={children}
                    fetchChildList={fetchChildList}
                    childProp="columns"
                    parent={item}
                    handleSelection={handleSelection}
                    schema={schemaName}
                    className={isTable ? styles.columnList : styles.subList}
                    filter={filter}
                  />
                )}
              </div>
              {!isSchema && (
                <Icon
                  className={`${styles.itemAddIcon}`}
                  icon={children ? 'add' : ''}
                  onClick={() =>
                    handleSelection(item, childProp, parent, null, schemaName, isTable ? item.name : null, true, false)
                  }
                />
              )}
            </div>
          </Tip>
        );
      })}
    </>
  );
}

export default function ConnectionsTab({ formikProps, isEditing, options }) {
  const { t } = useTranslation();
  const { project } = useBimContext();
  const dispatch = useReduxDispatch();
  const $bimQueryPageCtx = useBimQueryPageCtx();

  const { values, setFieldValue, errors, setValues } = formikProps;

  const $intervalRef = useRef();

  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [connectionsLoading, setConnectionsLoading] = useState(false);
  const [dbSchemas, setDbSchemas] = useState(null);
  const [filter, setFilter] = useState('');
  const [syncing, setSyncing] = useState({ running: false, errorMessage: '', jobId: 0 });

  const selectedConnection = values.connection;

  const fieldsHasError = errors.hasOwnProperty('connection') || errors.hasOwnProperty('sql');

  const fieldsAreEmpty = values.connection !== 0 && values.sql !== '' && values.fieldConfigs?.length > 0;

  const stepIsValid = fieldsAreEmpty && !fieldsHasError;

  const connection = useMemo(() => {
    let temp = connections.find((con) => con.id === selectedConnection);
    if (temp?.id === 'BIMWarehouse') {
      temp = _.cloneDeep(temp);
      temp.databaseStructureUpdatedAt = moment().format();
    }
    return temp;
  }, [connections, selectedConnection]);

  const filteredDbSchemas = useMemo(() => {
    if (dbSchemas == null) {
      return [];
    }
    if (!filter) {
      return dbSchemas;
    }

    const clone = dbSchemas.slice();
    return clone
      .map((schema) => {
        schema = { ...schema };
        schema.tables = schema.tables.filter((table) => table.name.toLowerCase().includes(filter.toLowerCase()));
        return schema;
      })
      .filter((schema) => schema.tables.length > 0);
  }, [dbSchemas, filter]);

  useEffect(() => {
    fetchConnections();

    return () => {
      clearInterval($intervalRef.current);
    };
  }, []);

  useEffect(() => {
    clearInterval($intervalRef.current);

    setDbSchemas(null);
    const newSyncing = { ...syncing };
    setFilter('');
    if (selectedConnection !== 0) {
      if (selectedConnection !== 'BIMWarehouse') {
        newSyncing.running = true;
        syncDatabase(true);
      }
      fetchDataBase();
    } else {
      newSyncing.running = false;
    }
    setSyncing(newSyncing);
  }, [selectedConnection]);

  const fetchConnections = async () => {
    try {
      setConnectionsLoading(true);
      const data = await $bimQueryPageCtx.fetchConnections(project.id);
      const connectionList = data
        .filter((con) => con.type !== null)
        .map((connection) => ({
          ...connection,
          database: connections.type?.name,
        }));
      connectionList.push({
        id: 'BIMWarehouse',
        name: t('bim.query.export.my.data.lake.connection.title'),
        type: { name: 'BIMWarehouse' },
      });
      setConnections(connectionList);
      const connection = connectionList.find((connection) => connection.id === values.connection);
      if (connection) {
        setFieldValue('connection', connection.id);
      }
    } catch (e) {
      console.error('Error on function fetchConnections', e);
      UiMsg.ajaxError(t('bim.query.fetch.connections.error'), { e });
    } finally {
      setConnectionsLoading(false);
    }
  };

  const fetchDataBase = async () => {
    try {
      setLoading(true);
      let data;
      if (selectedConnection === 'BIMWarehouse') {
        data = await Api.Connection.fetchProjectDwSchema(project.id);
      } else if (selectedConnection) {
        data = await Api.Connection.fetchSchema({ connectionId: selectedConnection, inspectionType: 'SCHEMA' });
      }
      setDbSchemas(data?.schemas || []);
    } catch (e) {
      console.error('Error on function fetchDataBase', e);
      UiMsg.ajaxError(t('bim.query.fetch.database.error'), { e });
    } finally {
      setLoading(false);
    }
  };

  const syncDatabase = async (createInterval = false) => {
    if (selectedConnection <= 0) {
      return;
    }

    if (createInterval) {
      $intervalRef.current = setInterval(syncDatabase, 30000);
    }

    const newSync = { ...syncing };

    try {
      const { job } = await Api.Connection.findSchemaInspectionJob(selectedConnection);
      if (job) {
        clearInterval($intervalRef.current);
        newSync.errorMessage = job.props.errorMessage || '';
        newSync.jobId = job.id;
      }
      setSyncing({ ...newSync, running: true });
      if (_.isEmpty(job)) {
        clearInterval($intervalRef.current);
        const resp = await Api.Connection.fetchSchema({
          connectionId: selectedConnection,
          inspectionType: 'PERSISTED',
        });

        if (resp.schemas !== null) {
          await fetchConnections();
          setDbSchemas(resp.schemas);
        }
        setSyncing({ ...newSync, running: false });
        clearInterval($intervalRef.current);
      }
    } catch (e) {
      setSyncing({ ...newSync, running: false });
      console.error('Error on fetch FULL_SCHEMA', { e });
      UiMsg.ajaxError(t('bim.query.fetch.database.error'), { e });
      clearInterval($intervalRef.current);
    }
  };

  const createJobToSyncDataBase = async () => {
    try {
      setSyncing({ ...syncing, running: true });
      if (selectedConnection === 'BIMWarehouse') {
        const data = await Api.Connection.fetchProjectDwSchema(project.id);
        setDbSchemas(data.schemas);
        setSyncing({ ...syncing, running: false });
        connection.databaseStructureUpdatedAt = moment().format();
      } else if (selectedConnection > 0) {
        await Api.Connection.createSchemaInspectionJob(selectedConnection, project.id);
        $intervalRef.current = setInterval(syncDatabase(true), 30000);
      }
    } catch (e) {
      console.error('Error syncing database', { e });
      UiMsg.ajaxError('', { e });
      setSyncing({ ...syncing, running: false });
      clearInterval($intervalRef.current);
    }
  };

  const fetchChildList = async (schemaName, tableName, childProp, lockUI = false) => {
    try {
      if (!lockUI) {
        setLoading(true);
      }
      const clonedSchemas = dbSchemas.slice();
      let temp = clonedSchemas.find((schema) => schema.name === schemaName);

      if (tableName) {
        temp = temp.tables.find((table) => table.name === tableName);
      }

      if (temp[childProp]?.length === 0) {
        const data = await Api.Connection.fetchSchema({
          connectionId: selectedConnection,
          inspectionType: tableName ? 'COLUMN' : 'TABLE',
          schemaName: schemaName,
          tableName: tableName,
        });

        if (tableName === null) {
          temp[childProp] = data.schemas.find((s) => s.name === schemaName).tables;
        } else {
          temp[childProp] = data.schemas
            .find((s) => s.name === schemaName)
            .tables.find((t) => t.name === tableName).columns;
        }

        setDbSchemas(clonedSchemas);
      }
    } catch (e) {
      console.error('Error on fetchChildList()', { e });
      UiMsg.ajaxError(t('bim.query.fetch.database.error'), { e });
    } finally {
      if (!lockUI) {
        setLoading(false);
      }
    }
  };

  const handleSelection = async (
    item,
    child,
    parent,
    dataTransfer = {},
    schemaName,
    tableName,
    fromAddButton = false,
    lockUI = false
  ) => {
    const hasChildProp = Object.hasOwn(item, child);
    if (hasChildProp || selectedConnection !== 'BIMWarehouse') {
      await fetchChildList(schemaName, tableName, 'columns', lockUI);
    }

    const isTable = item.hasOwnProperty('columns');
    const isBIMWarehouse = selectedConnection === 'BIMWarehouse';
    let sqlSelect = '';
    if (!isTable) {
      sqlSelect = `${parent.name}.${isBIMWarehouse ? '"' : ''}${item.name}${isBIMWarehouse ? '"' : ''}`;
      dataTransfer.setData('text/plain', sqlSelect);
    } else if (dataTransfer === null) {
      const table = hasChildProp ? item : parent;
      sqlSelect = '\nSELECT';

      if (connection.type.jdbcDriver === 'oracle.jdbc.driver.OracleDriver') {
        (hasChildProp ? table[child] : [item]).forEach((v, idx) => {
          sqlSelect += `\n${idx > 0 ? ',' : ' '} ${table.name}.${v.name}`;
        });
        sqlSelect += `\nFROM\n${schemaName.toLowerCase()}.${table.name} ${table.name}`;
      } else {
        (hasChildProp ? table[child] : [item]).forEach((v, idx) => {
          sqlSelect += `\n${idx > 0 ? ',' : ' '} ${table.name}."${v.name}"`;
        });
        if (selectedConnection === 'BIMWarehouse') {
          sqlSelect += `\nFROM\n"${schemaName}"."${table.name}" as ${table.name}`;
        } else {
          sqlSelect += `\nFROM\n"${schemaName}".${table.name} as ${table.name}`;
        }
      }

      setFieldValue('sql', values.sql + sqlSelect);
    }
  };

  const clearSyncingError = async () => {
    try {
      await Api.Connection.clearSchemaInspectionJob(selectedConnection, syncing.jobId);
      setSyncing({
        running: false,
        errorMessage: '',
        jobId: 0,
      });
    } catch (e) {
      console.error('Error on clearSyncingError()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const lastSync = connection?.databaseStructureUpdatedAt ?? 0;
  const disableButtons = selectedConnection === 0 ? 'disabled' : '';

  return (
    <>
      <AccordionList className={`${styles.ConnectionsTabAccordionWrapper} ObjectRightMenuAccordion`} loading={loading}>
        <Accordion title={t('connection')}>
          <ConnectionSelector
            connections={connections}
            selectedConnection={selectedConnection}
            fetchConnections={fetchConnections}
            loading={connectionsLoading}
            setValues={setValues}
            values={values}
            isEditing={isEditing}
          />
        </Accordion>
        <Accordion title={t('bim.query.menu.item.title.tables')}>
          <div className={`${styles.TopButtons} ${disableButtons}`}>
            <div className={`${styles.syncingIndicatorWrapper}`}>
              <div
                className={`${styles.SyncButton} ${disableButtons}`}
                onClick={disableButtons ? undefined : () => createJobToSyncDataBase()}
                title={lastSync !== 0 ? `${t('bim.query.last.sync')}: ${Utils.Date.formatDateTime(lastSync)}` : ''}
              >
                <Icon className={`${styles.SyncIcon} ${syncing.running ? 'syncing' : ''}`} icon="sync" />
                {t(syncing.running ? 'syncing' : 'sync')}{' '}
                {lastSync > 0 && selectedConnection !== 'BIMWarehouse' ? (
                  syncing.errorMessage ? (
                    <Icon
                      title={`${t('bim.query.last.sync')}: ${Utils.Date.formatDateTime(lastSync)}`}
                      className={`${styles.lastSyncDateIcon}`}
                      icon="calendar_month"
                    />
                  ) : (
                    Utils.Date.formatDateTime(lastSync)
                  )
                ) : (
                  ''
                )}
              </div>
              {syncing.errorMessage && (
                <>
                  <Icon title={syncing.errorMessage} className={styles.syncingInfoIcon} icon="info" />
                  <Icon
                    title={t('bim.query.database.syncing.clean.error')}
                    className={styles.syncingClearIcon}
                    icon="close"
                    onClick={clearSyncingError}
                  />
                </>
              )}
            </div>
            {selectedConnection !== 0 && (
              <BngSearch className={`${styles.SearchTable}`} onChange={(value) => setFilter(value)} />
            )}
            <div
              className={`${styles.ToJsonButton} ${disableButtons}`}
              onClick={
                disableButtons
                  ? undefined
                  : () => {
                      dispatch(
                        MODALS.open(BngLogDialog, {
                          log: JSON.stringify(dbSchemas, null, 2),
                          title: 'JSON',
                          footer: (
                            <div className={`${styles.LogDialogFooter}`}>
                              <BngButton
                                className={`${styles.SaveJsonButton}`}
                                onClick={() => {
                                  const exportName = connections.find((c) => c.id === selectedConnection);
                                  const dataStr =
                                    'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(dbSchemas));
                                  const el = document.createElement('a');
                                  el.setAttribute('href', dataStr);
                                  el.setAttribute('download', exportName + '.json');
                                  document.body.appendChild(el); // required for firefox
                                  el.click();
                                  el.remove();
                                }}
                              >
                                {t('save')}
                              </BngButton>
                            </div>
                          ),
                        })
                      );
                    }
              }
            >
              {'{JSON}'}
            </div>
          </div>
          <div className={`${styles.schemasWrapper}`}>
            {!_.isEmpty(dbSchemas) && (
              <RenderList
                schemas={filteredDbSchemas}
                fetchChildList={fetchChildList}
                handleSelection={handleSelection}
                selectedConnection={selectedConnection}
                filter={filter}
              />
            )}
          </div>
        </Accordion>
        {!isEditing && (
          <>
            <BngButton
              className={`${styles.NextStepButton}`}
              disabled={!stepIsValid}
              onClick={() => {
                if (stepIsValid) {
                  bimEventBus.emit(
                    RIGHT_MENU_TOGGLE_SUBMENU,
                    options.find((o) => o.key === BimQueryConfigurationMenuItem.KEY)
                  );
                }
              }}
            >
              {t('bim.query.connection.configuration.next.step')}
            </BngButton>
          </>
        )}
      </AccordionList>
    </>
  );
}
