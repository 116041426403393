import styles from './BngInMemoryLogs.module.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import BngLogsDialog from 'components/bng/logs/BngLogsDialog';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import bngYup from 'components/bng/form/yup/BngYup';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngTag from 'components/bng/ui/BngTag';
import { MODALS } from 'components/ui/redux/Actions';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngSearch from 'components/bng/ui/BngSearch';
import Icon from 'components/ui/common/Icon';
import BngLogDialog from 'components/bng/logs/BngLogDialog';
import useInterval from 'components/hooks/useInterval';
import BngInMemoryLogDetails from 'components/bng/pages/admin/structures/logs/BngInMemoryLogDetails';
import OpConfirmation from 'components/ui/OpConfirmation';
import useFetchData from 'components/hooks/useFetchData';
import BngButton from 'components/bng/ui/BngButton';
import AddAdditionalDialog, { ADDITIONALS } from 'components/bng/accounts/additionals/AddAdditionalDialog';
import useTranslation from 'components/hooks/useTranslation';

const BngInMemoryLogsSchema = bngYup((yup) => {
  return yup.object().shape({
    endedWith: yup.string().nullable().default(''),
    loadType: yup.string().nullable().default(''),
  });
});

const filterRows = (selectedRows, filters) => {
  const {
    searchTerm,
    filterButton: { endedWith, loadType },
  } = filters;

  if (!_.isEmpty(searchTerm)) {
    selectedRows = selectedRows.filter((row) => {
      return Utils.Strings.includesIgnoreCase(row.id, searchTerm);
    });
  }

  if (!_.isEmpty(endedWith)) {
    selectedRows = selectedRows.filter((row) => {
      return row.endedWith.toLowerCase() === endedWith.toLowerCase();
    });
  }

  if (!_.isEmpty(loadType)) {
    selectedRows = selectedRows.filter((row) => {
      return row.loadType.toLowerCase() === loadType.toLowerCase();
    });
  }

  return selectedRows;
};

function BngInMemoryLogsFilterDropdown({ className = '', onChange = _.noop, initialValues = {} }) {
  const { msg } = useBimContext();

  const endedWithOpts = useMemo(
    () =>
      FilterOpts({
        options: Object.values(Utils.Scheduling.IN_MEMORY_ENDED_WITH),
      }),
    []
  );

  const loadTypeOpts = useMemo(
    () =>
      FilterOpts({
        options: Object.values(Utils.Scheduling.LOAD_TYPE).map((item) => {
          return {
            label: item,
            value: item.replace('.', '_'),
          };
        }),
      }),
    []
  );

  const initialFormValues = useMemo(() => _.merge({}, BngInMemoryLogsSchema.default(), initialValues), [initialValues]);

  const filterFields = [
    {
      name: 'endedWith',
      label: msg.t('ended.with'),
      options: endedWithOpts,
    },
    {
      name: 'loadType',
      label: msg.t('inMemory_loadTypes'),
      options: loadTypeOpts,
    },
  ];

  return (
    <FilterDropdown
      className={`BngInMemoryLogsFilterDropdown ${className}`}
      fields={filterFields}
      initialFormValues={initialFormValues}
      dropdownSchema={BngInMemoryLogsSchema}
      onChange={onChange}
      overDialog={true}
    />
  );
}

function InMemoryLogDialog({ executionLog, closeModal }) {
  const context = useBimContext();
  const $log = useFetchData(() => Api.Origin.findLog(executionLog.inMemoryId, executionLog.id), [executionLog]);

  useInterval(() => {
    if ($log.isLoading || $log.data?.endedWith !== Utils.Scheduling.ENDED_WITH.RUNNING) {
      return;
    }

    $log.reload();
  }, 5000);

  return (
    <BngLogDialog
      className="InMemoryLogDialog"
      closeModal={closeModal}
      log={$log.data?.log ?? ''}
      title={context.msg.t('export.scheduling.log')}
      footer={
        !$log.data ? null : (
          <div className="mt-2">
            <div className="flex-center-items gap-2">
              <span>
                <strong>{context.msg.t('ended.with')}:</strong>
              </span>
              <EndedWithTag endedWith={$log.data?.endedWith} />
            </div>
            <div className="mt-1">{$log.data.endDate && <InMemoryLogDialogFooter log={$log.data} />}</div>
          </div>
        )
      }
      loading={!$log.data && $log.isLoading}
    />
  );
}

function InMemoryLogDialogFooter({ log }) {
  const dispatch = useReduxDispatch();
  const { msg, accountEnabledForBilling } = useBimContext();
  const isQuotaExceeded = log.endedWith === Utils.Scheduling.ENDED_WITH.QUOTA_EXCEEDED;
  return (
    <div className={`InMemoryLogDialogFooter ${styles.logFooterContainer}`}>
      <span>
        <strong>{msg.t('replication.time')}:</strong> {log.replicationDuration}s ({msg.t('finished')}:{' '}
        {Utils.Date.formatDateTime(log.refreshTriggeredDate)})
      </span>
      <div className={styles.logFooterButtonWrapper}>
        {isQuotaExceeded && accountEnabledForBilling && (
          <BngButton
            onClick={async () => {
              dispatch(
                MODALS.open(AddAdditionalDialog, {
                  featureKey: ADDITIONALS.QUOTA.key,
                  isConsumptionAdditional: true,
                  onActivate: () => window.location.reload(),
                })
              );
            }}
          >
            {msg.t('request.quota.button')}
          </BngButton>
        )}
        <BngIconButton
          className={`${styles.logFooterButton}`}
          icon={'build'}
          onClick={() => {
            dispatch(
              MODALS.open(BngInMemoryLogDetails, {
                log,
              })
            );
          }}
        />
      </div>
    </div>
  );
}

function EndedWithTag({ endedWith = Utils.Scheduling.ENDED_WITH.RUNNING }) {
  const { t } = useTranslation();
  return (
    <div className={`EndedWithTag ${styles.flexBoxCenterContainer}`}>
      <BngTag className={`${styles.bngTags} ${styles[endedWith]}`}>
        {endedWith === Utils.Scheduling.ENDED_WITH.RUNNING && <Icon className={'spin-element'} icon={'refresh'} />}
        <span>{t(endedWith)}</span>
      </BngTag>
    </div>
  );
}

export default function BngInMemoryLogs({ closeModal, structureId }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ searchTerm: '', filterButton: {} });
  const $fetchingLogs = useRef(false);

  const fetchLogs = async (showLoading = true) => {
    if ($fetchingLogs.current) {
      return;
    }
    $fetchingLogs.current = true;

    if (showLoading) {
      setLoading(true);
    }
    try {
      const result = await Api.Origin.findLogs(structureId);
      setLogs(result);
    } catch (e) {
      UiMsg.ajaxError(null, e);
      console.error('Error while trying to fetch logs ', e);
    } finally {
      $fetchingLogs.current = false;
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  useInterval(() => {
    fetchLogs(false);
  }, 10000);

  useEffect(() => {
    fetchLogs();
  }, []);

  const filteredRows = useMemo(() => {
    return filterRows(logs, filters);
  }, [logs, filters]);

  const tableColumns = useMemo(() => {
    return [
      {
        label: context.msg.t('id'),
        colClassName: styles.tableLeftPadding,
        rowClassName: styles.tableLeftPadding,
        render: (row) => row.id,
      },
      {
        label: context.msg.t('started'),
        render: (row) => Utils.Date.formatDateTime(row.date),
      },
      {
        label: context.msg.t('ended'),
        render: (row) => Utils.Date.formatDateTime(row.endDate),
      },
      {
        label: context.msg.t('ended.with'),
        render: (row) => {
          return <EndedWithTag endedWith={row.endedWith} />;
        },
      },
      {
        label: context.msg.t('time.load'),
        render: (row) => context.msg.t(row.loadTime),
      },
      {
        label: context.msg.t('inMemory_loadTypes'),
        render: (row) => context.msg.t(row.loadType.replace('_', '.')),
      },
      {
        label: context.msg.t('updated'),
        render: (row) => {
          if (row.endedWith === Utils.Scheduling.ENDED_WITH.RUNNING) {
            return null;
          }

          return (
            <div className={`${styles.flexBoxCenterContainer}`}>
              <Icon
                className={`${row.refreshTriggered ? styles.IconSuccess : styles.IconError}`}
                icon={row.refreshTriggered ? 'done' : 'close'}
              />
            </div>
          );
        },
      },
      {
        label: context.msg.t('actions'),
        render: (row) => {
          return (
            <div className="no-wrap">
              <BngIconButton
                icon={'description'}
                onClick={() => {
                  dispatch(
                    MODALS.open(InMemoryLogDialog, {
                      executionLog: row,
                      afterClose: () => {
                        fetchLogs(false);
                      },
                    })
                  );
                }}
              />
              {row?.endedWith === Utils.Scheduling.ENDED_WITH.RUNNING && (
                <BngIconButton
                  className={`${styles.cancelLoadButton}`}
                  icon={'block'}
                  title={context.msg.t('request.data.load.cancellation')}
                  onClick={() => {
                    try {
                      return OpConfirmation({
                        title: context.msg.t('alert'),
                        message: context.msg.t('stop.load.confirmation.message'),
                        onConfirm: async () => {
                          setLoading(true);
                          await Api.Origin.cancelDataLoad(row.id);
                          const timeoutId = setTimeout(() => {
                            fetchLogs(false);
                            setLoading(false);
                            clearTimeout(timeoutId);
                          }, 2500);
                        },
                        msg: context.msg,
                        confirmText: context.msg.t('yes'),
                      });
                    } catch (e) {
                      UiMsg.ajaxError(null, e);
                      console.error('Error while trying to cancel Load', e);
                    }
                  }}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <BngLogsDialog
      className="BngInMemoryLogsDialog"
      closeModal={closeModal}
      title={context.msg.t('log_link_text')}
      isEmpty={_.isEmpty(logs)}
      loading={loading}
      rows={filteredRows}
      columns={tableColumns}
      emptyMessage={context.msg.t('Scheduling.logs.empty')}
      headerButtons={
        <React.Fragment>
          <BngSearch
            className={`${styles.logsSearch}`}
            value={filters.searchTerm}
            onChange={(val) => {
              setFilters({ ...filters, searchTerm: val });
            }}
            title={context.msg.t('search.object.name')}
            alwaysOpen
            inline
          />
          <BngInMemoryLogsFilterDropdown
            alwaysOpen={false}
            onChange={(values) => setFilters({ ...filters, filterButton: values })}
            initialValues={filters.filterButton}
          />
          <BngIconButton icon={'refresh'} onClick={fetchLogs} />
        </React.Fragment>
      }
    />
  );
}
