import { Axios } from 'commonUtils';

class CentralMonitoringApi {
  async testHttpAction(action) {
    return await Axios.post(`/spr/central-monitoring/ui/test-http-action`, action);
  }

  async updateAction(action) {
    return await Axios.postData(`/spr/central-monitoring/ui/actions`, action);
  }

}

export default CentralMonitoringApi;