import styles from './ActivatePlanLabel.module.css';

import React from 'react';
import useBimContext from 'components/hooks/useBimContext';

export default function ActivatePlanLabel() {
  const context = useBimContext();
  return (
    <div className={`ActivatePlanLabel ${styles.activatePlanContainer}`}>
      <div className={`${styles.labelWrapper}`}>
        <span className={`${styles.activateLabel}`}>
          {context.msg.t(context.projectActivated ? 'project.activated' : 'activate.now')}
        </span>
      </div>
    </div>
  );
}
