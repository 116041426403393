// css imported on CommonCssImports.js
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import RGL, { WidthProvider } from 'bim-react-grid-layout';
import { ResizeSensor } from 'css-element-queries';

import { Axios } from 'commonUtils';
import RenderablePreload, {
  RENDERABLE_PRELOAD_UPDATE_SIZE,
} from 'components/ui/dashboard/components/RenderablePreload';
import RenderQueue from 'components/ui/dashboard/components/RenderQueue';
import Api from 'components/Api';
import DeepEqualComponent from 'components/DeepEqualComponent';
import ContextEnhancer from 'components/ContextEnhancer';
import DashGridContext from 'components/ui/dashboard/components/DashGridContext';
import RequiredFilters from 'components/ui/dashboard/components/RequiredFilters';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import BngEmpty from 'components/bng/ui/BngEmpty';
import EmptyComponent from 'components/ui/EmptyComponent';
import DashboardItemMenuExplorerMobile from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuExplorerMobile';
import bimEventBus from 'BimEventBus';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import { DashSkipOnResizeComponent } from 'components/ui/dashboard/DashShared';
import { buildContainerCustomStyles } from 'components/ui/dashboard/components/ContainerMenu';
import ColorUtils from 'components/bng/colorPicker/ColorUtils';

const ReactGridLayout = WidthProvider(RGL, { measureBeforeMount: true });

let CACHED_RENDER_QUEUE = null;
let LAST_SCROLL_POSITION = 0;

class DashGrid extends DeepEqualComponent {

    static propTypes = {
        className: PropTypes.string,
        mobile: PropTypes.bool,
        useDefaultMenus: PropTypes.bool,
        fromCockpit: PropTypes.number,
        onLayoutChange: PropTypes.func,
        changes: PropTypes.array,
    };

    static defaultProps = {
        className: '',
        layouts: {},
        divisions: 96,
        nextItemDelay: 1,
        editMode: false,
        breakpointView: 'DESKTOP',
        itemsData: {},
        mobile: false,
        isFromPublisher: false,
        privateVisibility: true,
        isPresentation: false,
        exportView: false,
        dashboardPath: '',
        style: {
            allowMargin: false,
            allowContainerMargin: false,
            allowBoxShadow: false,
            borderStyleClass: '',
            bodyStyle: '',
            borderType: '',
            highlightBoxColor: '',
            backgroundTheme: 'NONE',
            itemTransparency: 0,
        },
        requiredFilters: [],
        initialFilters: [],
        filters: [],
        mobileMenuComponent: EmptyComponent,
        dashboardItemMenuComponent: EmptyComponent,
        containerMenuComponent: EmptyComponent,
        useDefaultMenus: undefined,
        fromCockpit: undefined,
        onLayoutChange: _.noop,
        changes: [],
        itemClonerComponent: EmptyComponent,
    };

    state = {
        rowHeight: null,
        filters: [],
        initialRenderComplete: false,
        selectedItem: null,
        storeSelectedItems: null, // TODO padronizar todos os itens selecionados aqui e unificar com store???
        onCockpit: false,
        customSelect: null,
    };

    gridItems = {};

    cleanupFunctions = [];

    constructor(props) {
        super(props);
        if (!CACHED_RENDER_QUEUE) {
            CACHED_RENDER_QUEUE = new RenderQueue({delay: props.nextItemDelay * 100});
        }
        this.renderQueue = CACHED_RENDER_QUEUE;
        this._renderedItems = 0;

        let FILTER_CHANGE_TEMP_CACHE = null;
        try {
            if (props.mobile && Utils.parentWindowIsAccessible()) {
              FILTER_CHANGE_TEMP_CACHE = parent.__FILTER_CHANGE_TEMP_CACHE;
            }
        } catch (e) {
            console.error('Error loading FILTER_TEMP_CACHE: ', e);
        }

        if (FILTER_CHANGE_TEMP_CACHE) {
            this.state.filters = FILTER_CHANGE_TEMP_CACHE;
        } else if (props.initialFilters) {
            this.state.filters = [...props.initialFilters];
        }

        this.state.onCockpit = !!document.getElementById('cockpit-item-panel');
    }

    findItemSize(uuid) {
        const $parent = j(`.DashGridItem-${uuid}`);
        const $itemSize = j(`.item-size-${uuid}`);
        const headerSize = $parent.find('.widget-header').filter(':visible').height() || 0;
        const $placeHolder = j('.react-grid-placeholder');
        const $size = $placeHolder.length > 0 ? $placeHolder : $parent;
        const {borderWidth, borderHeight} = application.dashboards.parseBorders({$parent: $size});

        const size = {
            width: Math.ceil(parseInt($size.css('width'), 10)) - borderWidth,
            height: Math.ceil(parseInt($size.css('height'), 10) - headerSize) - borderHeight
        };

        const $dashBox = $parent.find(`#dashbox-${uuid}`);
        const gridItemRef = this.gridItems[uuid];
        return {$parent, $itemSize, $dashBox, size, gridItemRef};
    }

    lastLayout = null;

    updateOnResize = () => {
        setTimeout(async () => {
            const layout = [window.innerWidth, window.innerHeight];
            if (this.lastLayout === null || layout[0] !== this.lastLayout[0] && layout[1] !== this.lastLayout[1]) {
                this.lastLayout = layout;
                window.__FILTER_CACHE_ON_RESIZE = window.__FILTER_CHANGE_TEMP_CACHE;
                let {rowHeight} = this.calculateRowHeight();
                this.setState({rowHeight});
                for (let k in this.gridItems) {
                    const ref = this.gridItems[k].ref;
                    if (!!ref && !!ref.updateSize) {
                        ref.updateSize(null, true);
                    }
                }
            }
        }, 500);
    };

    pageRememberScrollHandler(event) {
        if (this.scrollHeight > this.clientHeight && this.scrollTop > 0) {
            LAST_SCROLL_POSITION = this.scrollTop ?? 0;
        }
    }

    componentDidMount() {
        this.lastLayout = [window.innerWidth, window.innerHeight];
        let {rowHeight} = this.calculateRowHeight();
        const cancelToken = Axios.generateCancelToken();
        window.__CANCEL_RENDER_DASHBOARDITEM_REQUEST = cancelToken;
        this.setState({rowHeight, cancelToken});
        j('body').on('click', this.bodyClickHandler);

        if (application.page.isMobile()) {
            window.addEventListener('resize', this.updateOnResize, true);
        }

        const dashPageCtxUnsubscribe = useDashboardPageCtx.subscribe(
          ({ dashGridCustomSelect, selectedItems, filters }) => {
            const update = {};

            if (dashGridCustomSelect !== this.state.customSelect) {
              update.customSelect = dashGridCustomSelect;
            }

            if (selectedItems !== this.state.storeSelectedItems) {
              update.storeSelectedItems = selectedItems;
            }

            if (filters !== this.state.filters) {
              update.filters = filters;
            }

            if (!_.isEmpty(update)) {
              this.setState(update);
            }
          }
        );

        const removeUpdateOnResize = bimEventBus.on('CockpitButtons:HEADER_EXPANDED', () => {
          const { rowHeight } = this.calculateRowHeight();
          this.setState({ rowHeight });
        });

        this.cleanupFunctions.push(() => {
          j('body').off('click', this.bodyClickHandler);
          if (application.page.isMobile()) {
            window.removeEventListener('resize', this.updateOnResize);
          }
          if (this.$pageContent) {
            this.$pageContent.off('scroll', this.pageRememberScrollHandler);
          }
          delete window.__CANCEL_RENDER_DASHBOARDITEM_REQUEST;
          dashPageCtxUnsubscribe();
          removeUpdateOnResize();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.$pageContent
            && this.state.initialRenderComplete
            && (this.state.initialRenderComplete !== prevState.initialRenderComplete)) {
            j('.breakpoint-MOBILE, .breakpoint-MOBILE_HORIZONTAL').length > 0
                ? this.$pageContent = j('.breakpoint-MOBILE, .breakpoint-MOBILE_HORIZONTAL')
                : this.$pageContent = j('.render-container-panel, #cockpit-item-panel, #body-dashboard-home');
            this.$pageContent.scrollTop(LAST_SCROLL_POSITION);
            this.$pageContent.on('scroll', this.pageRememberScrollHandler);
        } else if(this.$pageContent) {
            if(LAST_SCROLL_POSITION > this.$pageContent.scrollTop()) {
                this.$pageContent.scrollTop(LAST_SCROLL_POSITION);
            }
        }
    }

    requiredFiltersInfo = () => {
        let allRequiredFilterFilled = true;
        let requiredFiltersInfo = [];

        for (const filterId of this.props.requiredFilters) {
          const filter = this.state.filters.find(({ id }) => id === filterId);
          let ok = true;
          if (!filter || filter.members.length === 0) {
            const propFilter = this.props.availableFilters.find(({ id }) => id === filterId);
            if (!propFilter || propFilter.selectedMembers.length === 0) {
              allRequiredFilterFilled = false;
              ok = false;
            }
          }

          const filterInfo = this.props.filters[filterId];
          if (filterInfo) {
            requiredFiltersInfo.push({ ...filterInfo, ok });
          }
        }

        return {
          filled: allRequiredFilterFilled,
          info: requiredFiltersInfo,
        };
    };

    componentWillUnmount() {
        for (const cleanupFunction of this.cleanupFunctions) {
            try {
                cleanupFunction();
            } catch (e) {
                console.error('Error on cleanupFunction()', e);
            }
        }
    }

    bodyClickHandler = (event) => {
        if (!this.state.selectedItem) {
            return;
        }

        const $target = j(event.target);
        const $closestItem = $target.closest('.DashGridItem');
        const match = [$target, $closestItem].find($el => $el.hasClass(`DashGridItem-${this.state.selectedItem}`));

        if (match) {
            return;
        }

        this.setState({selectedItem: null});
    };

    clickedOnItem = (event, layout, data) => {
        if (!this.props.editMode) {
            return;
        }

        event?.stopPropagation?.();

        const isContainer = this.isContainer(layout.i);
        const bp = this.calculateBreakpoint();

        if (this.state.customSelect) {
            // Ignore if action come from drag
            if (event === 'dragstart'
              || event?.target?.classList?.contains('react-resizable-handle')
              || this._itemDragMoved) {
                return;
            }
            this.state.customSelect.onSelect({
                layout,
                data,
                ...bp,
                isContainer,
            }, event);
        } else {
            if (layout.i === this.state.selectedItem) {
                return;
            }

            this.setState({selectedItem: layout.i});

            if (bp.effectiveBreakpoint !== 'DESKTOP') {
                const $mobileItem = document.querySelector(`#MobileItem-${layout.i}`);
                if (!$mobileItem) {
                    return;
                }
                j('.ActionList.MobileMenuList .ActionListItems').stop().animate({
                    scrollTop: $mobileItem.position().top
                }, 1000);
            }
        }
    };

    buildStyleClass = () => {
        return application.dashboards.buildStyleClass(this.props.style);
    };

    filterLayouts = (layout, viewBreakpoint = 'DESKTOP') => {
        if (this.props.isFromPublisher
            || this.props.isPresentation
            || viewBreakpoint === 'DESKTOP') {
            return layout;
        }
        return layout.filter(item => this.props.itemsData[item.i]?.availableOnMobile ?? false);
    };

    getMockupBackground(isMobileBreakpoint, viewBreakpoint) {
        if (!isMobileBreakpoint || application.page.isMobile()) {
            return '';
        }
        const mockups = {
            'MOBILE': `url('${Api.baseUrl()}/resources/images/dashboard/mockup_mobile.svg')`,
            'MOBILE_HORIZONTAL': `url('${Api.baseUrl()}/resources/images/dashboard/mockup_mobile_horizontal.svg')`,
        };
        return mockups[viewBreakpoint.toUpperCase()];
    }

    // Create an array with selected and empty filters
    selectedFilters = () => {
        const filters = this.state.filters.slice();
        for (const filter of this.props.availableFilters) {
            const match = filters.find(f => f.id === filter.id);
            if(!match) {
                filters.push({
                    id: filter.id,
                    members: [],
                    restrictionType: '"SHOW_SELECTED"'
                });
            }
        }
        return filters;
    }

    findItemData(item) {
        return this.state.itemOverride[item.i] ?? this.props.itemsData[item.i]
    }

    render() {
        const requiredFilterInfo = this.requiredFiltersInfo();

        if (!requiredFilterInfo.filled) {
            let requiredFiltersInfo = _.sortBy(requiredFilterInfo.info, ['caption']);
            return (
                <div ref={ref => this._requiredFilterEl = ref}>
                    <RequiredFilters requiredFiltersInfo={requiredFiltersInfo}/>
                </div>
            );
        }

        const usingMobileDevice = application.page.isMobile();
        const {effectiveBreakpoint, viewBreakpoint} = this.calculateBreakpoint();
        const isMobileBreakpoint = effectiveBreakpoint.startsWith('MOBILE');
        const {fullLayout} = this.processEmptyLayouts(this.props.layouts, effectiveBreakpoint);
        const mobileFrameClass = !usingMobileDevice && isMobileBreakpoint ? 'mobile-frame' : '';

        const gridProps = {
            cols: this.props.divisions,
            rowHeight: this.state.rowHeight,
            margin: [0, 0],
            containerPadding: [0, 0],
            compactType: null,
            isDraggable: this.props.editMode,
            isResizable: this.props.editMode,
            preventCollision: true
        };

        if (this.props.editMode) {
            gridProps.onLayoutChange = this.onLayoutChange;
            gridProps.onResizeStart = this.onItemResizeStart;
            gridProps.onResize = this.onItemResize;
            gridProps.onResizeStop = this.onItemResizeStop;
            gridProps.onDragStart = this.onItemDragStart;
            gridProps.onDragStop = this.onItemDragStop;
            gridProps.onDrag = this.onItemDrag;
        }

        let GridComponent = ReactGridLayout;
        if (!usingMobileDevice && isMobileBreakpoint) {
            GridComponent = RGL;
            gridProps.width = effectiveBreakpoint === 'MOBILE_HORIZONTAL' ? 623 : 320;
        }

        const layout = this.filterLayouts(fullLayout, viewBreakpoint);
        const alertCustomMobile = _.isEqual(this.props.layouts['MOBILE'], this.props.layouts['DESKTOP']);
        const highlightColor = !!this.props.style.highlightBoxColor ? this.props.style.highlightBoxColor : "#unset";
        const dashboardBgThemeName = `DashTheme-${this.props.style.backgroundTheme.toLowerCase()}`;

        // Components
        const LazyMobileMenu = this.props.mobileMenuComponent;
        const LazyDashboardItemMenu = this.props.dashboardItemMenuComponent;
        const LazyContainerMenu = this.props.containerMenuComponent;
        const LazyItemClonerComponent = this.props.itemClonerComponent;

        const gridElementWidth = gridProps.width ? (gridProps.width + 17) : (this.props.editMode ? 'calc(100% - 6px)' : '100%');
        const lastYDivision = layout?.reduce((acc, l) => Math.max(acc, (l.y ?? 0) + (l.h ?? 0)), 0) ?? 0;

        return (
            <DashGridContext.Provider value={{
                dashboardPath: this.props.dashboardPath,
                filters: this.state.filters,
                editMode: this.props.editMode,
                isFromPublisher: this.props.isFromPublisher,
                privateVisibility: this.props.privateVisibility,
                isPresentation: this.props.isPresentation,
                fromCockpit: this.props.fromCockpit
            }}>
                <LazyItemClonerComponent item={this.state.selectedItem ? this.props.itemsData[this.state.selectedItem] : null}
                                editMode={this.props.editMode}
                />

                {(this.props.editMode && !isMobileBreakpoint) && (
                  <>
                      {/* TODO display background grid, useful for debug
                  <style
                  dangerouslySetInnerHTML={{
                    __html: `
                    .free-style-marker-class {
                        background-image: repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
                        background-size: calc(100% / ${gridProps.cols}) ${gridProps.rowHeight}px;
                    }`,
                  }}
                />
                */}

                      {_.range(0, Math.ceil(lastYDivision / gridProps.cols) + 1).map((i) => {
                          const pageHeight = gridProps.cols * gridProps.rowHeight;
                          const pageBreakIdx = i + 1;
                          return (
                            <div
                              key={`pageBreak${i}`}
                              className="dashboard-page-break-indicator w-100"
                              style={{ top: `${pageBreakIdx * pageHeight}px` }}
                            >
                                <p>
                                    <span>{this.props.context.msg.t('dashboard.page.break', pageBreakIdx)}</span>
                                </p>
                            </div>
                          );
                      })}
                  </>
                )}

                    <div id="DashGridComponent" className={`background-${viewBreakpoint} ${this.buildStyleClass()}`}
                         data-opacity={1 - (this.props.style.itemTransparency / 100)}
                         ref={ref => this.$dashGridEl = ref}
                    >

                        {(isMobileBreakpoint && this.props.editMode) &&
                            <LazyMobileMenu {...this.props}
                                            selectedItem={this.state.selectedItem}
                                            onLayoutChange={this.onLayoutChange}
                                            layout={fullLayout}
                            />
                        }

                        <div className={`div-${viewBreakpoint} ${mobileFrameClass}`} style={{
                            width: gridElementWidth,
                            backgroundImage: this.getMockupBackground(isMobileBreakpoint, viewBreakpoint)
                        }}>
                            <div className={`breakpoint-${viewBreakpoint} ${mobileFrameClass}`}
                                 style={{width: gridElementWidth}}
                                 ref={ref => this._breakpointEl = ref}>
                                {this.state.rowHeight &&
                                    <BngEmptyDashboard layout={layout}
                                                       width={gridProps.width - 10 || '98%'}
                                                       context={this.props.context}
                                                       path={this.props.dashboardPath}
                                                       viewBreakpoint={viewBreakpoint}>
                                        <GridComponent  {...gridProps}
                                                        layout={layout}
                                                        style={{width: gridProps.width}}
                                                        className={`DashGrid grid-stack item-content-container ${this.state.initialRenderComplete ? 'loaded' : ''} ${this.props.editMode ? 'inEditMode' : ''}`}>
                                            {layout.map((layoutItem) => {
                                                const itemData = this.props.itemsData[layoutItem.i];
                                                const isContainer = this.isContainer(layoutItem.i);
                                                const addingContainer = j('.ContainerCreatorParent').length > 0;
                                                const gridElClassName = `DashGridItem grid-stack-item DashGridItem-${layoutItem.i}
                                            ${isContainer ? 'Container' : 'Item'}
                                            ${this.state.customSelect ? this.state.customSelect.itemClass(layoutItem) : ''}
                                            ${layoutItem.i === this.state.selectedItem ? 'selectedToClone' : ''}
                                            `;

                                                return (
                                                    <GridEl onClick={(event) => this.clickedOnItem(event, layoutItem, itemData)}
                                                            key={layoutItem.i}
                                                            className={gridElClassName}

                                                    >
                                                        {({position}) => {
                                                            const buildItemMenuProps = ({id, item, inContainer = false, position}) => {
                                                                item = item ?? {id};
                                                                item.id = item.id ?? id;

                                                                return {
                                                                    item,
                                                                    isMobile: this.props.mobile,
                                                                    dashboardPath: this.props.dashboardPath,
                                                                    editMode: this.props.editMode,
                                                                    inContainer,
                                                                    position,
                                                                    style: this.props.style,
                                                                    dashGridItemRef: () => this.gridItems[item.id],
                                                                    selectedFilters: this.selectedFilters(),
                                                                    currentBreakpoint: {
                                                                        effectiveBreakpoint,
                                                                        viewBreakpoint
                                                                    }
                                                                };
                                                            }

                                                            const buildGridItemContentProps = ({item = {}, inContainer = false, position, onSelectItem, containerStyles}) => {
                                                                return {
                                                                    style: this.props.style,
                                                                    renderQueue: this.renderQueue,
                                                                    mobile: this.props.mobile,
                                                                    isPresentation:this.props.isPresentation,
                                                                    exportView:this.props.exportView,
                                                                    dashItemId: item.id,
                                                                    dashboardPath: this.props.dashboardPath,
                                                                    inContainer,
                                                                    selectedFilters: this.state.filters,
                                                                    ...item,
                                                                    ref: item.id ? ref => this.gridItems[item.id] = {ref} : undefined,
                                                                    renderCallback: this.itemRenderCallback,
                                                                    cancelToken: this.state.cancelToken,
                                                                    changes: this.props.changes,
                                                                    position,
                                                                    onSelectItem,
                                                                    containerStyles,
                                                                };
                                                            }

                                                            let highlightClassname = '';
                                                            if (itemData?.highlight) {
                                                                highlightClassname += 'item-highlight';
                                                                const lightHighlight = ColorUtils.isLightColor(highlightColor);
                                                                if (lightHighlight) {
                                                                    highlightClassname += ' color-highlight-light';
                                                                }
                                                            }

                                                            return (
                                                                <React.Fragment>

                                                                    <div className={
                                                                        `${itemData?.transparentbackground ? 'ImgTransparentBg' : ''} grid-stack-item-content grid-stack-item-content-${itemData?.viewType} 
                                                                        ${dashboardBgThemeName} 
                                                                        ${highlightClassname}`
                                                                    }
                                                                         style={!itemData?.highlight ? {backgroundColor: application.dashboards.buildBackgroundItemColor(this.props.style, itemData?.additionalProps)} : {}}
                                                                         onClick={(event) => this.clickedOnItem(event, layoutItem, itemData)}
                                                                         data-edit-mode={this.props.editMode}>

                                                                        <style type={'text/css'} dangerouslySetInnerHTML={{
                                                                            __html: `.DashGrid .DashGridItem div.widget-body-${layoutItem.i} { ${this.props.style.bodyStyle} }`
                                                                        }}/>

                                                                        <div key={`${layoutItem.i}:${layoutItem.w}x${layoutItem.h}`}
                                                                            className={`itemsize item-size-${layoutItem.i} ${isContainer ? 'onLeft' : ''}`}
                                                                            data-highlight-color={highlightColor}>
                                                                            {position.width} x {position.height}
                                                                        </div>

                                                                        {this.props.mobile && this.props.privateVisibility && isMobileBreakpoint && !isContainer &&
                                                                            <DashboardItemMenuExplorerMobile
                                                                                item={itemData}
                                                                                dashboardPath={this.props.dashboardPath}
                                                                                selectedFilters={this.state.filters}
                                                                            />

                                                                        }

                                                                        {!isContainer && (this.props.useDefaultMenus || !this.props.mobile) && !addingContainer &&
                                                                            <LazyDashboardItemMenu
                                                                              {...buildItemMenuProps({
                                                                                  id: layoutItem.i,
                                                                                  item: itemData,
                                                                                  position
                                                                              })}
                                                                            />
                                                                        }

                                                                        <div
                                                                        >
                                                                            {!isContainer &&
                                                                                    <DashGridItemContent
                                                                                      {...buildGridItemContentProps({
                                                                                          item: itemData,
                                                                                          inContainer: false,
                                                                                          position,
                                                                                          onSelectItem: isContainer ? undefined : (event) => this.clickedOnItem(event, layoutItem, itemData),
                                                                                      })}
                                                                                    />
                                                                            }

                                                                            {isContainer &&
                                                                                <div
                                                                                    ref={ref => this.gridItems[layoutItem.i] = {ref}}>

                                                                                    {(() => {
                                                                                        const {containerClassName, containerHeaderClassName, customHeight, backgroundStyle, headerCustomStyles } = buildContainerCustomStyles({
                                                                                            customStyle: itemData.additionalProps.customStyle
                                                                                                ? itemData.additionalProps.style
                                                                                                : this.props.style.containerStyle,
                                                                                            highlight: itemData.highlight,
                                                                                            showTitle: itemData.additionalProps.showTitle,
                                                                                            dashTransparency: this.props.style.itemTransparency,
                                                                                            iconAlign: itemData.additionalProps.iconAlign,
                                                                                        });

                                                                                        let containerWidth = position.width;
                                                                                        let containerHeight = position.height;

                                                                                        let containerMargin;

                                                                                        if (this.props.style.allowContainerMargin) {
                                                                                            containerMargin = this.props.style.containerMargin;
                                                                                            containerWidth -= containerMargin * 2;
                                                                                            containerHeight -= containerMargin * 3;
                                                                                        } else {
                                                                                            containerMargin = 10;
                                                                                            containerHeight -= containerMargin * 2;
                                                                                        }

                                                                                        if (itemData.additionalProps.showTitle) {
                                                                                            containerHeight -= customHeight;
                                                                                        }

                                                                                        const childs = itemData.additionalProps.items;
                                                                                        const backgroundOpacity = 1 - (this.props.style.itemTransparency/100)

                                                                                        return (
                                                                                            <div className={containerClassName} style={{display: 'grid', background: backgroundStyle ?? ''}} data-opacity={backgroundOpacity}>
                                                                                                <LazyContainerMenu item={itemData}
                                                                                                                   itemsData={this.props.itemsData}
                                                                                                                   editMode={this.props.editMode}
                                                                                                                   style={this.props.style}
                                                                                                                   currentBreakpoint={{
                                                                                                                       effectiveBreakpoint,
                                                                                                                       viewBreakpoint
                                                                                                                   }}
                                                                                                                   customStylesClassName={containerHeaderClassName}
                                                                                                                   customStyleProps={headerCustomStyles}
                                                                                                />

                                                                                                <RGL
                                                                                                    className={`ContainerRGL`}
                                                                                                    width={containerWidth}
                                                                                                    rowHeight={containerHeight / gridProps.cols}
                                                                                                    cols={gridProps.cols}
                                                                                                    layout={childs}
                                                                                                    margin={[0, 0]}
                                                                                                    containerPadding={[containerMargin, containerMargin]}
                                                                                                    compactType={null}
                                                                                                    isDraggable={false}
                                                                                                    isResizable={false}
                                                                                                    preventCollision={true}
                                                                                                >
                                                                                                    {childs.map(childLayout => {
                                                                                                        const childItem = this.props.itemsData[childLayout.i] ?? ({viewType: ''});
                                                                                                        childItem.highlight = itemData.highlight;
                                                                                                        return (
                                                                                                            <GridEl key={childLayout.i}
                                                                                                                    className={`grid-stack-item-content grid-stack-item-content-${childItem.viewType} ${dashboardBgThemeName} ContainerChild Item`}>
                                                                                                                {({position: containerPosition}) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <div
                                                                                                                                className={`itemsize item-size-${childLayout.i} ${itemData.additionalProps.showTitle ? '' : 'onLeft'} hidden`}></div>
                                                                                                                            {this.props.mobile && isMobileBreakpoint && !addingContainer &&
                                                                                                                                <DashboardItemMenuExplorerMobile
                                                                                                                                    item={childItem}
                                                                                                                                    dashboardPath={this.props.dashboardPath}
                                                                                                                                    selectedFilters={this.state.filters}
                                                                                                                                />
                                                                                                                            }
                                                                                                                            {(this.props.useDefaultMenus || !this.props.mobile) && !addingContainer &&
                                                                                                                                <LazyDashboardItemMenu
                                                                                                                                    {...buildItemMenuProps({
                                                                                                                                        id: childLayout.i,
                                                                                                                                        item: childItem,
                                                                                                                                        position: containerPosition,
                                                                                                                                        inContainer: true,
                                                                                                                                        containerStyles: itemData.additionalProps,
                                                                                                                                    })}
                                                                                                                                />
                                                                                                                            }
                                                                                                                            <DashGridItemContent
                                                                                                                                {...buildGridItemContentProps({
                                                                                                                                    item: childItem,
                                                                                                                                    inContainer: true,
                                                                                                                                    position: containerPosition,
                                                                                                                                    onSelectItem: (event) => this.clickedOnItem(event, layoutItem, childItem),
                                                                                                                                    containerStyles: itemData.additionalProps,
                                                                                                                                })}
                                                                                                                            />
                                                                                                                        </>
                                                                                                                    );
                                                                                                                }}
                                                                                                            </GridEl>
                                                                                                        )
                                                                                                    }
                                                                                                    )}
                                                                                                </RGL>
                                                                                            </div>
                                                                                        )
                                                                                    })()}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }}
                                                    </GridEl>
                                                );
                                            })}
                                        </GridComponent>
                                    </BngEmptyDashboard>
                                }
                            </div>
                        </div>
                        {
                            (alertCustomMobile && this.props.mobile && application.page.isMobile()) &&
                            <div className='alertConfigDashMobile'>
                                <div className='paddingConfigDashMobile'>
                            <span
                                className="spamAlertConfigDashMobile">{this.props.context.msg.t('dashboard.not.ready.for.mobile')}.</span>
                                </div>
                                <div className="timesAlertConfigDashMobile"
                                     onClick={event => j('.alertConfigDashMobile').hide()}>
                                    <i className='fa fa-times'></i>
                                </div>
                            </div>

                        }
                    </div>
            </DashGridContext.Provider>
        );
    }

    isContainer(itemUuid) {
        const itemData = this.props.itemsData[itemUuid];
        return itemData && itemData.viewType === 'container';
    }

    calculateBreakpoint = () => {
        let effectiveBreakpoint = this.props.breakpointView;
        let viewBreakpoint = effectiveBreakpoint;

        if (this.props.mobile) {
            if (application.page.isMobile()) {
                effectiveBreakpoint = 'MOBILE';
                if (application.page.isHorizontalPosition()) {
                    effectiveBreakpoint = 'MOBILE_HORIZONTAL';
                }
            } else {
                effectiveBreakpoint = 'DESKTOP';
            }
            viewBreakpoint = effectiveBreakpoint;
        }

        return {
            effectiveBreakpoint,
            viewBreakpoint,
        };
    };

    itemRenderCallback = () => {
        if (this.state.initialRenderComplete) {
            return;
        }
        const {effectiveBreakpoint} = this.calculateBreakpoint();

        const layout = this.props.layouts[effectiveBreakpoint] || [];
        this._renderedItems++;
        if (this._renderedItems === layout.length) {
            this.setState({initialRenderComplete: true});
            this._renderedItems = 0;
        }
    };

    calculateRowHeight() {
        let rowHeight = 50;

        if (this.props.staticSize) {
            let y = 0;
            this.props.layouts["DESKTOP"].forEach(e => y = Math.max(y, e.y + e.h));
            const rowHeight = y > 0 ? this.props.height / y : 1;
            return {rowHeight};
        }

        const _el = this._breakpointEl || this._requiredFilterEl;
        const {effectiveBreakpoint} = this.calculateBreakpoint();
        if (this.props.breakpointView !== 'DESKTOP') {
            const height = jQuery(_el).height();
            rowHeight = height / this.props.divisions;
        } else {
            const viewportHeight = application.page.viewport()[1];
            const offsetFromTop = jQuery(_el).offset().top;
            // _el offset change when parent is scrolling
            const scrollPosition = document.querySelector('.free-style-marker-class, .cockpit-item-panel')?.scrollTop ?? 0;
            const topOffset = Math.max(0, scrollPosition + offsetFromTop);
            if (effectiveBreakpoint === 'MOBILE' || effectiveBreakpoint === 'MOBILE_HORIZONTAL') {
                rowHeight = (viewportHeight - 5) / this.props.divisions;
            } else {
                rowHeight = (viewportHeight - topOffset - 5) / this.props.divisions;
            }
        }

        if (effectiveBreakpoint === 'MOBILE_HORIZONTAL') {
            rowHeight *= 2.8;
        }
        return {rowHeight};
    }

    onItemResize = (layout, oldItem, newItem) => {
        try {
            const { $itemSize, size } = this.findItemSize(newItem.i);
            $itemSize.text(`${size.width} x ${size.height}`);
        } catch (e) {
            console.error('Error onItemResize()', e);
        }
    };

    onItemResizeStop = (layout, oldItem, newItem, placeholder, event, element) => {
        try {
            const { $itemSize, size, gridItemRef } = this.findItemSize(newItem.i);
            $itemSize.text(`${size.width} x ${size.height}`);
            bimEventBus.emit(RENDERABLE_PRELOAD_UPDATE_SIZE, {
                id: newItem.i
            });

            // Added to fix broken resize placeholder after react 18 update
            window.setTimeout(() => {
                window.requestAnimationFrame(() => {
                    const nodes = document.querySelectorAll('.react-grid-placeholder');
                    nodes.forEach(el => el.classList.add('hide'));
                });
            }, 100);
        } catch (e) {
            console.error('Error onItemResizeStop()', e);
        }
    };

    onItemResizeStart = () => {
        try {
            // Added to fix broken resize placeholder after react 18 update
            window.setTimeout(() => {
                window.requestAnimationFrame(() => {
                    const nodes = document.querySelectorAll('.react-grid-placeholder');
                    nodes.forEach(el => el.classList.remove('hide'));
                });
            }, 100);
        } catch (e) {
            console.error('Error onItemResizeStart()', e);
        }
    };

    onItemDragStart = (layout, oldItem, newItem, placeholder, event, element) => {
        try {
            const isItemMenuOpen = !!document.querySelector('.dashboard-item-popper');
            if (isItemMenuOpen) return false;

            const isContainerMenuOpen = !!document.querySelector('.ContainerMenuPopper');
            if (isContainerMenuOpen) return false;

            // Prevent drag while resizing a bigtable column (ag-header-cell-resize)
            // Prevent drag while moving map (leaflet)
            // Prevent drag while clicking on button (DashboardItemOptsButton)
            for (const c of event.target.classList) {
                if (c.includes('ag-header-cell-resize') ||
                  c.includes('leaflet') ||
                  c.includes('DashboardItemOptsButton')) {
                    return false;
                }
            }

            clearTimeout(this._dragTimeout);
            delete this._itemDragMoved;
            document.body.classList.add('DashGridDraggingItem');
            this.clickedOnItem('dragstart', oldItem, this.props.itemsData[oldItem.i]);
        } catch (e) {
            console.error('Error onItemDragStart()', e);
        }
    };

    onItemDrag = (layout, oldItem, newItem, placeholder, event, element) => {
        try {
            this._itemDragMoved = true;
        } catch (e) {
            console.error('Error onItemDrag()', e);
        }
    };

    onItemDragStop = () => {
        this._dragTimeout = setTimeout(() => {
            try {
                delete this._itemDragMoved;
                document.body.classList.remove('DashGridDraggingItem');
            } catch (e) {
                console.error('Error onItemDragStop()', e);
            }
        }, 200);
    };

    processEmptyLayouts(layouts, effectiveBreakpoint) {
        let layout = layouts[effectiveBreakpoint] || [];
        if (effectiveBreakpoint === 'MOBILE_HORIZONTAL' && layout.length === 0) {
            layout = layouts['MOBILE'];
        }

        const maxPositionFn = ({y, h}) => y + h;
        let nextY = _.maxBy(layout, maxPositionFn) || {y: 0, h: 0};
        nextY = maxPositionFn(nextY);
        const {itemDefaultSizeX, itemDefaultSizeY} = {
            itemDefaultSizeX: effectiveBreakpoint === 'DESKTOP' ? this.props.divisions / 3 : this.props.divisions,
            itemDefaultSizeY: this.props.divisions / 3,
        };
        return {
            fullLayout: layout.map(i => {
                let item = {...i};
                if (!('x' in item)) {
                    nextY++;
                    item.w = itemDefaultSizeX;
                    item.h = itemDefaultSizeY;
                    item.x = 0;
                    item.y = nextY;
                    nextY += itemDefaultSizeY;
                }
                return item;
            })
        };
    }

    onLayoutChange = async (newLayout, fromDragAndDrop = false) => {
        try {
            const { effectiveBreakpoint } = this.calculateBreakpoint();
            this.props.onLayoutChange({ breakpoint: effectiveBreakpoint, layout: newLayout });
        } catch (e) {
            console.error('Error on onLayoutChange()', e);
        }
    };

    stateForExport = () => {
        const $dashCtx = useDashboardPageCtx.getState();
        const filters = _.cloneDeep($dashCtx.dash?.gridData?.availableFilters ?? []);
        const initialFilters = $dashCtx.dash?.gridData?.initialFilters ?? [];

        // User filters are only available on 'initialFilters' props, so mark them as runtimeRestriction
        for (const initialFilter of initialFilters) {
            if (filters.some(f => f.id === initialFilter.id)) {
                continue;
            }

            filters.push({
                id: initialFilter.id,
                selectedMembers: initialFilter.members
                  .map(value => ({
                      value,
                      label: value
                  })),
                runtimeRestriction: (
                  _.isEmpty(initialFilter.runtimeRestriction)
                    ? initialFilter.members
                    : initialFilter.runtimeRestriction
                ).slice()
            });
        }

        return {
            filters,
            itemOverrides: _.cloneDeep($dashCtx.itemOverrides),
            mdxOverrides: Object.entries(window.RENDERABLE_PRELOAD_CACHE ?? {})
              .map(([id, result]) => {
                  return {
                      id,
                      mdx: result.mdx,
                      drillData: {
                          drillState: result.additionalProps?.drillState,
                          drillResponse: result.additionalProps?.drillResponse,
                      }
                  }
              })
              .filter(mo => !!mo.mdx)
        }
    }

}

class DashGridItemContent extends DashSkipOnResizeComponent {
  static defaultProps = {
    resizable: true,
  };

  //MIAUUUUU filtros com dependência do bean (restrição de filtro por item)
  getFilters = () => {
    let { restrictFilters, selectedFilters, filters, mobile, path } = this.props;
    filters = (_.isArray(filters) ? filters : JSON.parse(filters || '[]')) ?? [];

    const isXmlaObject =
      Utils.Object.isKpi(path) ||
      Utils.Object.isNewMap(path) ||
      Utils.Object.isBigTable(path) ||
      Utils.Object.isAnalysis(path);

    const applyRestrictFilter = restrictFilters && isXmlaObject;
    const totalSelectedFilters = selectedFilters?.length ?? 0;
    const totalFilters = filters?.length ?? 0;
    const moreFiltersThanSelected = totalFilters > totalSelectedFilters;

    if (totalSelectedFilters > 0 && !applyRestrictFilter && !moreFiltersThanSelected) {
      return selectedFilters;
    }

    if ((mobile || moreFiltersThanSelected) && isXmlaObject) {
      filters = _.cloneDeep(filters);
      for (const mf of filters) {
        const match = selectedFilters.find((f) => f.id === mf.id);
        if (match) {
          mf.members = match.members ? _.cloneDeep(match.members) : mf.members ?? [];
          mf.restrictionType = match.restrictionType ?? mf.restrictionType;
        }
      }
    }

    return filters;
  };

  //END MIAUUUU
  render() {
    const { style, onSelectItem, exportView, isPresentation, mobile, dashItemId, ...props } = this.props;

    return (
      <div
        className={`widget-box mini-header dashboarditem dashboard-item ${style?.borderStyleClass}`}
        onClick={onSelectItem}
        ref={(ref) => (this.$el = ref)}
      >
        <div className="widget-header">
          <h4 className="lighter smaller">
            {!props.isText && (
              <div style={{ fontSize: '14px' }}>
                <Icon icon={props.icon} />
                {props.caption}
              </div>
            )}
          </h4>
          <span className="widget-toolbar" />
        </div>
        <div className={`widget-body widget-body-${props.id}`}>
          <RenderablePreload
            key={`${props.id}-${props.path ?? ''}`}
            {...props}
            style={style}
            filters={this.getFilters()}
            ref={(ref) => (this.renderComponent = ref)}
            dashItemId={dashItemId}
            mobile={mobile}
            isPresentation={isPresentation}
            exportView={exportView}
          />
        </div>
      </div>
    );
  }
}

function GridEl({ children, className = '', ...props }) {
  const [position, setPosition] = useState({ width: 0, height: 0 });
  const gridElRef = useRef();

  useEffect(() => {
    if (!gridElRef.current) return;

    const recalcPosition = () => {
      const newPosition = {
        width: gridElRef.current?.clientWidth || 0,
        height: gridElRef.current?.clientHeight || 0,
      };
      setPosition(newPosition);
    };

    const sensor = new ResizeSensor(gridElRef.current, recalcPosition);

    recalcPosition();

    return () => sensor.detach();
  }, [gridElRef.current]);

  const clonedChildren = React.Children.map(children, (child) => React.cloneElement(child, { position }));
  const childrenFunction = _.isFunction(children) ? children : children[0];

  return (
    <div className={`GridEl ${className}`} ref={gridElRef} {...props}>
      {gridElRef.current && (
        <GridElChildrenWrapper>
          {childrenFunction({ position })}
          <div className="CustomResizeHandle">
            <svg height="15" width="15">
              <circle cx="7" cy="7" r="6" stroke="#0b64fe" strokeWidth="1" fill="white" />
            </svg>
          </div>
          {clonedChildren}
        </GridElChildrenWrapper>
      )}
    </div>
  );
}

function GridElChildrenWrapper({ children }) {
    if (application.page.isIphone()) {
        return (
          <div className='appleDevicesFix'>
              {children}
          </div>
        );
    } else {
        return children;
    }
}

const BngEmptyDashboard = ({layout, viewBreakpoint, path, width, context, children, onCockpit = false}) => {
    const canManipulateObject = !!context.permissions && context.permissions.canManipulateObject(path);

    const emptyMsg = !onCockpit && canManipulateObject ? (
        <span>{context.msg.t('dashboard.no.items')}</span>
    ) : <span>{context.msg.t('dashboard.no.items.viewer')}</span>;

    return (
        <BngEmpty isEmpty={layout.length === 0}
                  style={{width, margin: 'auto'}}
                  className={`EmptyDashboard-${viewBreakpoint || 'MOBILE-DEVICE'}`}
                  message={emptyMsg}
                  children={children}
        />
    );
};

export default ContextEnhancer(DashGrid);
