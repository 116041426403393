import { Axios } from 'commonUtils';

class MonitorApi {
  findKpiData = async (path) => {
    return await Axios.getData(`/spr/central-monitoring/monitor/kpi`, {
      params: {
        path,
      },
    });
  };
}

export default MonitorApi;
