import styles from './MessageActionDialog.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { FastField, Field, Formik } from 'formik';
import { connect } from 'react-redux';

import { DefaultDialogActions } from 'components/ui/FormUtils';
import { BngCodeMirror } from 'components/bng/form/BngCodeMirror';
import {
  buildSortedMemberOptions,
  createMembersForOptions,
  fetchMembersForOptions,
} from 'components/bng/exportScheduling/BngExportRecipientsTab';
import Dialog from 'components/ui/Dialog';
import useTranslation from 'components/hooks/useTranslation';
import BngButtonGroup from 'components/bng/ui/Buttons/BngButtonGroup';
import bngYup from 'components/bng/form/yup/BngYup';
import BngForm from 'components/bng/form/BngForm';
import BngField from 'components/bng/form/BngField';
import BngUserItemSelect from 'components/bng/form/itemSelect/BngUserItemSelect';
import useBimContext from 'components/hooks/useBimContext';
import BngObjectItemSelect from 'components/bng/form/itemSelect/BngObjectItemSelect';
import BngSwitch from 'components/bng/form/BngSwitch';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Utils from 'components/Utils';
import useFetchData from 'components/hooks/useFetchData';
import UiMsg from 'components/ui/UiMsg';

const MessageActionSchema = bngYup((yup) => {
  return yup.object({
    name: yup.string().trim().required().default(''),
    deviceMessages: yup.array(yup.string().trim()).min(1, '').required().default([]),
    sendTo: yup.array().required('Share.errors.users').min(1, '').default([]),
    message: yup.string().trim().default(''),
    sendObjects: yup.object({
      type: yup.string().default(Utils.Scheduling.TYPE.OBJECT),
      contents: yup
        .array(
          yup.object({
            name: yup.string(),
            path: yup.string(),
            exportType: yup.string().default(Utils.Scheduling.EXPORT_TYPE.PDF),
          })
        )
        .nullable()
        .default([]),
    }),
    sendPdf: yup.boolean().default(false),
  });
});

const findCaptionOnMenuTree = (menu = [], path = '') => {
  for (const item of menu) {
    if (item.href === path) {
      return item.text;
    }

    if (item.children) {
      const result = findCaptionOnMenuTree(item.children, path);
      if (result) {
        return result;
      }
    }
  }
  return '';
};

function buildInitialValues(action, context = {}, menu = []) {
  const val = MessageActionSchema.default();
  if (action.name) {
    const { name = '', members = [], sendPdf = false, messageActionObjects = [], uuid = '', id = '' } = action;

    const deviceMessages = action.deviceMessages.map(({ device }) => device);
    const message = action.deviceMessages[0]?.message || '';

    const sendTo = createMembersForOptions({
      msg: context.msg,
      groups: members.filter(({ members }) => members),
      projectUsers: members.filter(({ members }) => !members).map((item) => ({ user: { ...item } })),
    });

    const sendObjects =
      messageActionObjects.length > 0
        ? {
            contents: Array.from(messageActionObjects)
            .sort((a, b) => a.id - b.id)
            .map(({ name, content }) => {
              if (!name) {
                name = findCaptionOnMenuTree(menu, content);
              }
              return { name, path: content };
            }),
            type: 'OBJECTS',
          }
        : val.sendObjects;

    Object.assign(val, {
      name,
      deviceMessages,
      sendTo,
      message,
      sendPdf,
      sendObjects,
      uuid,
      id,
    });
  }
  return val;
}

function MessageActionDialog({ onSave = _.noop, closeModal = _.noop, action, menu = [] }) {
  const context = useBimContext();
  const { t } = useTranslation();

  const [showSendObjects, setShowSendObjects] = useState(action?.messageActionObjects || action?.sendObjects);

  const { data: availableMembers = [] } = useFetchData(
    async () =>
      await fetchMembersForOptions({
        projectId: context.project.id,
        msg: context.msg,
      })
  );

  const messageTypeOpts = useMemo(
    () => [
      {
        value: 'EMAIL',
        label: t('email'),
        icon: 'mail',
      },
      {
        value: 'PORTAL',
        label: t('portal'),
        icon: 'notifications',
      },
      {
        value: 'SMS',
        label: t('sms'),
        icon: 'call',
      },
      {
        value: 'WHATSAPP',
        label: t('whatsapp'),
        icon: 'fa-whatsapp',
      },
    ],
    []
  );

  const variableOpts = useMemo(
    () => [
      { value: '[value]', label: t('kpi.value') },
      {
        value: '[target]',
        label: t('kpi.goal'),
      },
      { value: '[prefix]', label: t('prefix') },
      { value: '[suffix]', label: t('suffix') },
    ],
    []
  );

  const initialValues = useMemo(() => buildInitialValues(action, context, menu.roots), []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={MessageActionSchema}
      onSubmit={async (values) => {
        try {
          let sendObjects = [];
          if (!_.isEmpty(values.sendObjects)) {
            sendObjects = values.sendObjects.contents.map((item) => item.path);
          }

          const action = {
            name: values.name,
            deviceMessages: values.deviceMessages,
            users: values.sendTo.filter((item) => item.type === 'USER').map((user) => user.userId),
            groups: values.sendTo.filter((item) => item.type === 'GROUP').map((group) => group.groupId),
            message: values.message,
            sendObjects,
            sendPdf: values.sendPdf,
            type: 'MessageAction',
            id: values.id,
            uuid: values.uuid,
          };

          await onSave({ values: action });
          closeModal();
        } catch (e) {
          console.error('Error on form submit', e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      {({ values, isSubmitting }) => {
        const containsEmailOrWhatsApp = ['EMAIL', 'WHATSAPP'].some((v) => values.deviceMessages.includes(v));
        const mobileSharing = ['SMS', 'WHATSAPP'].some((v) => values.deviceMessages.includes(v));

        const membersOptions = buildSortedMemberOptions({
          members: availableMembers,
          isSharingMobile: mobileSharing,
          sendTo: values.sendTo,
          msg: context.msg,
        });

        useEffect(() => {
          if (!containsEmailOrWhatsApp && showSendObjects) {
            setShowSendObjects(false);
          }
        }, [values.deviceMessages]);

        return (
          <Dialog
            className={`MessageActionDialog large`}
            title={t('message.sending')}
            newDialogLayout={true}
            onClose={closeModal}
            loading={isSubmitting}
          >
            <BngForm>
              <Dialog.Body className={`${styles.messageAction}`}>
                <FastField
                  name={'name'}
                  label={t('identifier')}
                  labelClassName={`${styles.fieldLabel}`}
                  placeholder={t('schedule.recurrence.identifier.placeholder')}
                  component={BngField}
                  maxLength={'120'}
                  showErrors={false}
                />

                <Field
                  name={'deviceMessages'}
                  label={t('send.via')}
                  labelClassName={`${styles.fieldLabel}`}
                  component={BngField}
                  showErrors={false}
                  inputComponent={BngButtonGroup}
                  options={messageTypeOpts}
                  checkbox={true}
                  selectedIndicator
                  forceArray
                  fill
                />

                <Field
                  name={`sendTo`}
                  label={t('send.to.users')}
                  labelClassName={`${styles.fieldLabel}`}
                  component={BngField}
                  inputComponent={BngUserItemSelect}
                  options={membersOptions}
                />

                <div className={`${styles.containerMessage}`}>
                  <div className={`${styles.sectionMessage}`}>
                    <label className={`${styles.fieldLabel} ${styles.labelMessage}`}>{t('message')}</label>
                    <span className={`${styles.lightLabel}`}>({t('optional')})</span>
                  </div>
                  {mobileSharing && <label className={`${styles.lightLabel}`}>*140 {t('caracteres')}</label>}
                </div>

                <Field
                  name={'message'}
                  component={BngCodeMirror}
                  variables={variableOpts}
                  height={100}
                  lineNumbers={false}
                  textVars={true}
                  maxLength={mobileSharing ? 140 : null}
                />

                <div className={`mt-4 ${styles.sendSwitch}`}>
                  <div>
                    <span className={`${styles.fieldLabel}`}>{t('analytical.objects')}</span>
                  </div>
                  <BngSwitch
                    onChange={() => setShowSendObjects(!showSendObjects)}
                    checked={showSendObjects}
                    disabled={!containsEmailOrWhatsApp}
                  />
                </div>

                {containsEmailOrWhatsApp && showSendObjects && (
                  <>
                    <Field
                      name={`sendObjects`}
                      label={''}
                      labelClassName={`${styles.fieldLabel}`}
                      component={BngField}
                      showErrors={false}
                      inputComponent={BngObjectItemSelect}
                      includeCockpits={false}
                    />
                    <div className={`${styles.attachmentPdf}`}>
                      <Field name="sendPdf" component={BngCheckbox} label={t('monitor.attachment.pdf')} />
                    </div>
                  </>
                )}
              </Dialog.Body>
              <Dialog.Footer>
                <DefaultDialogActions closeModal={closeModal} />
              </Dialog.Footer>
            </BngForm>
          </Dialog>
        );
      }}
    </Formik>
  );
}

export default connect((state) => ({ menu: state.menu }))(MessageActionDialog);
