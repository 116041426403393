import './BngDropdownTags.css'
import React, {useEffect, useState} from 'react';
import {BngDropdown} from "components/bng/ui/BngDropdown";
import BngSearch from "components/bng/ui/BngSearch";
import ContextEnhancer from "components/ContextEnhancer";
import BngLazyListView from "components/bng/ui/BngLazyListView";
import {UiBlocker} from "components/bng/ui/UiBlocker";
import Icon from 'components/ui/common/Icon';
import {BngIconButton} from "components/bng/ui/BngIconButton";
import UiMsg from "components/ui/UiMsg";

const BngDropdownTags = ({
                             items = [],
                             customButton = null,
                             context = null,
                             beforeOpen = _.noop,
                             onApply = _.noop,
                             onCreate = null,
                             multipleSelect = false,
                             emptyLabel = context.msg.t('tag.not.found'),
                             createLabel = 'create.tag',
                             confirmButton = false,
                             onClose = _.noop,
                             ...props
                         }) => {

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        setSelectedItems(items.filter(i => i.selected));
    }, [items])


    const visibleItems = searchTerm
        ? items.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()))
        : items;

    const changeItemValue = async (checked, item) => {
        const _items = selectedItems.filter(i => i.label !== item.label);
        if (!checked) {
            if (multipleSelect) {
                setSelectedItems(_items.concat([{...item, selected: checked}]))
            } else {
                try {
                    setSelectedItems([item]);
                } catch (e) {
                    console.error(e);
                    UiMsg.error('Error', e);
                    setLoading(false);
                }
            }
        } else {
            setSelectedItems(_items)
        }
    };

    const clearSelection = async (clearAndSalve = true) => {
        setSelectedItems([]);
        if (clearAndSalve) {
            try {
                await onApply(null);
            } catch (e) {
                console.error(e);
                UiMsg.error('Error', e);
                setLoading(false);
            }
        }
    }

    const handleOpen = async () => {
        setLoading(true);
        await beforeOpen();
        setLoading(false);
    };

    const handleClose = async (selectedItem, closeAndSave) => {
        setSearchTerm('');
        if (closeAndSave) {
            await onApply(!multipleSelect ? selectedItem.group ? selectedItem : selectedItems[0] : selectedItems);
        }
        await onClose();
    };


    const handleCreate = async () => {
        setLoading(true);
        try {
            await onCreate(searchTerm);
        } catch (e) {
            console.error(e);
            UiMsg.error('Error', e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <BngDropdown {...props}
                     icon={"local_offer"}
                     className="BngDropdownTags"
                     popperClassName="BngDropdownTagsPopper"
                     onOpen={handleOpen}
                     onClose={(selectedItem) => handleClose(selectedItem, !confirmButton)}
                     customButton={customButton}
                     customOptions={({closeDropdown}) => (
                         <UiBlocker block={loading}>
                             <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                 <div className="ActionListSearch">
                                     <div style={{position: "relative", width: "100%"}}>
                                         <BngSearch onChange={setSearchTerm}
                                                    maxLength="25"
                                                    inline={true}
                                                    simple={false}
                                                    placeholder={context.msg.t('header.search')}
                                         />
                                     </div>
                                 </div>
                                 {onCreate &&
                                     <BngIconButton icon="add" onClick={handleCreate} disabled={searchTerm === ''}/>
                                 }
                             </div>
                             <hr className="m-0"/>
                             <fieldset className="BngDropdownCheckboxOpts" style={{maxHeight: '180px'}}>
                                 <BngLazyListView
                                     listProp={{
                                         height: Math.min(visibleItems.length * 27),
                                         itemCount: visibleItems.length,
                                         itemSize: 27,
                                         style: {
                                             overflow: 'visible',
                                         }
                                     }}
                                     itemRender={(props) => (
                                         <CheckboxItem {...props}
                                                       visibleItems={visibleItems}
                                                       selectedItems={selectedItems}
                                                       changeItemValue={changeItemValue}
                                                       closeDropdown={closeDropdown}
                                                       multipleSelect={multipleSelect}
                                         />
                                     )}
                                 />
                             </fieldset>
                             {!loading && visibleItems.length === 0 &&
                             <>
                                 <div className="tagsNotFound">
                                     <Icon icon="manage_search" style={{fontSize: '30px', paddingRight: '5px'}}/>
                                     <span>
                                         {emptyLabel}
                                     </span>
                                 </div>
                                 {searchTerm.length !== 0 && onCreate &&
                                 <ul className="bng-dropdown unstyled">
                                     <li
                                         onClick={(event) => {
                                             handleCreate();
                                         }}
                                         className="EmptyCreateTag"
                                     >
                                         <React.Fragment>
                                             <Icon icon="add"/>
                                             <span className='EmptyCreateTagText'>{
                                                 context.msg.t(createLabel, searchTerm)}
                                             </span>
                                         </React.Fragment>
                                     </li>
                                 </ul>
                                 }
                             </>
                             }
                             <div className="tagsFooterActions">
                                 {(confirmButton || selectedItems.length !== 0) &&
                                     <div className={`bng-dropdown tagsFooterButton tagsClearSelection ${selectedItems.length === 0 ? 'disabled': ''}`}
                                          onClick={() => {
                                              if (selectedItems.length !== 0) {
                                                  clearSelection(!confirmButton);
                                                  if (!confirmButton) {
                                                      closeDropdown();
                                                  }
                                              }
                                          }}
                                          style={{fill:'100%'}}>
                                         <Icon icon='close'/>
                                         <span>{context.msg.t('clear')}</span>
                                     </div>
                                 }
                                 {confirmButton &&
                                     <div className='bng-dropdown tagsFooterButton tagsApplySelection'
                                          onClick={async () => {
                                              closeDropdown();
                                              await handleClose(selectedItems, true);
                                          }}
                                          style={{fill:'100%'}}>
                                         <Icon icon='done'/>
                                         <span>{context.msg.t('apply')}</span>
                                     </div>
                                 }
                             </div>
                         </UiBlocker>
                     )}
        />
    );
};

const CheckboxItem = ({visibleItems, selectedItems, index, changeItemValue, style, closeDropdown, multipleSelect}) => {
    const item = visibleItems[index];
    const isSelected = !!selectedItems.find(selectedItem => selectedItem.value === item.value);
    if (!item) return null;
    return (
        <div className='CheckboxItem' onClick={async () => {
            if (multipleSelect) {
                await changeItemValue(isSelected, item);
            } else {
                closeDropdown(item || selectedItems[0]);
            }
        }}>
            <div className="checkboxInputWrapper">
                <input {...style}
                       checked={isSelected}
                       disabled={false}
                       type={multipleSelect ? 'checkbox' : "radio"}
                       className="CheckboxTags"
                       name='tags'
                       readOnly={true}
                />
                <span className={`itemInputLabel ${multipleSelect ? "checkboxLabel" : "radioLabel"}`}/>
            </div>
            <div className="checkboxItemRender" style={{paddingLeft: '25px'}}>
                {item.render(isSelected)}
            </div>
        </div>
    )
};

export default ContextEnhancer(BngDropdownTags);