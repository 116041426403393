import styles from './MarketplaceGroup.module.css';

import React from 'react';

import MarketplaceCard, { RECURRING_TYPES } from 'components/bng/pages/marketplace/MarketplaceCard';
import { proposalUtils } from 'components/service/bng/AccountApi';
import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';
import { MARKETPLACE_GROUPS } from 'components/bng/pages/marketplace/MarketplacePage';
import useBimNavigate from 'components/hooks/useBimNavigate';
import useBimContext from 'components/hooks/useBimContext';
import Utils from 'components/Utils';
import Tooltip from 'components/ui/Tooltip';

const findCurrentPricing = (pricings = []) => {
  return pricings
    .filter((pricing) => pricing.active)
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .shift();
};

export default function MarketplaceGroup({
  title,
  description,
  items = [],
  groupKey,
  searchTerm = '',
  selectCard,
  selectedItems,
  activeAddons = [],
  singleLine = false,
  knowMore = false,
  anchorKey,
}) {
  const { t } = useTranslation();
  const navigate = useBimNavigate();
  const context = useBimContext();
  const isConsultant = Utils.Users.isConsultant(context.user);

  const redirectToTab = () => {
    navigate(`/marketplace?currentTab=${groupKey}`);
  };

  const isAddonContracted = (addon) => {
    return activeAddons.find((a) => {
      const key =
        groupKey === 'additionals' || groupKey === 'addons' || groupKey === 'educational' ? a.feature.key : a.key;
      return (
        key &&
        key === addon.key &&
        (addon.feature?.classification === 'ADDON' ||
          !proposalUtils.canSelectQuantityInMarketplace(addon.marketplaceInfo))
      );
    });
  };

  const filteredItems = items
    ?.sort((a, b) => {
      const aActive = isAddonContracted(a);
      const bActive = isAddonContracted(b);

      if (aActive && !bActive) {
        return 1;
      }

      if (!aActive && bActive) {
        return -1;
      }
      return 0;
    })
    .filter((item, idx) => {
      if (!item.marketplaceInfo.availableForHire && !isConsultant) {
        return false;
      }

      if (singleLine && !searchTerm && idx > 2) {
        return false;
      }
      const translations = proposalUtils.translationsForSales(item);
      if (!searchTerm) {
        return true;
      }
      return translations.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

  if (filteredItems.length === 0) {
    return null;
  }

  return (
    <div id={anchorKey} className={`MarketplaceGroup ${styles.MarketplaceGroup}`}>
      <div className={styles.groupHeader}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>{title}</span>
          <span className={styles.description}>{description}</span>
        </div>
        {knowMore && (
          <a className={styles.knowMoreLink} onClick={redirectToTab}>
            {t('see.more')}
          </a>
        )}
      </div>
      <div className={`${styles.cardArray}`}>
        {filteredItems.map((item) => {
          const translations = proposalUtils.translationsForSales(item);
          const pricing = findCurrentPricing(item.pricings);
          const isTemplate = item.marketplaceInfo.groupKey === MARKETPLACE_GROUPS.TEMPLATE;
          const isChecked = selectedItems.find(
            (selectedItem) => selectedItem.id === item.id && selectedItem.groupKey === groupKey,
          );

          let itemType;
          if (item.feature) {
            itemType = proposalUtils.PRICING_TYPES.ADDITIONAL;
          } else if (item.recurringType) {
            itemType = proposalUtils.PRICING_TYPES.SERVICE;
          } else {
            itemType = proposalUtils.PRICING_TYPES.PLAN;
          }

          return (
            <Tooltip title={translations.description}>
              <span className={`${styles.marketplaceCardContainer}`}>
                <MarketplaceCard
                  key={`${item.id}`}
                  title={translations.name}
                  description={translations.description}
                  pricing={pricing}
                  itemId={item.id}
                  itemType={itemType}
                  recurringType={isTemplate ? RECURRING_TYPES.ONCE : item.recurringType}
                  isChecked={isChecked}
                  selectCard={() =>
                    selectCard({
                      cardItem: item,
                      groupKey,
                      canSelectQuantity: proposalUtils.canSelectQuantityInMarketplace(item.marketplaceInfo),
                    })
                  }
                  isContracted={isAddonContracted(item)}
                  {...item.marketplaceInfo}
                />
              </span>
            </Tooltip>
          );
        })}
        {singleLine && items.length > 3 && !searchTerm && (
          <div className={`MarketplaceCard-SEE_MORE ${styles.moreCard}`} onClick={redirectToTab}>
            <Icon icon={'arrow_forward'} />
            <strong>{t('all.of', [title])}</strong>
          </div>
        )}
      </div>
    </div>
  );
}
