import GatewayTutorialImg from './assets/pageImages/GatewayTutorial0.png';

import React from 'react';

import {
  InstallButtons,
  TermsFooter,
} from 'components/bng/pages/bimGateway/bimGatewayTutorial/BimGatewayTutorialDialog';
import Api from 'components/Api';
import BngStepTutorialDialog from 'components/bng/ui/BngStepTutorial/BngStepTutorialDialog';
import BimGatewayTutorialLayout from 'components/bng/pages/bimGateway/bimGatewayTutorial/components/BimGatewayTutorialLayout';
import useTranslation from 'components/hooks/useTranslation';
import useFetchData from 'components/hooks/useFetchData';

export default function BimGatewayUpdateDialog({ closeModal = _.noop }) {
  const { t } = useTranslation();
  const { data: installerLinks = {} } = useFetchData(async () => await Api.ProjectExtractor.gatewayInfo(), []);

  return (
    <BngStepTutorialDialog
      className={`BimGatewayTutorialDialog`}
      onClose={closeModal}
      renderDefaultFooter={false}
      enableDotNavigation={false}
      steps={[
        {
          title: t(`tutorial.gateway.update.title`),
          renderFooter: () => <TermsFooter />,
          hideClose: false,
          render: () => (
            <BimGatewayTutorialLayout
              title={t(`tutorial.gateway.update.title`)}
              description={t(`tutorial.gateway.update.desc`)}
              image={GatewayTutorialImg}
              component={<InstallButtons installerLinks={installerLinks} />}
            />
          ),
        },
      ]}
    />
  );
}
