import React, { Component } from 'react';

import ContextEnhancer from 'components/ContextEnhancer';
import UiMsg from 'components/ui/UiMsg';
import ObjectRightMenu from 'components/ui/right-menu/ObjectRightMenu';
import Api from 'components/Api';
import BngPulsateLoading from 'components/bng/ui/BngPulsateLoading/BngPulsateLoading';

class BigTableRightMenu extends Component {
  state = {
    loading: false,
  };

    save = async (data) => {
        const objectConfig = {...data.values.objectConfig};
        delete objectConfig.folder;
        delete objectConfig.name;
        try {
            this.setState({loading: true});
            const datasourceConfig = _.cloneDeep(this.props.datasourceConfig);

            // Remove filter dimensions from persisted state
            datasourceConfig.sourceFields = datasourceConfig.sourceFields
              .filter(sf => sf.visible === true || !sf.field.startsWith("BIMF"));

            const bigTableData = {
                id: this.props.id,
                config: {
                    ...this.props.config,
                    calculatedColumns: this.props.calculatedColumns,
                    sortModel: this.props.updateSortModel()
                },
                datasourceConfig,
                datasourceName: this.props.datasourceName,
                ...objectConfig,
            };

      await Api.BigTable.save(bigTableData);

      await data.submitForm();
      await this.props.onSave();
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(this.props.context.msg.t('object.save.success'), e);
    } finally {
      this.setState({ loading: false });
    }
  };

  saveAs = async (data) => {
    const config = Object.assign({}, this.props.config);

    if (config.titleConfig.title === '') {
      config.titleConfig.title = data.name;
    }

    if (config.titleConfig.description === '') {
      config.titleConfig.description = data.description;
    }

    try {
      const bigTableData = {
        name: data.name,
        description: data.description,
        parentFolder: data.folder,
        config: config,
        datasourceConfig: this.props.datasourceConfig,
        datasourceName: this.props.datasourceName,
      };
      const persistedBigTable = await Api.BigTable.saveAs(bigTableData);
      UiMsg.ok(this.props.context.msg.t('object.save.success'));
      window.location = Api.loadObjectUrl({ content: persistedBigTable.path });
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(this.props.context.msg.t('object.save.error'), e);
    }
  };

  remove = async () => {
    try {
      this.setState({ loading: true });
      await Api.BigTable.remove(this.props.id);
      UiMsg.ok(this.props.context.msg.t('object.remove.success'));
      this.setState({ loading: false });
      window.location = `${Api.baseUrl()}/spr/bng/personal-page`;
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      UiMsg.ajaxError(this.props.context.msg.t('object.remove.error'), e);
    }
  };

  render() {
    return (
      <>
        <ObjectRightMenu
          context={this.props.context}
          open={true}
          isPersisted={!_.isEqual(this.props.id, null)}
          objectType={'bigtable'}
          save={this.save}
          isSaving={this.state.loading}
          saveAs={this.saveAs}
          remove={this.remove}
          reload={this.reload}
          showAccordion={this.props.openAccordion}
          setOpenAccordion={this.props.setOpenAccordion}
          onAccordionChange={(Accordion) => this.props.onMenuOpenChange(!!Accordion.component)}
          {...this.props}
        />
        {this.state.loading && <BngPulsateLoading />}
      </>
    );
  }
}

export default ContextEnhancer(BigTableRightMenu);
