import './IndexPage.css';
import React, { PureComponent } from 'react';
import Icon from 'components/ui/common/Icon';
import BngSearch from 'components/bng/ui/BngSearch';
import ContextEnhancer from 'components/ContextEnhancer';
import HtmlComponent from 'components/ui/dashboard/components/HtmlComponent';

class IndexPage extends PureComponent {
  static propTypes = {};

  static defaultProps = {};

  state = {
    search: '',
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.body.style.background = '#f6f6f6';
  }

  componentWillUnmount() {
    document.body.style.background = '';
  }

  render() {
    let { title, icon, content, panels, columnCount } = this.props;
    if (this.state.search.length) {
      const s = this.state.search.toLowerCase();
      panels = panels.filter((p) => p.name.toLowerCase().indexOf(s) !== -1);
    }

    return (
      <div className="row-fluid">
        <div className="cockpit-index-page-summary">
          <div className="widget-box">
            <div className="widget-header">
              <Icon style={{ marginRight: '10px' }} icon={icon} />
              {title}
            </div>
            <div className="widget-body">
              {content && (
                <div className="row-fluid">
                  <div>
                    <HtmlComponent
                      html={content}
                      className="scrollbar-outer cockpit-index-page-content-panel"
                      maxHeight={150}
                    />
                  </div>
                </div>
              )}
              <div>
                {this.props.location === 'cockpit' && (
                  <BngSearch
                    className={'indexFilter'}
                    placeholder={this.props.context.msg.t('search')}
                    alwaysOpen={true}
                    onChange={(event) => this.setState({ search: event })}
                  />
                )}
                <ol className="list cockpit-index-page-ol" style={{ columnCount: columnCount }}>
                  {panels.map((panel) => {
                    const iconStyle =
                      panel.type === 'PATH_AWARE_ITEM'
                        ? {
                            fontSize: 20,
                            position: 'relative',
                            top: 4,
                          }
                        : {
                            fontSize: 16,
                          };

                    return (
                      <li key={panel.id} className="truncate-text cockpit-index-list-item">
                        <a
                          className="iceCmdLnk name mouse-config-false cockpit-index-list-item-link cockpit-index-list-item-link-publisher"
                          onClick={(e) => this.props.onSelect(panel)}
                        >
                          <span style={{ opacity: 0.5 }}>
                            <Icon icon={panel.icon} style={{ color: '#000000', ...iconStyle }} />
                          </span>
                          {panel.name}
                        </a>
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContextEnhancer(IndexPage);
