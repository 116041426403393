import styles from './BimGatewayTutorialDialog.module.css';
import SuccessLottie from 'components/bng/securityCheckup/assets/success_lottie.mp4';
import GatewayTutorial0 from './assets/pageImages/GatewayTutorial0.png';
import GatewayTutorial1 from './assets/pageImages/GatewayTutorial1.gif';
import GatewayTutorial2 from './assets/pageImages/GatewayTutorial2.gif';
import GatewayTutorial3 from './assets/pageImages/GatewayTutorial3.gif';
import WindowsLogo from './assets/windows.png';
import LinuxLogo from './assets/linux.png';

import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';

import BngStepTutorialDialog from 'components/bng/ui/BngStepTutorial/BngStepTutorialDialog';
import useBimContext from 'components/hooks/useBimContext';
import BimGatewayTutorialLayout from 'components/bng/pages/bimGateway/bimGatewayTutorial/components/BimGatewayTutorialLayout';
import BngImageButton from 'components/bng/ui/BngImageButton';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import { SAVE_EVENT } from 'components/bng/pages/bimGateway/ProjectExtractorFormDialog';

export const ConnectionState = {
  Loading: 'Loading',
  Error: 'Error',
  Success: 'Success',
  Unloaded: 'Unloaded',
};

export function InstallButtons({ installerLinks = {}, onSkipTutorial }) {
  const context = useBimContext();

  const disableWindows = _.isEmpty(installerLinks.windowsLink);
  const disableLinux = _.isEmpty(installerLinks.linuxLink);
  return (
    <div className={`InstallButtons ${styles.installButtonsWrapper}`}>
      <div className={`${styles.installButtonsContainer}`}>
        <BngImageButton
          image={WindowsLogo}
          text={context.msg.t('windows.installer')}
          imageAlt={'WindowsLogo'}
          disabled={disableWindows}
          title={disableWindows ? context.msg.t('coming.soon') : ''}
          onClick={() => window.open(installerLinks.windowsLink, '_blank')}
        />
        <BngImageButton
          image={LinuxLogo}
          text={context.msg.t('linux.installer')}
          imageAlt={'LinuxLogo'}
          disabled={disableLinux}
          title={disableLinux ? context.msg.t('coming.soon') : ''}
          onClick={() => window.open(installerLinks.linuxLink, '_blank')}
        />
      </div>
      {onSkipTutorial && (
        <span className={`${styles.skipTutorialBtn}`} onClick={onSkipTutorial}>
          {context.msg.t('skip.tutorial')}
        </span>
      )}
    </div>
  );
}

export function TermsFooter() {
  const context = useBimContext();

  return (
    <div className={`TermsFooter ${styles.termsFooterContainer}`}>
      <span
        dangerouslySetInnerHTML={{
          __html: context.msg.t('tutorial.gateway.termsFooter.desc', [
            context.msg.t('user.menu.terms.link'),
            context.msg.t('user.menu.policies.link'),
          ]),
        }}
      ></span>
    </div>
  );
}

function InstalledCheckbox({ installedGateway, onChange }) {
  const context = useBimContext();

  return (
    <div className={`InstalledCheckbox ${styles.installedCheckboxWrapper}`}>
      <BngCheckbox
        field={{
          onChange: () => onChange(!installedGateway),
          value: installedGateway,
        }}
        size="lg"
      />
      <span>{context.msg.t('tutorial.gateway.installedCheckbox.title')}</span>
    </div>
  );
}

function SupportFooter() {
  const context = useBimContext();
  return (
    <div
      className={`SupportFooter ${styles.supportFooter}`}
      onClick={() =>
        openSuportChat(
          context.user.email,
          context.user.displayName,
          context.support.projectName,
          context.support.chat,
          context.project.projectType
        )
      }
    >
      <span>{context.msg.t('tutorial.gateway.supportFooter')}</span>
    </div>
  );
}

function ConnectionTag({
  id,
  changeToNextStep,
  setConnectionState,
  setCompletedConnection,
  completedConnection,
  connectionState,
  $connection,
}) {
  const { msg, project } = useBimContext();
  const $mounted = useRef(true);

  const runConnectionTest = async () => {
    setConnectionState(ConnectionState.Loading);

    try {
      $connection.current = await Api.ProjectExtractor.requestConnection({ id, projectId: project.id });
    } catch (e) {
      console.error('Error on requestConnection()', e);
      UiMsg.ajaxError(null, e);
      setConnectionState(ConnectionState.Error);
    }

    while ($mounted.current) {
      try {
        $connection.current = await Api.ProjectExtractor.checkConnectionStatus($connection.current.id);
        if ($connection.current.connected) {
          break;
        }
      } catch (e) {
        if (e.response?.status !== 404) {
          console.error('Error on checkConnectionStatus()', { id: $connection.current?.id }, e);
        }
        setConnectionState(ConnectionState.Error);
        break;
      }

      await new Promise((res) => {
        setTimeout(res, 2500);
      });
    }

    if ($connection.current?.connected) {
      setConnectionState(ConnectionState.Success);
      UiMsg.ok(msg.t('bimGateway.connection.success'));
    } else {
      setConnectionState(ConnectionState.Error);
      if ($connection.current) {
        await Api.ProjectExtractor.invalidateConnection($connection.current.id);
        UiMsg.warn(msg.t('bimGateway.connection.warn'));
      }
    }
  };

  useEffect(() => {
    if (connectionState === ConnectionState.Unloaded) {
      runConnectionTest();
    }
    return () => {
      $mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (completedConnection === false && connectionState === ConnectionState.Success) {
      setCompletedConnection(true);
      changeToNextStep();
    }
  }, [connectionState]);

  const loadingAnimation = useSpring({
    from: { opacity: 1, transform: 'scale(1.0)' },
    to: { opacity: 0, transform: 'scale(3.5)' },
    config: { friction: 35, tension: 140 },
    loop: true,
  });

  return (
    <div
      className={`${styles.connectionTagWrapper} ${styles[connectionState]}`}
      onClick={connectionState === ConnectionState.Error ? runConnectionTest : undefined}
    >
      {connectionState === ConnectionState.Loading && (
        <React.Fragment>
          <div className={`${styles.pulsatingLoadingContainer}`}>
            <div className={`${styles.centeredCircle}`} />
            <animated.div style={{ ...loadingAnimation }} className={`${styles.pulsatingLoading}`} />
          </div>
          <span>{msg.t('waiting.connection')}</span>
        </React.Fragment>
      )}
      {connectionState === ConnectionState.Error && (
        <React.Fragment>
          <Icon icon={'refresh'} />
          <span>{msg.t('try.again')}</span>
        </React.Fragment>
      )}
      {connectionState === ConnectionState.Success && <span>{msg.t('success')}</span>}
    </div>
  );
}

export default function BimGatewayTutorialDialog({ closeModal = _.noop, id }) {
  const context = useBimContext();

  const [installedGateway, setInstalledGateway] = useState(false);
  const [connectionState, setConnectionState] = useState(ConnectionState.Unloaded);
  const [completedConnection, setCompletedConnection] = useState(false);
  const [installerLinks, setInstallerLinks] = useState({});

  const $connection = useRef();

  useAsyncEffect({
    onMount: async () => {
      try {
        const result = await Api.ProjectExtractor.gatewayInfo();
        setInstallerLinks(result);
      } catch (e) {
        console.error('Error fetching links', e);
        UiMsg.ajaxError(null, e);
      }
    },
    onUnmount: async () => {
      if ($connection.current) {
        const id = $connection.current.id;
        $connection.current = null;
        await Api.ProjectExtractor.invalidateConnection(id);
      }
    },
  });

  const handleClose = async () => {
    bimEventBus.emit(SAVE_EVENT, {});
    closeModal();
  };

  const stepsComponents = [];

  if (!id) {
    stepsComponents.push({
      render: ({ changeStep }) => (
        <InstallButtons
          onSkipTutorial={() => {
            changeStep(3);
            setInstalledGateway(true);
          }}
          installerLinks={installerLinks}
        />
      ),
      renderFooter: () => <TermsFooter />,
      image: GatewayTutorial0,
      title: context.msg.t(`tutorial.gateway.0.title`),
      description: context.msg.t(`tutorial.gateway.0.desc`),
    });

    stepsComponents.push({
      render: () => (
        <InstalledCheckbox installedGateway={installedGateway} onChange={(val) => setInstalledGateway(val)} />
      ),
      nextStepDisabled: !installedGateway && !completedConnection,
      image: GatewayTutorial1,
      title: context.msg.t(`tutorial.gateway.1.title`),
      description: context.msg.t(`tutorial.gateway.1.desc`),
    });

    stepsComponents.push({
      className: styles.thirdPageWrapper,
      image: GatewayTutorial2,
      title: context.msg.t(`tutorial.gateway.2.title`),
      description: context.msg.t(`tutorial.gateway.2.desc`),
    });
  }

  stepsComponents.push({
    render: ({ changeToNextStep }) => (
      <ConnectionTag
        id={id}
        connectionState={connectionState}
        setConnectionState={setConnectionState}
        completedConnection={completedConnection}
        setCompletedConnection={setCompletedConnection}
        $connection={$connection}
        changeToNextStep={changeToNextStep}
      />
    ),
    className: styles.fourthPageWrapper,
    image: GatewayTutorial3,
    title: context.msg.t(`tutorial.gateway.3.title`),
    description: context.msg.t(`tutorial.gateway.3.desc`),
  });

  stepsComponents.push({
    stepDisabled: connectionState !== ConnectionState.Success,
    video: SuccessLottie,
    renderFooter: null,
    title: context.msg.t(`tutorial.gateway.4.title`),
    description: context.msg.t(`tutorial.gateway.4.desc`),
  });

  const steps = stepsComponents.map((component, idx) => {
    return {
      ...component,
      renderFooter: component.hasOwnProperty('renderFooter')
        ? _.isFunction(component.renderFooter)
          ? component.renderFooter()
          : null
        : () => <SupportFooter />,
      hideClose: false,
      render: (props = {}) => {
        return (
          <BimGatewayTutorialLayout
            title={component.title ?? ''}
            description={component.description ?? ''}
            image={component?.image}
            imageAlt={`Image for step ${idx}`}
            video={component?.video}
            component={component.hasOwnProperty('render') && component.render({ ...props })}
            className={component.className}
          />
        );
      },
    };
  });

  const handleDotPaginationChange = (nextStep, currentStep) => {
    if (currentStep !== 1 && nextStep > 1) {
      setInstalledGateway(true);
    }
  };

  return (
    <BngStepTutorialDialog
      className={`BimGatewayTutorialDialog`}
      onClose={handleClose}
      steps={steps}
      beforeDotPaginationChange={handleDotPaginationChange}
    />
  );
}
