import React from 'react';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import ThemeSelector from 'components/bng/pages/bigTable/menus/ThemeSelector';
import StyleMenu from 'components/bng/pages/bigTable/menus/StyleMenu';
import BordersMenu from 'components/bng/pages/bigTable/menus/BordersMenu';
import TextsMenu from 'components/bng/pages/bigTable/menus/TextsMenu';

class LayoutTab extends React.Component {
  render() {
    const {
      themeConfig,
      gridConfig,
      titleConfig,
      totalizerConfig,
      datasourceConfig,
      onDatasourceConfigChange,
      onChangeTheme,
      onGridConfigChange,
      onTitleConfigChange,
      onTotalizerConfigChange,
    } = this.props;

    return (
      <AccordionList className="BngBigTable__menuTab ObjectRightMenuAccordion">
        <Accordion
          title={this.props.context.msg.t('table_style')}
          startOpen={true}
          className={'BngBigTable__themeAccordion'}
        >
          <ThemeSelector value={themeConfig} onChange={onChangeTheme} />

          <StyleMenu
            gridConfig={gridConfig}
            onGridConfigChange={onGridConfigChange}
            titleConfig={titleConfig}
            onTitleConfigChange={onTitleConfigChange}
            totalizerConfig={totalizerConfig}
            onTotalizerConfigChange={onTotalizerConfigChange}
            datasourceConfig={datasourceConfig}
            onDatasourceConfigChange={onDatasourceConfigChange}
          />
        </Accordion>

        <Accordion title={this.props.context.msg.t('borders')} startOpen={false}>
          <BordersMenu gridConfig={gridConfig} onGridConfigChange={onGridConfigChange} />
        </Accordion>

        <Accordion title={this.props.context.msg.t('text_style')} startOpen={false}>
          <TextsMenu
            gridConfig={gridConfig}
            onGridConfigChange={onGridConfigChange}
            titleConfig={titleConfig}
            onTitleConfigChange={onTitleConfigChange}
            totalizerConfig={totalizerConfig}
            onTotalizerConfigChange={onTotalizerConfigChange}
          />
        </Accordion>
      </AccordionList>
    );
  }
}

export default LayoutTab;
