import styles from './ColumnsConfiguration.module.css';

import React, { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';

import BngTable from 'components/bng/ui/BngTable';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngInput from 'components/bng/form/BngInput';
import BngDropdown from 'components/bng/ui/BngDropdown';
import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';
import { normalizeFieldDescription } from 'components/bng/pages/admin/structures/bimQuery/BimQueryPage';

function DimensionTypeSelector({ row = {}, onChange = _.noop }) {
  const { t } = useTranslation();

  const dimensionOptions = useMemo(() => {
    return [
      {
        label: t('newInMemoryTypeDimensionRegular'),
        value: 'Regular',
        icon: 'abc',
      },
      {
        label: t('url'),
        value: 'Url',
        icon: 'link',
      },
      {
        label: t('newInMemoryTypeDimensionTime'),
        value: 'TimeDimension',
        icon: 'event',
      },
      {
        label: t('newInMemoryTypeDimensionMetric'),
        value: 'Measure',
        icon: '123',
      },
      {
        label: t('newInMemoryTypeDimensionGeoreference'),
        value: 'Georeference',
        icon: 'location_on',
      },
      {
        label: t('newInMemoryTypeDimensionNull'),
        value: 'Null',
        icon: 'filter_list',
      },
    ];
  }, []);

  const selectedOption = dimensionOptions.find((option) => option.value === row.dimensionType);

  return (
    <BngDropdown
      className={`DimensionTypeSelector ${styles.BngDropdown}`}
      customButton={({ openDropdown }) => (
        <div onClick={openDropdown} className={`${styles.DimensionTypeSelector}`}>
          <div className={`${styles.selectedIconWrapper}`}>
            <Icon className={`${styles.selectedOptionIcon}`} icon={selectedOption.icon} />
            {selectedOption.label}
          </div>
          <Icon icon="arrow_drop_down" />
        </div>
      )}
      popperClassName={`${styles.PopperOptions} `}
      customOptions={({ closeDropdown }) => {
        return dimensionOptions.map((option, idx) => {
          return (
            <div
              className={`${styles.DimensionOption}`}
              onClick={async () => {
                await onChange({
                  dimensionType: option.value,
                  measure: option.value === 'Measure',
                });
                closeDropdown();
              }}
            >
              <Icon className={`${styles.dimensionSelectedOptionIcon}`} icon={option.icon} />
              {option.label}
            </div>
          );
        });
      }}
    />
  );
}

function MeasureMasksSelector({ row, onChange = _.noop }) {
  const { t } = useTranslation();

  const maskOptions = useMemo(() => {
    return [
      {
        label: t('newInMemoryStandard'),
        value: 'Standard',
      },
      {
        label: t('newInMemoryPercent'),
        value: 'Percent',
      },
      {
        label: t('newInMemoryFixed'),
        value: 'Fixed',
      },
      {
        label: t('formatOptions.currency'),
        options: [
          {
            label: t('newInMemoryCurrencyDollar'),
            value: 'CurrencyDollar',
          },
          {
            label: t('newInMemoryCurrency'),
            value: 'Currency',
          },
          {
            label: t('newInMemoryCurrencyEuro'),
            value: 'CurrencyEuro',
          },
        ],
      },
      {
        label: t('newInMemoryHour'),
        value: 'Hour',
      },
      {
        label: t('formatOptions.accounting'),
        options: [
          {
            label: t('FormatString.Accounting'),
            value: 'Accounting',
          },
          {
            label: t('FormatString.AccountingFixed'),
            value: 'AccountingFixed',
          },
          {
            label: t('formatOptions.accountingUsd'),
            value: 'AccountingDollar',
          },
          {
            label: t('formatOptions.accountingBrl'),
            value: 'AccountingReal',
          },
          {
            label: t('formatOptions.accountingEur'),
            value: 'AccountingEuro',
          },
        ],
      },
    ];
  }, []);

  let label = '';
  maskOptions.forEach((option) => {
    if (option.value === row.formatString) {
      label = option.label;
    } else if (option.hasOwnProperty('options')) {
      option.options.forEach((o) => {
        if (o.value === row.formatString) {
          label = o.label;
        }
      });
    }
  });

  return (
    <BngDropdown
      className={`MeasureMasksSelector ${styles.BngDropdown} ${!row.measure && 'disabled'}`}
      customButton={({ openDropdown }) => (
        <div
          onClick={(event) => {
            if (row.measure) {
              openDropdown(event);
            }
          }}
          className={`${styles.DimensionTypeSelector}`}
        >
          <div className={`${styles.selectedIcon}`}>{label}</div>
          <Icon icon="arrow_drop_down" />
        </div>
      )}
      popperClassName={`${styles.PopperOptions} `}
      customOptions={({ closeDropdown }) => {
        return maskOptions.map((option, idx) => {
          if (option.hasOwnProperty('options')) {
            return (
              <BngDropdown
                className={`${styles.MaskOptionDropdown} `}
                customButton={({ openDropdown }) => (
                  <div
                    onClick={openDropdown}
                    className={`${styles.DimensionTypeSelector} ${styles.MaskOptionDropdownOption}`}
                  >
                    <div>{option.label}</div>
                    <Icon icon="arrow_drop_down" />
                  </div>
                )}
                popperOpts={{ placement: 'right' }}
                popperClassName={`${styles.PopperOptions} `}
                customOptions={({ closeDropdown }) => {
                  return option.options.map((o) => {
                    return (
                      <div
                        className={`${styles.DimensionOption}`}
                        onClick={async () => {
                          await onChange({ formatString: o.value });
                          closeDropdown();
                        }}
                      >
                        {o.label}
                      </div>
                    );
                  });
                }}
              />
            );
          }
          return (
            <div
              className={`${styles.DimensionOption}`}
              onClick={async () => {
                await onChange({ formatString: option.value });
                closeDropdown();
              }}
            >
              {option.label}
            </div>
          );
        });
      }}
    />
  );
}

function OperatorSelector({ row, onChange = _.noop }) {
  const { t } = useTranslation();

  const operationOptions = useMemo(() => {
    return [
      {
        label: t('sum'),
        value: 'SUM',
        icon: 'functions',
      },
      {
        label: t('count'),
        value: 'COUNT',
        icon: 'money',
      },
      {
        label: t('min'),
        value: 'MIN',
        icon: 'arrow_downward',
      },
      {
        label: t('max'),
        value: 'MAX',
        icon: 'arrow_upward',
      },
      {
        label: t('avg'),
        value: 'AVG',
        icon: 'sync_alt',
      },
      {
        label: t('distinct'),
        value: 'DISTINCT_COUNT',
        icon: 'pin',
      },
    ];
  }, []);
  const operator = operationOptions.find((operation) => operation.value === row.measureAggregator);

  return (
    <BngDropdown
      className={`OperatorSelector ${styles.BngDropdown} ${!row.measure && 'disabled'}`}
      customButton={({ openDropdown }) => (
        <div
          onClick={(event) => {
            if (row.measure) {
              openDropdown(event);
            }
          }}
          className={`${styles.DimensionTypeSelector}`}
        >
          <div className={`${styles.selectedIconWrapper}`}>
            <Icon icon={operator.icon} />
            {operator.label}
          </div>
          <Icon icon="arrow_drop_down" />
        </div>
      )}
      popperClassName={`${styles.PopperOptions} `}
      customOptions={({ closeDropdown }) => {
        return operationOptions.map((option, idx) => {
          return (
            <div
              className={`${styles.DimensionOption}`}
              onClick={async () => {
                await onChange({
                  measureAggregator: option.value,
                });
                closeDropdown();
              }}
            >
              <Icon className={`${styles.dimensionSelectedOptionIcon}`} icon={option.icon} />
              {option.label}
            </div>
          );
        });
      }}
    />
  );
}

function AdvancedOptions({ row, onChange = _.noop }) {
  const { t } = useTranslation();

  const advancedOption = useMemo(() => {
    return [
      {
        label: t('all'),
        value: 'hasAll',
      },
      {
        label: t('visible'),
        value: 'visible',
      },
      {
        label: t('index'),
        value: 'indexed',
      },
      {
        label: t('rename.field'),
        value: 'renamed',
        icon: 'edit_note',
      },
    ];
  }, []);

  return (
    <BngDropdown
      popperClassName={`AdvancedOptions ${styles.PopperOptions} `}
      customOptions={({ closeDropdown }) => {
        return advancedOption.map((option, idx) => {
          const notRenamed = option.value !== 'renamed';
          return (
            <div
              className={`AdvancedOptionsPopper ${styles.DimensionOption}`}
              onClick={async () => {
                await onChange({
                  [option.value]: !row[option.value],
                });
                if (!notRenamed) {
                  closeDropdown();
                }
              }}
            >
              {option.label}

              {notRenamed ? (
                <BngCheckbox
                  className={`AdvancedOptionCheckbox ${styles.Checkbox}`}
                  field={{ value: row[option.value] }}
                />
              ) : (
                <Icon icon={option.icon} className={`${styles.RenameFieldIcon}`} />
              )}
            </div>
          );
        });
      }}
    />
  );
}

const fieldNameValidator = (fieldName) => {
  if (fieldName === '' || fieldName.length > 60) {
    return false;
  }

  return (
    normalizeFieldDescription(fieldName)
      .replaceAll(/[_\-\s]/g, '')
      .match(/\W/g) === null
  );
};

export default function ColumnsConfiguration() {
  const { t } = useTranslation();
  const {
    values: { fieldConfigs = [], structureName = '' },
    setFieldValue,
  } = useFormikContext();

  const columns = useMemo(() => {
    const updateFieldValues = (row, values = {}) => {
      const copy = fieldConfigs.slice();
      const idx = copy.findIndex((field) => field.fieldName === row.fieldName);
      copy[idx] = {
        ...copy[idx],
        ...values,
      };
      setFieldValue('fieldConfigs', copy);
    };

    return [
      {
        render: (row) => (
          <BngCheckbox
            field={{
              onChange: () => updateFieldValues(row, { selected: !row.selected }),
              value: row.selected,
            }}
            size="lg"
          />
        ),
        headerClassName: `${styles.BngTableHeaderClassName}`,
      },
      {
        label: t('origin'),
        render: (row) => {
          if (row.renamed) {
            return (
              <div className={`${styles.RenameField}`}>
                <Field
                  className={`${styles.RenameFieldInput}`}
                  component={BngInput}
                  value={row.fieldName}
                  onChange={(event) => updateFieldValues(row, { fieldName: event.target.value })}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      updateFieldValues(row, { renamed: false });
                    }
                  }}
                />
                <Icon
                  icon={'check'}
                  onClick={() => updateFieldValues(row, { renamed: false })}
                  className={`${styles.ConfirmRenameIcon}`}
                />
              </div>
            );
          }

          const maxFieldDescriptionSize = 24;
          const isValid = fieldNameValidator(row.fieldName);
          const exceedFieldSize = row.description.length > maxFieldDescriptionSize;
          let icon = '';
          let titleMessage = '';
          if (!isValid && row.selected && !exceedFieldSize) {
            icon = 'close';
            titleMessage = t('inMemory_invalidFieldUnselected');
          } else if ((!isValid && !row.selected && !exceedFieldSize) || exceedFieldSize) {
            icon = 'priority_high';
            titleMessage = t('in.memory.field.name.size.limit.error', [maxFieldDescriptionSize]);
          }
          return (
            <div className={`${styles.OriginFieldName}`}>
              {icon && <Icon title={titleMessage} icon={icon} className={`${styles.fieldValidationIcon} ${icon}`} />}
              {row.fieldName}
            </div>
          );
        },
        headerClassName: `${styles.BngTableHeaderClassName}`,
      },
      {
        label: t('newInMemoryVisualizationField'),
        render: (row) => {
          return (
            <Field
              className={`${styles.VisualizationName}`}
              component={BngInput}
              value={row.caption}
              onChange={(event) => updateFieldValues(row, { caption: event.target.value })}
            />
          );
        },
        headerClassName: `${styles.BngTableHeaderClassName}`,
      },
      {
        label: t('newInMemoryDimensionType'),
        render: (row) => (
          <DimensionTypeSelector
            row={row}
            fieldConfigs={fieldConfigs}
            onChange={(value) => updateFieldValues(row, value)}
          />
        ),
        headerClassName: `${styles.BngTableHeaderClassName}`,
      },
      {
        label: t('newInMemoryMeasureMasks'),
        render: (row) => <MeasureMasksSelector row={row} onChange={(value) => updateFieldValues(row, value)} />,
        headerClassName: `${styles.BngTableHeaderClassName}`,
      },
      {
        label: t('newInMemoryMeasureCount'),
        render: (row) => <OperatorSelector row={row} onChange={(value) => updateFieldValues(row, value)} />,
        headerClassName: `${styles.BngTableHeaderClassName}`,
      },
      {
        render: (row) => <AdvancedOptions row={row} onChange={(value) => updateFieldValues(row, value)} />,
        headerClassName: `${styles.BngTableHeaderClassName}`,
      },
    ];
  }, [fieldConfigs, structureName]);

  return <BngTable className={`${styles.columnConfigurationTable}`} rows={fieldConfigs} cols={columns} />;
}
