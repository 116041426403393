import styles from './BngObjectItemSelect.module.css';

import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import BngItemSelect from 'components/bng/form/itemSelect/BngItemSelect';
import BngObjectCockpitSelection from 'components/bng/exportScheduling/BngObjectCockpitSelection';
import Utils from 'components/Utils';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import BngDropdown from 'components/bng/ui/BngDropdown';
import bngYup from 'components/bng/form/yup/BngYup';
import BngForm from 'components/bng/form/BngForm';
import BngTag from 'components/bng/ui/BngTag';
import useDraggableInPortal from 'components/hooks/useDraggableInPortal';

const OrderDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    contents: yup.array().default([]),
  });
});

function HeaderButton({ form, field }) {
  const context = useBimContext();
  const renderDraggable = useDraggableInPortal();

  const onDragEnd = (result, setFieldValue, values) => {
    if (!result.destination) return;

    const fromIdx = result.source.index;
    const toIdx = result.destination.index;

    if (fromIdx === toIdx) return;

    const orderArr = Array.from(values.contents);
    const [removed] = orderArr.splice(fromIdx, 1);
    orderArr.splice(toIdx, 0, removed);

    setFieldValue('contents', orderArr);
  };

  const initialFormValues = useMemo(() => {
    return { contents: _.cloneDeep(field.value.contents) };
  }, [field.value.contents]);

  return (
    <BngDropdown
      title={context.msg.t('Share.objects.changeOrder.label')}
      icon={'swap_vert'}
      popperClassName={`${styles.shareObjectsDropdownPopper}`}
      overDialog={true}
      customOptions={({ closeDropdown }) => {
        return (
          <Formik
            initialValues={initialFormValues}
            validationSchema={OrderDropdownSchema}
            onSubmit={(values) => {
              form.setFieldValue(field.name, Object.assign({}, field.value, { contents: values.contents }));
              closeDropdown();
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <BngForm>
                  <div className={`${styles.shareObjectsDropdownHeader}`}>
                    <span className={`${styles.shareObjectsDropdownHeaderTitle}`}>{context.msg.t('objects')}</span>
                  </div>
                  <hr style={{ margin: 0 }} />
                  <div className={`${styles.shareObjectsDropdownDraggableContainer}`}>
                    <DragDropContext onDragEnd={(result) => onDragEnd(result, setFieldValue, values)}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => {
                          return (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {values.contents.map((item, idx) => {
                                return (
                                  <Draggable key={idx} draggableId={item.path} index={idx}>
                                    {renderDraggable((provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`DraggableItem ${styles.draggableItem}`}
                                      >
                                        <ObjectTag
                                          form={form}
                                          field={field}
                                          item={item}
                                          isCockpit={false}
                                          draggable={true}
                                        />
                                      </div>
                                    ))}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </DragDropContext>
                  </div>
                  <hr style={{ margin: 0 }} />
                  <div className={`${styles.shareObjectsDropdownButtonsContainer}`}>
                    <BngButton
                      onClick={() => {
                        closeDropdown();
                        setFieldValue('contents', _.cloneDeep(field.value.contents));
                      }}
                      variant={Variant.textButton}
                    >
                      {context.msg.t('cancel')}
                    </BngButton>
                    <BngButton className={`${styles.OrderDropdownConfirmButton}`} btnType={'submit'}>
                      {context.msg.t('save_button')}
                    </BngButton>
                  </div>
                </BngForm>
              );
            }}
          </Formik>
        );
      }}
    />
  );
}

function ObjectTagComponent({ form, field, contents = [], isCockpit, readOnly = false }) {
  return (
    <React.Fragment>
      {contents.map((item, idx) => {
        return (
          <ObjectTag
            form={form}
            field={field}
            item={item}
            isCockpit={isCockpit}
            key={`${idx}_${item.text ?? item.name}`}
            readOnly={readOnly}
          />
        );
      })}
    </React.Fragment>
  );
}

function ObjectTag({ form, field, item, isCockpit, draggable = false, readOnly = false }) {
  const context = useBimContext();

  const remove = () => {
    const contents = _.cloneDeep(field.value.contents).filter((i) => i.path !== item.path);
    form.setFieldValue(field.name, Object.assign({}, field.value, { contents: contents }));
  };

  const objectType = isCockpit ? 'cockpit' : Utils.Object.getObjectType(item.path);
  const itemName = `${context.msg.t(objectType)}: ${item.name}`;

  return (
    <BngTag
      className={`${draggable ? styles.objectTagWrapperFullSize : ''}`}
      icon={Utils.Object.getObjectIcon(item.path, Utils.Object.isAnalysis(item.path) ? 'showChart' : '')}
      description={itemName}
      onClose={!readOnly && !isCockpit && !draggable ? () => remove(item) : undefined}
      textEllipsis={true}
    >
      {draggable && <Icon icon={'drag_indicator'} className={`${styles.objectIcon}`} />}
    </BngTag>
  );
}

export default function BngObjectItemSelect({ form, field, readOnly = false, ...props }) {
  const { msg } = useBimContext();
  const [open, setOpen] = useState(false);

  const isCockpit = field.value?.type === Utils.Scheduling.TYPE.COCKPIT;
  const contents = isCockpit ? [{ ...field.value.cockpit }] : field.value?.contents ?? [];

  return (
    <BngItemSelect
      buttonLabel={msg.t(`BngItemSelect.label.objects`)}
      openComponent={
        open
          ? () => (
              <BngObjectCockpitSelection
                form={form}
                field={field}
                open={open}
                onClose={() => setOpen(false)}
                {...props}
              />
            )
          : undefined
      }
      onOpen={() => setOpen(true)}
      tagComponent={() => <ObjectTagComponent contents={contents} form={form} field={field} isCockpit={isCockpit} readOnly={readOnly} />}
      value={contents}
      headerButton={!isCockpit ? () => <HeaderButton form={form} field={field} /> : null}
      showTags={contents.length >= 1}
      readOnly={readOnly}
    />
  );
}
