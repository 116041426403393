import './ProjectMergeDialog.css';

import React from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Field, Formik } from 'formik';
import { connect } from 'react-redux';

import Api from 'components/Api';
import ContextEnhancer from 'components/ContextEnhancer';
import Dialog from 'components/ui/Dialog';
import { optionsFromRoots } from 'components/ui/common/SaveAsDialog';
import { Validations } from 'components/ui/FormUtils';
import UiMsg from 'components/ui/UiMsg';
import { calculateAccountConsumption } from 'components/ui/accounts/tabs/AccFinanceTab';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import BngField from 'components/bng/form/BngField';
import BngForm from 'components/bng/form/BngForm';
import bngYup from 'components/bng/form/yup/BngYup';
import Button from 'components/ui/Button';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngTreeDropdown from 'components/bng/form/BngTreeDropdown';
import { metricsLimitsExceeded } from 'components/bng/pages/project/management/ProjectManagement';

const conflictsOrder = {
  connections: { validators: [Validations.maxLength(100)] },
  origins: { validators: [Validations.inMemoryName] },
  cockpits: { validators: [Validations.maxLength(30)] },
};

const ProjectMergeDialogSchema = bngYup((yup) => {
  return yup.object({
    project: yup.number().required(),
    parentFolder: yup.string(),
    folder: yup.string(),
    importObjects: yup.boolean().default(true),
    importData: yup.boolean().default(true),
    connections: yup.object().default({}),
    origins: yup.object().default({}),
    cockpits: yup.object().default({}),
  });
});

class ProjectMergeDialog extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func,
    onMaximize: PropTypes.func,
  };

  static defaultProps = {
    formValues: {},
  };

  state = {
    projects: [],
    availableFolders: [],
    message: 'none',
    loading: true,
    initialValues: ProjectMergeDialogSchema.default(),
  };

  validateFolderName = (value) => {
    return Validations.folderName(value) === undefined;
  };

  async componentDidMount() {
    const { projectId } = this.props;
    try {
      const allProjects = await Api.Navbar.projectsMenuDetails();
      const roots = await Api.Menu.findRootsFor({ projectId });
      const projectData = await Api.ProjectApp.findInstalls(projectId);

      const projects = allProjects.filter((p) => p.id !== projectId && (p.canImport || p.isMaster));

      projectData.installs.forEach((projectApp) => {
        const match = projects.find((p) => p.id === projectApp.app.id);
        if (match) {
          match.disabled = true;
          match.title = this.props.context.msg.t('app.already.installed', projectApp.install.path);
        }
      });

      projectData.installedOn.forEach((projectApp) => {
        const match = projects.find((p) => p.id === projectApp.app.id);
        if (match) {
          match.disabled = true;
          match.title = this.props.context.msg.t('project.already.installed.as.app', projectApp.install.path);
        }
      });

      const availableFolders = optionsFromRoots(roots);
      const currentProject = allProjects.find((p) => p.id === projectId);
      availableFolders.unshift({ value: `/${currentProject.name}`, label: '/', icon: '', depth: 0 });

      this.setState({
        currentProject,
        projects,
        availableFolders,
      });
    } catch (e) {
      UiMsg.ajaxError('', e);
    } finally {
      this.setState({ loading: false });
    }
  }

  checkForConflicts = async (params, setFieldValue) => {
    let gotConflict = false;
    if (!this.state.conflicts) {
      const conflictData = await Api.ProjectMerge.checkForConflicts(params);
      Object.entries(conflictData).forEach(([k, val]) => {
        val.conflicts.forEach((name) => {
          let newName = name + 'Import';
          while (val.inUse.indexOf(newName) !== -1) {
            newName += 'Import';
          }
          setFieldValue(`${k}.${name}`, newName);
          gotConflict = true;
        });
      });
      if (gotConflict) {
        this.setState({ conflicts: conflictData });
      }
    }
    return gotConflict;
  };

  validateConflictResolution = (data) => {
    let msg = '';
    Object.keys(conflictsOrder).forEach((key) => {
      let innerMsg = '';
      const vals = data[key];
      const repeatCheck = vals ? _.countBy(Object.values(vals)) : {};
      Object.entries(repeatCheck).forEach(([k, v]) => {
        if (v > 1) {
          if (innerMsg.length === 0) {
            innerMsg += `<b>${this.props.context.msg.t(key)}</b><ul>`;
          }
          innerMsg += `<li>${this.props.context.msg.t('merge.name.in.use', [v, k])}</li>`;
        }
      });
      if (innerMsg.length !== 0) {
        innerMsg += '</ul>';
        msg += innerMsg;
      }
    });
    return msg;
  };

  folderNameValidation = (folderName, formValues) => {
    return (
      Validations.folderName(folderName) ||
      Validations.notIn({
        values: this.state.availableFolders.map((f) => f.value),
        valueTransformer: (val) => formValues.parentFolder + '/' + val,
      })(folderName)
    );
  };

  conflictFieldValidation = (key, value, allValues, val) => {
    return (
      Validations.notIn({
        values: [...val.inUse, ...val.conflicts],
        valueTransformer: (val) => `${key}.${val}`,
      })(value) || Validations.required(value)
    );
  };

  save = async (data, { setFieldValue }) => {
    data.folder = (data.folder || '')?.trim();
    const params = {
      importObjects: data.importObjects,
      importData: data.importData,
      projectFrom: data.project,
      projectTo: this.props.projectId,
      parentFolder: data.parentFolder,
      folder: data.folder,
    };

    const { currentProject, projects } = this.state;
    const folderValidationError = this.folderNameValidation(data.folder, data);
    if (folderValidationError) {
      UiMsg.warn(folderValidationError);
      return;
    }

    const conflictErrors = this.state?.conflicts
      ? Object.entries(this.state?.conflicts).reduce((errors, [key, val]) => {
          val.conflicts.forEach((e) => {
            const fieldError = this.conflictFieldValidation(key, data[key] ? data[key][e] : '', data, val);
            if (fieldError) {
              errors[`${key}.${e}`] = fieldError;
            }
          });
          return errors;
        }, {})
      : [];

    if (Object.keys(conflictErrors).length > 0) {
      Object.entries(conflictErrors).forEach(([field, error]) => {
        UiMsg.warn(error);
      });
      return;
    }

    if (this.validateFolderName(data.folder) || !data.importObjects) {
      this.setState({ message: 'none' });
      try {
        const gotConflict = await this.checkForConflicts(params, setFieldValue);
        if (gotConflict) return;

        const msg = this.validateConflictResolution(data);
        if (msg.length > 0) {
          UiMsg.warn('', msg);
          return;
        }

        if (
          this.props.context.accountEnabledForBilling &&
          currentProject.projectType.type === 'Production' &&
          params.importData
        ) {
          const project = projects.find((p) => p.id === params.projectFrom);
          const currentAccountInfo = await Api.Account.fetchBillingAccountInfo(currentProject.accountId);
          const importedProjectAccountInfo = await Api.Account.fetchBillingAccountInfo(project.accountId);
          const metrics = calculateAccountConsumption(currentAccountInfo, project, importedProjectAccountInfo);
          if (metrics.limits.structuresExceeds > 0) {
            await metricsLimitsExceeded({
              context: this.props.context,
              dispatch: this.props.dispatch,
              metrics,
              shouldCheckUsers: false,
              msg: 'import.project.metrics.limit.error',
            });
            return;
          }
        }

        await Api.ProjectMerge.runMerge({
          ...params,
          origins: data.origins,
          cockpits: data.cockpits,
          connections: data.connections,
        });

        UiMsg.ok(this.props.context.msg.t('project.merge.success'));
        this.props.context.execute('#{projectSelectionMB.reloadCurrent()}');
        this.props.closeModal();
      } catch (ex) {
        const e = ex.response;
        if (e && e.status) {
          switch (e.status) {
            case 403:
              UiMsg.warn(this.props.context.msg.t('project.merge.permission.denied.import'));
              break;
            case 409:
              UiMsg.error(this.props.context.msg.t('project.merge.error'), e.data);
              break;
            default:
              UiMsg.error(this.props.context.msg.t('project.merge.error'), e.data.message);
              break;
          }
          return;
        }
        UiMsg.ajaxError(this.props.context.msg.t('project.merge.error'), e || ex);
      }
    } else {
      this.setState({ message: 'inline' });
    }
  };

  canSave(values) {
    return values.importData || values.importObjects;
  }

  render() {
    const { msg } = this.props.context;
    const { projects, availableFolders, conflicts } = this.state;

    return (
      <Dialog
        open={this.props.open}
        className="large ProjectMergeDialog"
        title={msg.t('import.project')}
        onClose={this.props.closeModal}
      >
        <BlockUi tag="div" blocking={this.props.submitting || this.state.loading}>
          <Formik
            initialValues={this.state.initialValues}
            validationSchema={ProjectMergeDialogSchema}
            onSubmit={this.save}
          >
            {({ values, isSubmitting }) => {
              return (
                <BngForm>
                  <div className="row-fluid">
                    <div className="span6">
                      <Field
                        className="fill-w"
                        label={msg.t('project')}
                        name="project"
                        component={BngField}
                        inputComponent={BngSelectSearch}
                        options={projects.map((project) => ({
                          value: project.id,
                          label: `${project.id} - ${project.name}`,
                          disabled: project.disabled,
                          title: project.title,
                          project,
                        }))}
                        required={true}
                      />
                    </div>
                    {values.importObjects && (
                      <div className="span6">
                        <Field
                          className="fill-w"
                          label={msg.t('folder')}
                          name="parentFolder"
                          component={BngField}
                          inputComponent={BngTreeDropdown}
                          dataTree={availableFolders}
                          required={values.importObjects}
                          selectFolders
                        />
                      </div>
                    )}
                  </div>
                  {values.importObjects && (
                    <div className="row-fluid">
                      <div className="span12">
                        <Field
                          className="fill-w"
                          label={msg.t('new.folder.name')}
                          name="folder"
                          component={BngField}
                          required={values.importObjects}
                        />
                        <span
                          style={{
                            display: this.state.message,
                            color: '#700',
                          }}
                        >
                          {msg.t('folder.name.only.alphanumeric')}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="row-fluid">
                    <div className="span12">
                      <Field label={msg.t('import.analytical.objects')} name="importObjects" component={BngCheckbox} />
                    </div>
                  </div>

                  <div className="row-fluid">
                    <div className="span12">
                      <Field label={msg.t('import.data.structures')} name="importData" component={BngCheckbox} />
                    </div>
                  </div>
                  {conflicts && (
                    <div>
                      <fieldset>
                        <legend>{msg.t('conflicts')}</legend>
                      </fieldset>
                      <div className="row-fluid">
                        {Object.entries(conflictsOrder).map(([key, additional], idx) => {
                          const val = conflicts[key];
                          if (!_.isObject(val)) return null;
                          return (
                            <div key={idx} className="span4 conflict-box">
                              <h5 className="conflict-title">{this.props.context.msg.t(key)}</h5>
                              <div className="conflict-body scrollbar-outer">
                                {val.conflicts.map((e, idx) => {
                                  return (
                                    <div key={idx}>
                                      <Field
                                        className="fill-w"
                                        label={e}
                                        name={`${key}.${e}`}
                                        value={values[key] ? values[key][e] : ''}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="row-fluid">
                    <div className="span12 text-right btn-fix">
                      <Button onClick={this.props.closeModal} className="bng-button cancel" disabled={isSubmitting}>
                        {msg.t('cancel')}
                      </Button>{' '}
                      <Button
                        type="submit"
                        className="bng-button save"
                        disabled={!this.canSave(values)}
                        loading={isSubmitting}
                      >
                        {msg.t('import')}
                      </Button>
                    </div>
                  </div>
                </BngForm>
              );
            }}
          </Formik>
        </BlockUi>
      </Dialog>
    );
  }
}

export default connect()(ContextEnhancer(ProjectMergeDialog));
