import './BngCodeMirror.css'
import React from 'react';
import PropTypes from 'prop-types';


const optsToUpdate = [{opt: 'mode'}, {opt: 'readOnly', alias: 'disabled'}, {opt: 'lineNumbers'}];

export class BngCodeMirror extends React.PureComponent {

    static propTypes = {
        className: PropTypes.string,
        mode: PropTypes.string,
        lineNumbers: PropTypes.bool,
        variables: PropTypes.array,
        disabled: PropTypes.bool,
        height: PropTypes.any,
        maxLength: PropTypes.number,
    };

    static defaultProps = {
        className: '',
        mode: 'text/javascript',
        lineNumbers: true,
        disabled: false,
        variables: [],
        height: null,
        maxLength: null,
    };

    componentDidMount() {
        this.codeMirror = new CodeMirror(this._container, {
            value: this.props.field.value || '',
            mode: this.props.mode,
            readOnly: this.props.disabled,
            lineNumbers: this.props.lineNumbers,
        });
        if(this.props.height) {
            this.codeMirror.setSize(null, this.props.height);
        }

        this.codeMirror.on('change', (cm) => {
            const newValue = this.truncateValue(cm);
            this.props.form.setFieldValue(this.props.field.name, newValue);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        optsToUpdate.forEach(({opt, alias}) => {
            let option = alias || opt;
            let prevValue = prevProps[option];
            let currentValue = this.props[option];
            if (prevValue !== currentValue) {
                this.codeMirror.setOption(opt, currentValue);
            }
        });

        if (prevProps.maxLength !== this.props.maxLength) {
            this.truncateValue(this.codeMirror);
        }

        if (this.props.field.value !== this.codeMirror.getValue()) {
            this.codeMirror.setValue(this.props.field.value || '');
        }
    }

    truncateValue(codeMirror) {
        if (!codeMirror) {
            return '';
        }

        let value = codeMirror.getValue() || '';
        if (this.props.maxLength && value.length > this.props.maxLength) {
            value = value.substring(0, this.props.maxLength);
            codeMirror.setValue(value);
            codeMirror.setCursor(value.length);
        }

        return value;
    }

    render() {
        return (
            <div className={`BngCodeMirror ${this.props.className} ${this.props.disabled ? 'disabled' : ''}`}>
                <div className={`BngCodeMirrorEditor`}
                     ref={ref => this._container = ref}
                />
                <div className={`BngCodeMirrorVars`}>
                    {this.props.variables.map(v => {
                        return (
                            <div key={v.value}
                                 className="BngCodeMirrorVar"
                                 onClick={this.props.disabled ? _.noop : () => this.insertVariable(v.value)}>
                                {v.label || v.value}
                            </div>
                        );
                    })}
                </div>
                {this.props.disabled &&
                <div className="DisabledOverlay"/>
                }
            </div>
        )
    }

    insertVariable = (value) => {
        const doc = this.codeMirror.doc;
        doc.replaceRange(value, doc.getCursor());
    }
}