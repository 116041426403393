import './Navbar.css';

import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';

import Api from 'components/Api';
import AdminMenuButton from 'components/ui/navbar/AdminMenuButton';
import SupportMenuButton from 'components/ui/navbar/SupportMenuButton';
import CreateObjectMenuButton from 'components/ui/navbar/CreateObjectMenuButton';
import UserMenuButton from 'components/ui/navbar/UserMenuButton';
import ProjectMenuButtonContainer from 'components/ui/navbar/ProjectMenuButtonContainer';
import PublisherMenuButtonContainer from 'components/ui/navbar/PublisherMenuButtonContainer';
import PresentationMenuButtonContainer from 'components/ui/navbar/PresentationMenuButtonContainer';
import CentralMonitoringMenuButtonContainer from 'components/ui/navbar/CentralMonitoringMenuButtonContainer';
import NotificationMenuButtonContainer from 'components/ui/navbar/NotificationMenuButtonContainer';
import ObjectSearchContainer from 'components/ui/navbar/ObjectSearchContainer';
import FloatingNotificationAlert from 'components/ui/navbar/FloatingNotificationAlert';
import ManageEnvironment from './ManageEnvironment';
import SpecialistDropdown from 'components/ui/navbar/SpecialistDropdown';
import EvaluationPeriodDropdown from 'components/ui/navbar/EvaluationPeriodDropdown';
import NavRoundButton from 'components/ui/navbar/NavRoundButton';
import Utils from 'components/Utils';
import { MODALS } from 'components/ui/redux/Actions';
import ActivateBimDialog from 'components/ui/navbar/ActivateBimDialog';
import AddonActivateDialog from 'components/bng/accounts/addons/AddonActivateDialog';
import AddonNavButton from 'components/ui/navbar/addons/AddonNavButton';
import ExportMenuButton from 'components/ui/navbar/ExportMenuButton';
import { VIEW } from 'bng/routes';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import useBimContext from 'components/hooks/useBimContext';
import useQueryParams from 'components/hooks/useQueryParams';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngIconButton from 'components/bng/ui/BngIconButton';
import { openBimAcademy } from 'components/ui/university/BimUniversityPage';

const ACTIVATE_BIMACHINE_DIALOG_QUERY_PARAM = 'activateBIMachineDialog';
const ACTIVATE_ADDON_DIALOG_QUERY_PARAM = 'activateAddonDialog';

export default function Navbar({
  info = {
    counts: {
      notifications: 0,
    },
  },
  view,
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const { queryParams } = useQueryParams();

  const _navRightButtonsRed = useRef();

  const [searchExpanded, setSearchExpanded] = useState(false);
  const [rightButtonsExpanded, setRightButtonsExpanded] = useState(false);

  const rightButtonStyles = useSpring({
    opacity: rightButtonsExpanded ? 1 : 0,
    transform: rightButtonsExpanded
      ? `translateX(-${_navRightButtonsRed.current?.clientWidth}px)`
      : `translateX(calc(${window.innerWidth}px))`,
  });
  const openBimStore = async () => {
    await Api.Cockpit.accessStore();
  };

  const accessCockpit = async () => {
    await Api.Cockpit.accessCockpit();
  };

  const accessProject = async () => {
    await Api.Cockpit.accessDiscover();
  };

  const openMarketplace = async () => {
    return await Api.Cockpit.accessMarketplace(context.project.name);
  };

  const accessProjectFromManage = () => {
    j('.access-box-button').click();
  };

  useEffect(() => {
    if (queryParams.has(ACTIVATE_BIMACHINE_DIALOG_QUERY_PARAM) && Utils.Project.isDemo(context.project)) {
      dispatch(MODALS.open(ActivateBimDialog));
    } else if (queryParams.has(ACTIVATE_ADDON_DIALOG_QUERY_PARAM)) {
      dispatch(MODALS.open(AddonActivateDialog));
    }
  }, []);

  const toggleSearchBar = () => setSearchExpanded(!searchExpanded);

  const buildRoundButton = () => {
    const isDemo = Utils.Project.isDemo(context.project);

    if (isDemo) {
      const activeMessage = context.msg.t(context.projectActivated ? 'project.activated' : 'activate.now.link');
      return {
        DropdownComponent: EvaluationPeriodDropdown,
        label: context.msg.t('evaluation.days.left', [
          context.demoExpirationDate > 0 ? context.demoExpirationDate : '0',
          activeMessage,
        ]),
        isNovelty: false,
      };
    } else if (context.showHireSpecialist && context.isAccessingProject) {
      return {
        DropdownComponent: SpecialistDropdown,
        label: context.msg.t('need.specialist'),
        isNovelty: true,
      };
    }

    return null;
  };

  let logoUrl = `${context.contextPath}/resources/images/logo-new.png`;

  if (checkAddonEnabled('VISUAL_IDENTITY') && context.theme?.toolbarLogo) {
    logoUrl = `${context.contextPath}/${context.theme.toolbarLogo}`;
  }

  const isExternalPage = Utils.ExternalPages.isExternalPage();

  const perm = context.permissions;
  const isCockpit = view ? view === VIEW.Cockpit : context.cockpitEnabled && !isExternalPage;
  const isDiscover = view ? view === VIEW.Discover : context.cockpitEnabled === false && !isExternalPage;
  const isStore = window.location.pathname.includes('/spr/bng/store');
  const isMarketplace = window.location.pathname.includes('/bng/marketplace');
  const isAcademy = window.location.pathname.includes('/bng/university');

  const roundButtonProps = buildRoundButton();
  const showMarketplace =
    context.accountEnabledForBilling &&
    context.bimResources.marketplace &&
    (context.support.accountConfig.showBimStore || context.canManageAccounts);
  const showBimStore =
    context.isAccessingProject &&
    'store' in context &&
    !context.userIsOnlyCockpit &&
    !showMarketplace &&
    context.store.currentProjectAccount.storeConfig.enabled &&
    context.support.accountConfig.showBimStore;
  const showBimAcademy = context.isAccessingProject && context.showBimAcademy;

  const hideLogo =
    rightButtonsExpanded && (_navRightButtonsRed.current?.clientWidth || 500) + 115 + 170 > window.innerWidth;

  const showDiscoverButtons =
    (view && view === VIEW.Discover) || (context.isAccessingProject && !context.cockpitEnabled);
  return (
    <div className={`navbar no-print`} style={{ marginBottom: 'auto' }}>
      <div className="navbar-inner">
        <div className="container-fluid" style={{ height: '0px' }}>
          {context.isAccessingProject ? (
            <a
              href={
                context.cockpitEnabled || window.location.href.includes('/store')
                  ? window.location.href
                  : `${Api.baseUrl()}/spr/bng/personal-page`
              }
              className={`brand ${hideLogo ? 'hide-logo' : ''}`}
              id="logo-project"
            >
              <img src={logoUrl} className="navbarLogo" alt="logo" />
            </a>
          ) : (
            <div className={`brand ${hideLogo ? 'hide-logo' : ''}`}>
              <img
                src={logoUrl}
                className="navbarLogo"
                alt="logo"
                onClick={accessProjectFromManage}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}

          <div
            className={`type-view-buttons ${searchExpanded ? 'hidden-top' : ''} ${
              rightButtonsExpanded ? 'hide-on-small' : ''
            }`}
          >
            {context.isAccessingProject && context.showCockpitToggler && (
              <div onClick={isCockpit ? undefined : accessCockpit}>
                <div className={`type-view-button type-view-button-cockpit ${isCockpit ? 'active disabled' : 'hover'}`}>
                  cockpit
                </div>
                <BngIconButton
                  className={`type-view-icon-button ${isCockpit ? 'active disabled' : ''}`}
                  icon={'web_asset'}
                  title={'Cockpit'}
                />
              </div>
            )}
            {context.isAccessingProject && !context.userIsOnlyCockpit && (
              <div onClick={isDiscover ? undefined : accessProject}>
                <div
                  id="discover-button-enable"
                  className={`type-view-button type-view-button-discover ${isDiscover ? 'active disabled' : 'hover'}`}
                >
                  discover
                </div>
                <BngIconButton
                  className={`type-view-icon-button ${isDiscover ? 'active disabled' : 'hover'}`}
                  icon={'insert_chart'}
                  title={'Discover'}
                />
              </div>
            )}
            {showBimStore && (
              <div onClick={isStore ? undefined : openBimStore}>
                <div className={`type-view-button type-view-button-store ${isStore ? 'active disabled' : 'hover'}`}>
                  store
                </div>
                <BngIconButton
                  className={`type-view-icon-button ${isStore ? 'active disabled' : ''}`}
                  icon={'storefront'}
                  title={'Store'}
                />
              </div>
            )}
            {context.isAccessingProject && showMarketplace && (
              <div onClick={isMarketplace ? undefined : openMarketplace}>
                <div
                  className={`type-view-button type-view-button-marketplace ${
                    isMarketplace ? 'active disabled' : 'hover'
                  }`}
                >
                  marketplace
                </div>
                <BngIconButton
                  className={`type-view-icon-button ${isMarketplace ? 'active disabled' : ''}`}
                  icon={'storefront'}
                  title={'Marketplace'}
                />
              </div>
            )}

            {context.isAccessingProject && (
              <div onClick={() => openBimAcademy({ context })}>
                <div className={`type-view-button type-view-button-academy ${isAcademy ? 'active disabled' : 'hover'}`}>
                  BIM Academy
                </div>
                <BngIconButton
                  className={`type-view-icon-button ${isAcademy ? 'active disabled' : ''}`}
                  icon={'school'}
                  title={'BIM Academy'}
                />
              </div>
            )}

            {roundButtonProps && <NavRoundButton {...roundButtonProps} />}
          </div>

          <ul className="nav ace-nav pull-right">
            {showDiscoverButtons && (
              <ObjectSearchContainer
                className={rightButtonsExpanded ? 'hide-on-small' : ''}
                onToggle={toggleSearchBar}
                searching={searchExpanded}
              />
            )}

            <animated.ul className="navRightButtons" style={rightButtonStyles} ref={_navRightButtonsRed}>
              <li>
                <div className="navBarSeparator navBarItem" />
              </li>

              {context.user.superUser && <AdminMenuButton />}

              {context.permissions.canManageMonitors() && showDiscoverButtons && (
                <CentralMonitoringMenuButtonContainer />
              )}

              {context.showSupportMenu && <SupportMenuButton />}

              {context.permissions.isAdmin() && <PublisherMenuButtonContainer />}

              <ProjectMenuButtonContainer />

              {showDiscoverButtons && <ExportMenuButton />}

              {context.isAccessingProject && <PresentationMenuButtonContainer />}

              <li>
                <div className="navBarSeparator" />
              </li>

              {perm.canCreateObjects() && showDiscoverButtons && <CreateObjectMenuButton />}

              <NotificationMenuButtonContainer badgeValue={info?.counts?.notifications} />

              {context.accountId && !context.accountEnabledForBilling && <AddonNavButton />}

              {context.canManageCurrentProject && <ManageEnvironment projectId={context.project.id} />}
            </animated.ul>

            {rightButtonsExpanded && (
              <li className={'reducedRightMenuButton'}>
                <div className="navBarSeparator" />
              </li>
            )}

            <li>
              <BngIconButton
                className={`reducedRightMenuButton closeMenuButton`}
                icon={rightButtonsExpanded ? 'menu_open' : 'menu'}
                onClick={() => setRightButtonsExpanded(!rightButtonsExpanded)}
              />
            </li>

            <UserMenuButton />
          </ul>

          <FloatingNotificationAlert />
        </div>
      </div>
    </div>
  );
}
