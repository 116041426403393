'use strict';

import { Axios, findContextPath } from 'commonUtils';
import Utils from 'components/Utils';

const $ = jQuery;

class ProposalsUtils {
  PRICING_TYPES = {
    PLAN: 'PLAN',
    ADDITIONAL: 'ADDITIONAL',
    SERVICE: 'SERVICE',
  };

  ACTIVATION_TYPES = {
    REQUESTED: 'REQUESTED',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    CANCELLED: 'CANCELLED',
  };

  PROPOSAL_ORIGIN = {
    BILLING_PROPOSALS: 'BILLING_PROPOSALS',
    BIM_EXTENSIONS: 'BIM_EXTENSIONS',
    BIM_ACCOUNT: 'BIM_EXTENSIONS',
    EMAIL: 'BIM_EXTENSIONS',
    BIM_ADD_ADDITIONAL_DIALOG: 'BIM_EXTENSIONS',
    BIM_ADD_ADDITIONAL_DIALOG_REQUEST: 'BIM_EXTENSIONS',
    BIM_MARKETPLACE: 'BIM_EXTENSIONS',
  };

  billingFormat = (proposalPricing) => {
    if (!!proposalPricing.pricing.service) {
      return proposalPricing.pricing.service.recurringType;
    } else {
      return proposalPricing.billingFormat;
    }
  };

  salesFromProposalPricing = (proposalPricing) => {
    const pricing = proposalPricing.pricing;
    return pricing.plan || pricing.additional || pricing.service || pricing.existingSalesObj || null;
  };

  proposalUserFromProposal = (proposal) => {
    return (
      proposal.props.requestProps == null ||
      proposal.props.requestProps.userName == null ||
      proposal.props.requestProps.userName
    );
  };

  activationUserFromPricing = (pricing) => {
    const activationProps = pricing.proposalProps?.activationProps;
    if (!activationProps || !activationProps.userName) {
      return '';
    } else if (Utils.Project.isBimachineEmail(activationProps.userEmail)) {
      return 'system';
    } else {
      return activationProps.userName;
    }
  };

  translationsForProposalPricing = (proposalPricing, language = window.__USER_LANG) => {
    const salesObj = this.salesFromProposalPricing(proposalPricing);
    if (!!salesObj.props) {
      return this.translationsForSales(salesObj, language);
    } else {
      return salesObj;
    }
  };

  translationsForSales = (salesObj, language = window.__USER_LANG) => {
    const translations = salesObj.props?.translations ?? salesObj.features.props.translations;
    return this.findTranslation(translations, language);
  };

  findTranslation = (props, language = window.__USER_LANG) => {
    return props.langs[language];
  };

  getDefaultPricing = (pricings = []) => {
    return pricings.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))[0];
  };

  getPricingLabel = ({ pricing, currency }) => {
    return this.formatPricing(pricing, currency.symbol);
  };

  formatPricing = (pricing, currencySymbol, countryFormat = window.__USER_LANG) => {
    const numberFormatter = new Intl.NumberFormat(countryFormat, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${currencySymbol} ${numberFormatter.format(pricing)}`;
  };

  getProposalPricingType = (proposalPricing) => {
    const pricing = proposalPricing.pricing;
    if (!!pricing.plan) {
      return proposalUtils.PRICING_TYPES.PLAN;
    } else if (!!pricing.additional) {
      return proposalUtils.PRICING_TYPES.ADDITIONAL;
    } else if (!!pricing.service) {
      return proposalUtils.PRICING_TYPES.SERVICE;
    }
  };

  canSelectQuantityInMarketplace = (marketplaceItem) => {
    const priceBlock = marketplaceItem.pageContent?.find((pageContent) => pageContent.type === 'SINGLE_PRICE');
    return priceBlock?.props.selectQuantity;
  };

  buildPricings = (proposalArray) => {
    const planPricings = proposalArray
      .filter((proposal) => proposal.planPricings.length > 0)
      .flatMap((proposal) =>
        proposal.planPricings.map((pp) => {
          return {
            proposalId: proposal.id,
            proposalProps: proposal.props,
            acceptDate: proposal.acceptDate,
            proposalStatus: proposal.status,
            ...pp,
          };
        }),
      );
    const additionalPricings = proposalArray
      .filter((proposal) => proposal.additionalPricings.length > 0)
      .flatMap((proposal) =>
        proposal.additionalPricings.map((ap) => {
          return {
            proposalId: proposal.id,
            proposalProps: proposal.props,
            acceptDate: proposal.acceptDate,
            proposalStatus: proposal.status,
            ...ap,
          };
        }),
      );
    const servicePricings = proposalArray
      .filter((proposal) => proposal.servicePricings.length > 0)
      .flatMap((proposal) =>
        proposal.servicePricings.map((sp) => {
          return {
            proposalId: proposal.id,
            proposalProps: proposal.props,
            acceptDate: proposal.acceptDate,
            proposalStatus: proposal.status,
            ...sp,
          };
        }),
      );

    return { additionalPricings, servicePricings, planPricings };
  };
}

export const proposalUtils = new ProposalsUtils();

class AccountApi {
  static async findAvailable(opts = { includeRelated: false, activeForBilling: false }) {
    const { data } = await Axios.get(`/spr/accounts`, {
      params: {
        includeRelated: opts?.includeRelated ?? false,
        activeForBilling: opts?.activeForBilling ?? false,
      },
    });
    return data;
  }

  static async findAccount(accountId, params = { publicDataOnly: false }) {
    return await Axios.getData(`/spr/accounts/${accountId}`, {
      params,
    });
  }

  static findAvailableDetails() {
    return $.getJSON('/spr/accounts/details');
  }

  static async findAccountAddons(accountId) {
    const { data } = await Axios.get(`/spr/accounts/${accountId}/addons`);
    return data;
  }

  static async findAddonsInfo(addons) {
    const isAddonsArr = _.isArray(addons);
    const { data } = await Axios.get(`/spr/accounts/addons`, {
      params: {
        addons: isAddonsArr ? addons.join(',') : addons,
      },
    });

    if (!_.isArray(addons)) {
      return data[0];
    }

    return data;
  }

  static async findAccountAddonInfo(accountId, addon) {
    const { data } = await Axios.get(`/spr/accounts/${accountId}/addon`, {
      params: {
        addon,
      },
    });
    return data;
  }

  static async requestAddon(addon, projectId, billingAdditional) {
    const { data } = await Axios.post(`/spr/accounts/addons/request`, {
      addon,
      projectId,
      billingAdditional,
    });
    return data;
  }

  static async changeAddonRequestStatus(addon, projectId, enable, plan, billingAdditional) {
    const { data } = await Axios.post(`/spr/accounts/addons/change-status`, {
      addon,
      projectId,
      enable,
      plan,
      billingAdditional,
    });
    return data;
  }

  static async addonNotEnabled(addon, projectId) {
    const { data } = await Axios.post(`/spr/accounts/addons/not-enabled`, {
      addon,
      projectId,
    });
    return data;
  }

  static findAccountMetrics(accountId) {
    return $.getJSON('/spr/accounts/metrics', { accountId });
  }

  static findAccountProjects({ accountId, role }) {
    return $.getJSON('/spr/accounts/projects', { accountId, role });
  }

  static findAvailablePlans() {
    return $.getJSON('/spr/accounts/plans');
  }

  static findApplicationKeys(accountId) {
    return $.getJSON('/spr/accounts/application-key', { accountId });
  }

  static generateToken() {
    return $.getJSON('/spr/accounts/application-key/generate-token');
  }

  static saveApplicationKey(accountId, applicationKey) {
    return $.postJSON(`/spr/accounts/application-key/${accountId}`, applicationKey);
  }

  static removeApplicationKey(applicationKey) {
    return $.deleteReq(`/spr/accounts/application-key/${applicationKey.token}`);
  }

  static saveAccount(account) {
    return $.postJSON('/spr/accounts/save', account);
  }

  static async findUserReferences(userId) {
    const { data } = await Axios.get(`/spr/accounts/userReferences/${userId}`);
    return data;
  }

  static async clearUserReferences(userId) {
    const { data } = await Axios.post(`/spr/accounts/userReferences/${userId}/clear`);
    return data;
  }

  static async findAddonWarning() {
    const { data } = await Axios.get(`/spr/accounts/addons/alert`);
    return data;
  }

  static redirectToAccountsPage() {
    window.location.replace(`${findContextPath()}/spr/bng/accounts?breadcrumb=true`);
  }

  static async changePlan(accountId, values = {}) {
    const { data } = await Axios.post(`/spr/accounts/${accountId}/changePlan`, values);
    return data;
  }

  static async fetchPlans(accountId) {
    return await Axios.getData(`/spr/accounts/${accountId}/billingPlans`);
  }

  static async findAccountPlan(accountId) {
    const { data } = await Axios.get(`/spr/accounts/${accountId}/plans`);
    return data;
  }

  static async fetchPricings(accountId) {
    const { data } = await Axios.get(`/spr/accounts/${accountId}/proposals`);
    return proposalUtils.buildPricings(data.proposals);
  }

  static async fetchBillingAccountInfo(accountId) {
    const { data } = await Axios.get(`/spr/accounts/${accountId}/billing`);
    return new AccountInfo(data);
  }

  static async saveAddon(accountId, addon) {
    const { data } = await Axios.post(`/spr/accounts/${accountId}/addon`, addon);
    return data;
  }

  static async fetchAccountUsers(accountId) {
    const { data } = await Axios.get(`/spr/accounts/${accountId}/projectUsers`);
    return data.map(({ projectUser, accountManagePermissions }) => ({
      ...projectUser,
      accountManagePermissions: accountManagePermissions ?? [],
    }));
  }

  static async fetchProposalPricing(proposalPricingId, type, accountId) {
    const { data } = await Axios.get(`/spr/accounts/${accountId}/proposalPricing/${proposalPricingId}`, {
      params: { type: type },
    });
    return data;
  }

  static async fetchReviewRates(accountId) {
    const { data } = await Axios.get(`/spr/accounts/${accountId}/reviewRates`);
    return data;
  }

  static async findInvoiceProjectionInfo(accountId, invoiceProjectionId) {
    const { data } = await Axios.get(`/spr/accounts/${accountId}/invoiceProjection/${invoiceProjectionId}`);
    return data;
  }

  static async shouldAddResource(accountId, classification) {
    return await Axios.getData(`/spr/accounts/${accountId}/shouldAddResource`, {
      params: {
        classification,
      },
    });
  }

  static async fetchResourceUsage(accountId, classification) {
    return await Axios.getData(`/spr/accounts/${accountId}/resourceUsage`, {
      params: {
        classification,
      },
    });
  }

  static async fetchAdditionalInfo(accountId, featureKey, isConsumptionAdditional) {
    return await Axios.getData(`/spr/accounts/${accountId}/additionalInfo`, {
      params: {
        featureKey,
        isConsumptionAdditional,
      },
    });
  }

  static async requestAdditional({ accountId, additional, projectId, enable, origin }) {
    return await Axios.postData(`/spr/accounts/${accountId}/request/additional`, {
      additional,
      projectId,
      enable,
      origin,
    });
  }

  static async fetchOldestWaitingProposal(accountId) {
    return await Axios.getData(`/spr/accounts/${accountId}/waitingProposal`);
  }

  static async fetchProposal(proposalId) {
    return await Axios.getData(`/spr/accounts/proposal/${proposalId}`);
  }

  static async resolveProposal({ accountId, proposalId, status, projectId, origin }) {
    return await Axios.postData(`/spr/accounts/${accountId}/proposal/resolve`, {
      proposalId,
      status,
      projectId,
      origin,
    });
  }

  static async fetchContractInfo(accountId) {
    return await Axios.getData(`/spr/accounts/${accountId}/contract`);
  }

  static async fetchAccountOrigins(accountId) {
    return await Axios.getData(`/spr/accounts/${accountId}/origins`);
  }

  static async changeMaster(accountId, userId) {
    return await Axios.postData(`/spr/accounts/${accountId}/changeMaster`, {
      userId,
    });
  }

  static async resendProposalEmail(proposalId, projectId) {
    return await Axios.postData(`/spr/accounts/proposal/${proposalId}/resend`, { projectId });
  }

  static async toggleAccountManagePermission({ accountId, userId }) {
    return await Axios.postData(`/spr/accounts/${accountId}/manage`, { userId });
  }

  static async isAccountEnabledInBilling(accountId) {
    return await Axios.getData(`/spr/accounts/${accountId}/enabledInBilling`);
  }

  static async toggleAccountEnabledInBilling(accountId) {
    return await Axios.postData(`/spr/accounts/${accountId}/enabledInBilling`);
  }

  static addResourceToAccount({ accountId = 0, accountMetric = { additionalCapacity: '', resource: '' } }) {
    return Axios.postData(`/spr/accounts/${accountId}/addResource`, {
      accountMetric,
    });
  }

  static async fetchAddonProps({ accountId, addon }) {
    return await Axios.getData(`/spr/accounts/${accountId}/addons/${addon}`);
  }

  static async updateAddonProps({ accountId, addon, props }) {
    return await Axios.postData(`/spr/accounts/${accountId}/addons/${addon}`, { props });
  }

  static async findAdaAiConfig(accountId = 0) {
    return await Axios.getData(`/spr/accounts/${accountId}/adaAi`);
  }

  static async saveAdaAiConfig(accountId = 0, config = { chatGptKey: '' }) {
    return await Axios.postData(`/spr/accounts/${accountId}/adaAi`, config);
  }

  static fetchQuotaUsage({ accountId }) {
    return Axios.getData(`/spr/accounts/${accountId}/quota/usage`);
  }

  static clearBillingCache({ accountId }) {
    return Axios.postData(`/spr/accounts/${accountId}/clearBillingCache`);
  }

  static async saveAccountConfig(accountId, accountConfig) {
    return await Axios.postData(`/spr/accounts/${accountId}/config`, accountConfig);
  }

  static fetchUpfrontPaymentMonths({accountId}) {
    return Axios.getData(`/spr/accounts/${accountId}/upfrontPaymentMonths`)
  }

  static createProposalUpfrontPayment({accountId, upfrontPayments}) {
    return Axios.postData(`/spr/accounts/${accountId}/proposalUpfrontPayment`, upfrontPayments)
  }
}

class AccountInfo {
  constructor(data) {
    Object.assign(this, data);
    this.planPricing = data?.proposals
      .filter((proposal) => proposal.planPricings.length > 0)
      .flatMap((proposal) =>
        proposal.planPricings
          .filter((pp) => pp.status === 'ENABLED')
          .map((pp) => {
            return {
              proposalId: proposal.id,
              proposalStatus: proposal.status,
              proposalAcceptDate: proposal.acceptDate,
              ...pp,
            };
          }),
      )
      .find((planPricing) => planPricing.pricing.plan.id === data.activePlan.id);
    if (this.activePlan) {
      this.planAddons = this.activePlan.planFeatures
        .filter((feat) => feat.features.classification === 'ADDON' && feat.value.value)
        .map((feat) => {
          return {
            proposalId: this.activePlan.proposalId,
            proposalStatus: this.activePlan.proposalStatus,
            proposalAcceptDate: this.activePlan.proposalAcceptDate,
            billingFormat: this.activePlan.billingFormat,
            quantity: this.activePlan.quantity,
            ...feat.features,
          };
        });
    }

    this.additionalPricings = data?.proposals
      .filter((proposal) => proposal.additionalPricings.length > 0)
      .flatMap((proposal) =>
        proposal.additionalPricings
          .filter((ap) => ap.status === 'ENABLED')
          .map((ap) => {
            return {
              proposalId: proposal.id,
              proposalStatus: proposal.status,
              proposalAcceptDate: proposal.acceptDate,
              ...ap,
            };
          }),
      );
    this.servicePricings = data?.proposals
      .filter((proposal) => proposal.servicePricings.length > 0)
      .flatMap((proposal) =>
        proposal.servicePricings
          .filter((sp) => sp.status === 'ENABLED')
          .map((sp) => {
            return {
              proposalId: proposal.id,
              proposalStatus: proposal.status,
              proposalAcceptDate: proposal.acceptDate,
              ...sp,
            };
          }),
      );
    this.contract = data?.proposals[0].contract;
    this.invoices = this.invoices.map((invoice) => {
      return {
        ...invoice,
        invoiceProjection: {
          ...invoice.invoiceProjection,
          referenceMonth: new Date(`${invoice.invoiceProjection.referenceMonth}T12:00:00`),
          nominalPricing: parseFloat(invoice.invoiceProjection.nominalPricing.toFixed(2)),
        },
      };
    });
    this.activePlan.planFeatures = this.activePlan.planFeatures.map((pf) => {
      if (pf.features.type === 'INT') {
        return {
          ...pf,
          value: {
            ...pf.value,
            value: pf.value.value ? parseFloat(pf.value.value) : 0,
          },
        };
      } else {
        return { ...pf };
      }
    });
    this.allAddons = [
      ...this.activeAddons,
      ...this.planAddons.map((feat) => {
        return { feature: feat, fromPlan: true };
      }),
    ];
  }
}

export default AccountApi;
