import { Axios } from 'commonUtils';
import bimEventBus from 'BimEventBus';
import { proposalUtils } from 'components/service/bng/AccountApi';

class MarketplaceApi {
  billingUrl = 'https://billing.bimachine.com';

  constructor() {
    bimEventBus.on('CeDataInitialized', ({ ceData }) => {
      this.billingUrl = ceData.context?.billingConf?.url ?? this.billingUrl;
    });
  }

  fetchMarketplaceInfo = async (accountId, salesObjId, salesObjType) => {
    return await Axios.getData(`/spr/marketplace/${accountId}`, { params: { salesObjId, salesObjType } });
  };

  activateMarketplaceItem = async ({ accountId, items, plan, voucher, projectId, requestMessage }) => {
    await Axios.post(`/spr/marketplace/activate`, {
      accountId,
      items,
      projectId,
      plan,
      voucher,
      requestMessage,
    });
  };

  changeItemStatus = async (salesItemKey, salesItemType, projectId, enable, plan, salesItem) => {
    return await Axios.postData(`/spr/marketplace/addons`, {
      salesItemKey,
      salesItemType,
      projectId,
      enable,
      plan,
      salesItem,
    });
  };

  fetchAll = async (accountId) => {
    return await Axios.getData(`/spr/marketplace/${accountId}/all`);
  };

  marketplaceImageUrl = (ref = '') => {
    if (!ref) {
      return '';
    }
    return Axios.getUri({
      url: `${this.billingUrl}/api/uploads/marketplaceImage`,
      params: {
        ref,
      },
    });
  };

  fetchFeaturesForPlan = async (accountId) => {
    return await Axios.getData(`/spr/marketplace/${accountId}/features/plan`);
  };

  changeAccountPlan = async (data = { accountId: 0, plan: null, isRequest: true, projectId: 0 }) => {
    return await Axios.postData(`/spr/marketplace/${data.accountId}/changePlan`, data);
  };

  fetchVoucher = async (voucherName, accountId) => {
    const data = await Axios.getData(`/spr/marketplace/voucher/${voucherName}`, { params: { accountId } });
    return new Voucher(data);
  };

  canActivateAddonWithoutRequest = async (accountId) => {
    return await Axios.getData(`/spr/marketplace/${accountId}/canActivateAddonWithoutRequest`);
  };
}

export const applyVoucherSalesPricingOnValue = (value, voucherSalesPricing) => {
  const { type, discountType, discountValue } = voucherSalesPricing;
  let result = 0;
  switch (type) {
    case 'DISCOUNT': {
      switch (discountType) {
        case 'PERCENT': {
          result = value * (discountValue / 100);
          break;
        }
        case 'FLAT_VALUE': {
          result = discountValue;
          break;
        }
      }
      break;
    }
    case 'BONUS': {
      result = value;
      break;
    }
  }
  result = result * -1;
  return result;
};

export class Voucher {
  constructor(data) {
    Object.assign(this, data);

    data.voucherPlanPricings.forEach((vpp) => {
      vpp.applyOnValue = (value) => applyVoucherSalesPricingOnValue(value, vpp);
    });
    data.voucherAdditionalPricings.forEach((vap) => {
      vap.applyOnValue = (value) => applyVoucherSalesPricingOnValue(value, vap);
    });
    data.voucherServicePricings.forEach((vsp) => {
      vsp.applyOnValue = (value) => applyVoucherSalesPricingOnValue(value, vsp);
    });
  }
}

export function getAppliedItemFromVoucher(voucher, pricingType, { name, pricing }) {
  const itemsApplied = [];
  switch (pricingType) {
    case proposalUtils.PRICING_TYPES.PLAN: {
      voucher.voucherPlanPricings
        .filter((vpp) => vpp.planPricing.id === pricing.id)
        .forEach((vpp) => {
          const calculatedPricing = applyVoucherSalesPricingOnValue(pricing.pricing, vpp);
          itemsApplied.push({
            ...vpp,
            name,
            salesType: 'planPricing',
            pricing: calculatedPricing,
          });
        });
      break;
    }
    case proposalUtils.PRICING_TYPES.ADDITIONAL: {
      voucher.voucherAdditionalPricings
        .filter((vap) => vap.additionalPricing.id === pricing.id)
        .forEach((vap) => {
          const calculatedPricing = applyVoucherSalesPricingOnValue(pricing.pricing, vap);
          itemsApplied.push({
            ...vap,
            name,
            salesType: 'additionalPricing',
            pricing: calculatedPricing,
          });
        });
      break;
    }
    case proposalUtils.PRICING_TYPES.SERVICE: {
      voucher.voucherServicePricings
        .filter((vsp) => vsp.servicePricing.id === pricing.id)
        .forEach((vsp) => {
          const calculatedPricing = applyVoucherSalesPricingOnValue(pricing.pricing, vsp);
          itemsApplied.push({
            ...vsp,
            name,
            salesType: 'servicePricing',
            pricing: calculatedPricing,
          });
        });
      break;
    }
  }
  return itemsApplied;
}

export function getTotalVoucherPricing(items = []) {
  items = items.sort((a, b) => {
    if (a.type === 'BONUS') {
      return -1;
    } else if (b.type === 'BONUS') {
      return 1;
    }
    return 0;
  });

  const alreadyProcessed = new Set();
  let result = 0;
  for (const item of items) {
    const itemIdentifier = `${item.salesType}_${item[item.salesType].id}`;
    if (alreadyProcessed.has(itemIdentifier)) {
      continue;
    }
    alreadyProcessed.add(itemIdentifier);
    result += item.pricing || 0;
  }

  return result;
}

export default MarketplaceApi;
