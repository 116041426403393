import './ConfigureAccordion.css';
import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import Api from 'components/Api';
import Utils from 'components/Utils';
import Accordion from 'components/ui/Accordion';
import AccordionList from 'components/ui/AccordionList';
import UiMsg from 'components/ui/UiMsg';
import ActionList from 'components/ui/dashboard/components/ActionList';
import BngFolderField from 'components/bng/form/BngFolderField';
import BngField from 'components/bng/form/BngField';
import BngInput from 'components/bng/form/BngInput';
import BngTextArea from 'components/bng/form/BngTextArea';
import BngSelect from 'components/bng/form/BngSelect';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import OpConfirmation from 'components/ui/OpConfirmation';
import bngYup from 'components/bng/form/yup/BngYup';
import useRightMenuStore from 'components/ui/dashboard/components/useRightMenuStore';
import AddonType from 'components/bng/accounts/AddonType';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import useTranslation from 'components/hooks/useTranslation';
import useBimContext from 'components/hooks/useBimContext';

const paperSizes = [
  { value: 'A4', label: 'A4' },
  { value: 'A3', label: 'A3' },
  { value: 'A2', label: 'A2' },
];

const orientationTypes = [
  { value: 'portrait', label: 'printer.portrait' },
  { value: 'landscape', label: 'printer.landscape' },
];

const paperOrientationBox = (context) => {
  let opts = [];
  for (let orientation of orientationTypes) {
    for (let paper of paperSizes) {
      opts.push({
        value: `${paper.value}_${orientation.value}`,
        label: `${paper.label} - ${context.msg.t(orientation.label)}`,
      });
    }
  }
  return opts;
};

export const ConfigureSchema = bngYup((yup) => {
  return yup.object().shape({
    folder: yup.string().required('').typeError('no.dir.object.error').default('/'),
    name: yup.string().required().min(1).max(100).trim().validObjectName().default(''),
    description: yup.string().required().default(''),
    printer: yup
      .object()
      .shape({
        printerOrientation: yup.string().required(),
        printerTitleSize: yup.number().required().min(0).max(99),
        printerDescriptionSize: yup.number().required().min(0).max(99),
        printerFiltersSize: yup.number().required().min(0).max(99),
        printerShowPageNumber: yup.boolean().required(),
        printerShowHeader: yup.boolean().required(),
        printerShowFooter: yup.boolean().required(),
        printerShowBackground: yup.boolean().required(),
        printerShowComments: yup.boolean().required(),
      })
      .nullable()
      .default({
        printerOrientation: 'A3_landscape',
        printerTitleSize: 11,
        printerDescriptionSize: 9,
        printerFiltersSize: 8,
        printerShowPageNumber: false,
        printerShowHeader: true,
        printerShowFooter: true,
        printerShowBackground: true,
        printerShowComments: false,
      }),
  });
});

export const objectConfigInitialValues = ({ folder, name, description, printer = null }) => {
  return {
    ...ConfigureSchema.default(),
    folder: folder,
    name: name,
    description: description,
    printer: printer,
  };
};

function SettingsAccordion() {
  const { t } = useTranslation();
  return (
    <Accordion title={t('settings')} id="SettingFolderFieldsAccordion">
      <BngFolderField name="objectConfig.folder" className="FolderField" style={{ maxWidth: '298px' }} />

      <div className="RenameField flex-center-items">
        <Field
          name="objectConfig.name"
          required={true}
          label={t('name')}
          rootProps={{ style: { width: '100%' } }}
          component={BngField}
          inputComponent={BngInput}
        />
      </div>
      <Field
        name="objectConfig.description"
        required={true}
        rows="5"
        label={t('description')}
        component={BngField}
        inputComponent={BngTextArea}
      />
    </Accordion>
  );
}

export function VersionAccordion({ versions, fetchVersions, accordionProps = {}, path }) {
  const { t } = useTranslation();
  const execRestore = async (version) => {
    try {
      await Api.Bng.restoreVersion({
        path,
        versionId: version.id,
      });
      await Api.updateJsf();

      window.location.replace(Api.loadObjectUrl({ content: path }));
    } catch (e) {
      console.error('Error on execRestore()', { version, path }, e);
      UiMsg.ajaxError(t('restore.version.fail'), e);
    }
  };

  const restoreVersion = ({ version }) => {
    OpConfirmation({
      title: t('attention'),
      message: t('restore.version.confirm'),
      level: 'warning',
      onConfirm: () => execRestore(version),
    });
  };

  const openMDXDialog = async ({ version }) => {
    try {
      const mdx = await Api.Bng.getVersionMdx(version);
      Swal.fire({
        title: 'MDX',
        html: `<div><pre style="text-align: left">${mdx}</pre></div>`,
      });
    } catch (e) {
      console.error('Error on openMDXDialog()', { version, path }, e);
      UiMsg.error(t('fetch.mdx.version.fail'), e);
    }
  };

  const getVersionRowData = () => {
    if (!versions) return [];

    return versions.versions.map((version) => {
      const isActualVersion = false;
      let actions = [];
      if (Utils.Object.isAnalysis(path)) {
        actions.push({
          icon: 'functions',
          title: t('see.mdx'),
          onClick: () => openMDXDialog({ version }),
        });
      }
      actions.push({
        className: isActualVersion ? 'ActualVersion' : '',
        icon: isActualVersion ? 'done' : 'settings_backup_restore',
        title: t(isActualVersion ? 'version.selected' : 'restore.action.title'),
        disabled: isActualVersion,
        onClick: () => restoreVersion({ version }),
      });
      return {
        description: Utils.Date.formatDateTime(version.date),
        actions,
      };
    });
  };

  return (
    <Accordion
      id="VersionAccordion"
      startOpen={false}
      title={t('versioning')}
      onOpen={fetchVersions}
      {...accordionProps}
    >
      <ActionList className="VersionActionList" items={getVersionRowData()} searchField={false} />
    </Accordion>
  );
}

function PrinterAccordion({ path }) {
  const context = useBimContext();
  const { values, setValues } = useFormikContext();

  const [removeBackgroundTrace, setRemoveBackgroundTrace] = useState(false);

  const [storePrinterConfig, setPrinterConfig, fetchPrinterConfig] = useRightMenuStore((state) => [
    state.printerConfig,
    state.setPrinterConfig,
    state.fetchPrinterConfig,
  ]);

  useEffect(() => {
    fetchPrinterConfig(path);
  }, []);

  useEffect(() => {
    if (!storePrinterConfig || !!values.objectConfig.printer) return;
    const { objectConfig, ...otherValues } = values;
    setValues({
      objectConfig: Object.assign({}, objectConfig, { printer: _.cloneDeep(storePrinterConfig) }),
      ...otherValues,
    });
  }, [storePrinterConfig]);

  useEffect(() => {
    setPrinterConfig(values.objectConfig.printer);
  }, [values]);

  useEffect(() => {
    (async () => {
      const hasTraceAddon = checkAddonEnabled(AddonType.TRACEABILITY.key, false);
      if (!hasTraceAddon) {
        return;
      }
      const traceInfo = await Api.Account.findAccountAddonInfo(context.accountId, AddonType.TRACEABILITY.key);
      const removeBgTrace = traceInfo.props.config.removeBackgroundImage ?? false;
      setRemoveBackgroundTrace(removeBgTrace);
    })();
  }, []);

  useEffect(() => {
    const clonedValues = _.cloneDeep(values);
    if (clonedValues.objectConfig.printer && removeBackgroundTrace) {
      clonedValues.objectConfig.printer.printerShowBackground = false;
      setValues(clonedValues);
    }
  }, [removeBackgroundTrace]);

  return (
    <Accordion id="PrinterAccordion" startOpen={false} title={context.msg.t('printer')}>
      {!!values.objectConfig.printer && (
        <>
          <Field
            name="objectConfig.printer.printerOrientation"
            emptyOption={false}
            label={context.msg.t('orientacao_pagina')}
            component={BngField}
            inputComponent={BngSelect}
            options={paperOrientationBox(context)}
          />
          <div className="PrinterConfigValues">
            <Field
              name="objectConfig.printer.printerTitleSize"
              label={context.msg.t('title')}
              component={BngField}
              type="number"
            />
            <Field
              name="objectConfig.printer.printerDescriptionSize"
              label={context.msg.t('description')}
              component={BngField}
              type="number"
            />
            <Field
              name="objectConfig.printer.printerFiltersSize"
              label={context.msg.t('filters')}
              component={BngField}
              type="number"
            />
          </div>

          <div className="PrinterConfigCheckboxOpts">
            <Field
              name="objectConfig.printer.printerShowPageNumber"
              label={context.msg.t('printer.setup.page.number.show')}
              component={BngCheckbox}
            />
            <Field
              name="objectConfig.printer.printerShowHeader"
              label={context.msg.t('printer.setup.page.show.header')}
              component={BngCheckbox}
            />
            {/*TODO:: Footer and legend in maps are never removed with this option, so its selection is disabled, although it should be implemented one day*/}
            {!Utils.Object.isNewMap(path) && (
              <Field
                name="objectConfig.printer.printerShowFooter"
                label={context.msg.t('printer.setup.page.show.footer')}
                component={BngCheckbox}
              />
            )}
            <Field
              name="objectConfig.printer.printerShowBackground"
              label={context.msg.t('printer.setup.page.show.background')}
              component={BngCheckbox}
              disabled={removeBackgroundTrace}
              title={removeBackgroundTrace ? context.msg.t('background.printing.disabled.by.admins') : ''}
            />
            {!Utils.Object.isNewMap(path) && (
              <Field
                name="objectConfig.printer.printerShowComments"
                label={context.msg.t('printer.setup.page.show.comments')}
                component={BngCheckbox}
              />
            )}
          </div>
        </>
      )}
    </Accordion>
  );
}

export default function ConfigureAccordion({ versions, fetchVersions, accordionProps, path }) {
  useEffect(() => {
    fetchVersions();
  }, []);

  return (
    <AccordionList className="ObjectRightMenuAccordion ConfigureAccordion">
      <SettingsAccordion />
      <VersionAccordion versions={versions} fetchVersions={fetchVersions} accordionProps={accordionProps} path={path} />
      <PrinterAccordion path={path} />
    </AccordionList>
  );
}
