import './DataOriginsDialog.css';
import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';

import BngSearch from 'components/bng/ui/BngSearch';
import Api from 'components/Api';
import * as Actions from 'components/ui/redux/Actions';
import { MODALS } from 'components/ui/redux/Actions';
import { BngHorizontalCard } from 'components/bng/ui/BngHorizontalCard';
import Dialog from 'components/ui/Dialog';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { BngForm } from 'components/bng/form/BngForm';
import BngSuggestion from 'components/bng/ui/BngSuggestion';
import BngIntegrationRequest from 'components/bng/ui/BngIntegrationRequest';
import Accordion from 'components/ui/Accordion';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import {
  DATA_ORIGINS_DIALOG_QUERY_PARAM,
  NEW_STRUCTURE_QUERY_PARAM,
  WsTypeModal,
} from 'components/bng/pages/admin/structures/StructuresPage';
import BimIntegrationEulaDialog from 'components/ui/in-memory/BimIntegrationEulaDialog';
import ActivateBimDialog from 'components/ui/navbar/ActivateBimDialog';
import UiMsg from 'components/ui/UiMsg';
import IntegrationConnectionDialog from 'components/ui/in-memory/IntegrationConnectionDialog';
import DatabaseConnectionDialog from 'components/ui/in-memory/DatabaseConnectionDialog';
import useBimContext from 'components/hooks/useBimContext';

const openJsfDialog = (structureType) => {
  Api.executeExp(`#{projectSelectionMB.inMemoryCRUD.createGenericStructure('${structureType}')}`);
  Utils.History.updateQuery(
    {
      [NEW_STRUCTURE_QUERY_PARAM]: `jsf-${structureType}`,
    },
    { replace: true }
  );
};

const openEulaDialog = (structureType, structureQueryParam) => {
  ReduxStore.dispatch(
    Actions.MODALS.open(BimIntegrationEulaDialog, {
      onAgreement({ closeModal }) {
        closeModal();
        Api.executeExp(structureType);
      },
    })
  );
  Utils.History.updateQuery(
    {
      [NEW_STRUCTURE_QUERY_PARAM]: structureQueryParam,
    },
    { replace: true }
  );
};

export const openCustomDialog = (name, projectId) => {
  const customForm = WsTypeModal[name];
  ReduxStore.dispatch(
    Actions.MODALS.open(BimIntegrationEulaDialog, {
      onAgreement({ closeModal }) {
        closeModal();
        ReduxStore.dispatch(
          Actions.MODALS.open(customForm, {
            projectId,
          })
        );
      },
    })
  );
  Utils.History.updateQuery(
    {
      [NEW_STRUCTURE_QUERY_PARAM]: `cus-${name}`,
    },
    { replace: true }
  );
};

const defaultIntegration = (context) => [
  {
    sourceType: 'SQL',
    name: 'Database',
    description: 'DataOriginsDialog.origin.database.description',
    logo: `${Api.baseUrl()}/images/data/db.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.db.relational', 'bim.integration.category.spotlight'],
    onClick: (projectId) => {
      openJsfDialog('SQL');
    },
  },
  {
    sourceType: 'SQL_APP',
    name: 'BIMachine',
    description: 'DataOriginsDialog.origin.bimachine.description',
    logo: `${Api.baseUrl()}/images/data/logo.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.db.relational', 'bim.integration.category.spotlight'],
    onClick: (projectId) => {
      openJsfDialog('SQL_APP');
    },
  },
  {
    sourceType: 'ELASTIC',
    name: 'Elastic',
    description: 'DataOriginsDialog.origin.elastic.description',
    logo: `${Api.baseUrl()}/images/data/elastic.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.digital.services', 'bim.integration.category.spotlight'],
    onClick: (projectId) => {
      openJsfDialog('ELASTIC');
    },
  },
  {
    sourceType: 'UMovMe',
    name: 'UMovMe',
    description: 'DataOriginsDialog.origin.umov.me.description',
    logo: `${Api.baseUrl()}/images/data/umov-me.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.db.relational'],
    onClick: () => {
      openEulaDialog(`#{projectSelectionMB.inMemoryCRUD.createGenericStructure('UMovMe')}`, 'jsf-UMovMe');
    },
  },
  {
    sourceType: 'DW',
    name: 'Data Warehouse',
    description: 'DataOriginsDialog.origin.dw.description',
    logo: `${Api.baseUrl()}/images/data/dw.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.db.multidimensional'],
    onClick: (projectId) => {
      openJsfDialog('DW');
    },
  },
  {
    sourceType: 'CSV',
    name: 'CSV',
    description: 'DataOriginsDialog.origin.csv.description',
    logo: `${Api.baseUrl()}/images/data/csv.svg`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.text.file', 'bim.integration.category.spotlight'],
    onClick: (projectId) => {
      openJsfDialog('CSV');
    },
  },
  {
    sourceType: 'REMOTE_FILE_SOURCE',
    name: 'FTP',
    description: 'DataOriginsDialog.origin.ftp.description',
    logo: `${Api.baseUrl()}/images/data/ftp.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.text.file', 'bim.integration.category.spotlight'],
    onClick: (projectId) => {
      openJsfDialog('REMOTE_FILE_SOURCE');
    },
  },
  {
    sourceType: 'S3_SOURCE',
    name: 'S3',
    description: 'DataOriginsDialog.origin.s3.description',
    logo: `${Api.baseUrl()}/images/data/s3.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.text.file', 'bim.integration.category.spotlight'],
    onClick: (projectId) => {
      openJsfDialog('S3_SOURCE');
    },
  },
  {
    sourceType: 'Google Sheets',
    name: 'Google Sheets',
    description: 'DataOriginsDialog.origin.google.sheets.description',
    logo: `${Api.baseUrl()}/images/data/google-sheets-white.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.text.file'],
    onClick: (projectId) => {
      openJsfDialog('GOOGLE_SHEETS');
    },
  },
  {
    sourceType: 'Json',
    name: 'Json',
    description: 'DataOriginsDialog.origin.json.description',
    logo: `${Api.baseUrl()}/images/data/json-source-icon.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.digital.services'],
    onClick: () => {
      openEulaDialog(`#{projectSelectionMB.inMemoryCRUD.createGenericStructure('JSON')}`, 'jsf-JSON');
    },
  },
  {
    sourceType: 'GenericFileSource.XML',
    name: 'Xml',
    description: 'GenericFileSource.XML.description',
    logo: `${Api.baseUrl()}/images/data/xml.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.text.file', 'bim.integration.category.spotlight'],
    onClick: (projectId) => {
      openJsfDialog('GenericFileSource.XML');
    },
  },
  {
    name: 'GitHub',
    description: 'DataOriginsDialog.origin.github.description',
    logo: `${Api.baseUrl()}/images/data/github-black.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.digital.services'],
    onClick: (projectId) => {
      openCustomDialog('GIT_HUB', projectId);
    },
  },
  {
    name: 'Api4Com (Business)',
    description: 'DataOriginsDialog.origin.api4com.description',
    logo: `${Api.baseUrl()}/images/data/api4com.png`,
    connectionType: 'bim.integration.data.connector',
    tags: ['bim.integration.category.digital.services'],
    onClick: (projectId) => {
      openCustomDialog('API4COM_CDRS', projectId);
    },
  },
  {
    name: 'Machine Learning Predict',
    description: 'DataOriginsDialog.origin.machine.learning.prediction.description',
    logo: `${Api.baseUrl()}/images/data/ml.png`,
    connectionType: 'bim.integration.machine.learning',
    tags: ['bim.integration.category.digital.services'],
    tagIsComing: true,
    keepModalOpen: true,
    onClick: (projectId) => {
      UiMsg.warn(context.msg.t('coming.soon'));
    },
  },
  {
    name: 'Machine Learning EAD',
    description: 'DataOriginsDialog.origin.machine.learning.ead.description',
    logo: `${Api.baseUrl()}/images/data/ml.png`,
    connectionType: 'bim.integration.machine.learning',
    tags: ['bim.integration.category.digital.services'],
    tagIsComing: true,
    keepModalOpen: true,
    onClick: (projectId) => {
      UiMsg.warn(context.msg.t('coming.soon'));
    },
  },
];

const initialConnections = [
  {
    name: 'bim.integration.app.analysis',
    className: 'BngConnexionRed',
  },
  {
    name: 'bim.integration.big.data',
    className: 'BngConnexionOrange',
  },
  {
    name: 'bim.integration.data.connector',
    className: 'BngConnexionBlue',
  },
  {
    name: 'bim.integration.machine.learning',
    className: 'BngConnexionGreen',
  },
];

export const DONT_NEED_CONNECTION = [
  'currencyexchange',
  'economicexpectation',
  'finantialdefault',
  'IBGE',
  'inflation',
  'marketexpectations',
  'nationalaccountingindicators',
  'nationalproductionindicators',
  'weather',
];

export default function DataOriginsDialog({
  projectId,
  closeModal,
  onClose,
  initialSearch = '',
  onSelect,
  currentSelection,
  isConnections = false,
  connectionsProps = {},
}) {
  const context = useBimContext();

  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const [connections, setConnections] = useState(initialConnections);
  const [filteredConnections, setFilteredConnections] = useState([]);

  const [integrations, setIntegrations] = useState(
    defaultIntegration(context)
      .filter((origin) => (onSelect ? !!origin.sourceType : true))
      .filter((i) => (i.hasOwnProperty('visible') ? i.visible === true : true))
      .filter((i) => (isConnections ? (i.name === 'Database' || i.name === 'UMovMe') : true))
  );

  const [filteredIntegrations, setFilteredIntegrations] = useState([]);

  const [integrationRequest, setIntegrationRequest] = useState(null);
  const [integrationRequestLoading, setIntegrationRequestLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState(initialSearch);

  useEffect(() => {
    setFilteredIntegrations(integrations);

    let tempCategories = [
      {
        name: 'bim.integration.category.spotlight',
        icon: 'grade',
      },
    ];

    let tempConnections = [];

    integrations.forEach((origins) => {
      origins.tags.forEach((category) => {
        const hasCategory = tempCategories.find((c) => c.name === category);
        if (!hasCategory)
          tempCategories.push({
            name: category,
            icon: null,
          });
      });

      if (origins.connectionType && !tempConnections.some((c) => c.name === origins.connectionType)) {
        const matchedConnections = initialConnections.find(
            (connection) => connection.name === origins.connectionType
        );
        tempConnections.push({
          name: origins.connectionType,
          className: matchedConnections.className,
        });
      }
    });

    setConnections(tempConnections);
    setCategories(tempCategories);
  }, [integrations]);

  function initFilteredConnections() {
    setFilteredConnections(connections);
  }

  useEffect(initFilteredConnections, [connections]);

  useEffect(() => {
    const fetchBimIntegrationEndpoints = () => {
      Api.BimIntegration.findEndpoints()
        .then((endpoints) => {
          if (_.isEmpty(endpoints)) {
            return;
          }
          setIntegrations((currentIntegrations) => [
            ...currentIntegrations,
            ...endpoints
              .filter((e) => !e.disabled)
              .filter((e) => (isConnections ? !DONT_NEED_CONNECTION.includes(e.route) : true))
              .map((endpoint) => ({
                sourceType: 'WS',
                ...endpoint,
                tags: ['bim.integration.category.digital.services'],
                onClick: () => {
                  openEulaDialog(
                    `#{projectSelectionMB.inMemoryCRUD.newWsStructure('BIM_INTEGRATION', '${endpoint.route}')}`,
                    `int-${endpoint.route}`
                  );
                },
              })),
          ]);
        })
        .catch((e) => console.error('Error on findEndpoints()', e));

      if (!isConnections) {
        Api.BimIntegration.findExternalEndpoints()
          .then((externalEndpoints) => {
            if (_.isEmpty(externalEndpoints)) {
              return;
            }
            setIntegrations((currentIntegrations) => [
              ...currentIntegrations,
              ...externalEndpoints.map((endpoint) => ({
                ...endpoint,
                keepModalOpen: true,
                connectionType: 'bim.integration.data.connector',
                tags: ['bim.integration.category.digital.services'],
                onClick: () => {
                  setIntegrationRequest(endpoint);
                },
              })),
            ]);
          })
          .catch((e) => {
            console.error('Error on findExternalEndpoints()', e);
          });
      }
    };

    fetchBimIntegrationEndpoints();
  }, []);

  useEffect(() => {
    let tempFilteredOrigins = [...integrations];

    tempFilteredOrigins = tempFilteredOrigins.filter((origin) => {
      let shouldFilter = false;
      origin.tags.forEach((originTag) => {
        const category = filteredCategories.find((category) => category.name === originTag);
        if (category) shouldFilter = true;
      });

      if (_.isEmpty(filteredCategories)) shouldFilter = true;

      const connection = filteredConnections.find((fc) => fc.name === origin.connectionType);
      if (connection) return shouldFilter;
      return false;
    });

    tempFilteredOrigins = tempFilteredOrigins.filter((i) => i.name.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredIntegrations(tempFilteredOrigins);
  }, [filteredCategories, filteredConnections, integrations, searchTerm]);

  const toggleFilterCategory = (category, isChecked) => {
    if (isChecked) {
      return setFilteredCategories([...filteredCategories, category]);
    }
    setFilteredCategories((currentCategories) => {
      return currentCategories.filter((c) => c.name !== category.name);
    });
  };

  const toggleFilterConnection = (connection, isChecked) => {
    if (isChecked) {
      return setFilteredConnections([...filteredConnections, connection]);
    }
    setFilteredConnections((currentConnections) => {
      return currentConnections.filter((c) => c.name !== connection.name);
    });
  };

  const handleSearch = (searchTerm = '') => {
    setSearchTerm(searchTerm);
    Utils.History.updateQuery(
      {
        [DATA_ORIGINS_DIALOG_QUERY_PARAM]: searchTerm ?? '',
      },
      { replace: true }
    );
  };

  const getClassNameForConnectionType = (connectionType) => {
    if (connectionType) {
      const connection = connections.find((con) => con.name === connectionType);
      if (connection) return connection.className;
    }
    return '';
  };

  const handleSuggestionIntegrationSubmit = async (values, actions) => {
    const { setSubmitting } = actions;
    try {
      setSubmitting(true);

      const newFeatureType = '00e22d60debbcb79e1dc109c379c4a14';
      const normalPriority = 2;
      const title = `[INTEGRADORES] - Sugestão de integrador ${values.title}`;

      const ticket = Object.assign(
        { projectId: context.support.projectName, type: newFeatureType, priority: normalPriority },
        values,
        { title }
      );
      await Api.Support.newTicket(ticket, []);
    } finally {
      setSubmitting(false);
    }
  };

  const handleIntegrationRequestSubmit = async (values, actions) => {
    const { setSubmitting } = actions;
    try {
      setSubmitting(true);

      const normalPriority = 2;
      const title = context.msg.t('BngIntegrationRequest.ticket.title', [integrationRequest.name]);
      const data = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
      const description = context.msg.t('BngIntegrationRequest.ticket.description', [
        context.user.displayName,
        context.user.email,
        integrationRequest.name,
        context.project.displayName,
        data,
      ]);

      const ticket = Object.assign(
        {
          projectId: context.support.projectName,
          type: 'b1b0cc6e23e80ba808ef0d37d2368523',
          department: '93fed457f4d749ca514cedf8958f1181',
          priority: normalPriority,
        },
        { title, description }
      );

      setIntegrationRequestLoading(true);
      await Api.Support.newTicket(ticket, []);
      setIntegrationRequestLoading(false);
    } finally {
      setSubmitting(false);
    }
  };

  const handleOriginDescriptionTranslation = (description) => {
    const translatedDescription = context.msg.t(description);
    if (translatedDescription.includes('???')) return description;
    return translatedDescription;
  };

  const handleCreateConnection = (origin) => {
    closeModal();
    if (origin.sourceType === 'SQL') {
      ReduxStore.dispatch(
        MODALS.open(DatabaseConnectionDialog, {
          ...connectionsProps,
        })
      );
    } else if (origin.sourceType === 'UMovMe') {
      ReduxStore.dispatch(
        MODALS.open(DatabaseConnectionDialog, {
          ...connectionsProps,
          defaultType: 'UMovMe',
        })
      );
    } else {
      ReduxStore.dispatch(
        MODALS.open(IntegrationConnectionDialog, {
          name: origin.route,
          fetchConnections: connectionsProps.fetchConnections,
          onChange: connectionsProps.onChange,
        })
      );
    }
  };

  return (
    <Dialog
      title={context.msg.t('pageTitleDataSource')}
      className="DialogResponsive"
      onClose={(e) => {
        closeModal(e);
        onClose?.();
      }}
      newDialogLayout
      contentFullWidth
    >
      <div className="DataOriginsContainer">
        <div className="DataOriginsBody">
          <div className="DataOriginsMenu">
            <BngSearch
              simple={true}
              alwaysOpen={true}
              value={searchTerm}
              onChange={handleSearch}
              placeholder={context.msg.t('DataOriginsDialog.search.integration')}
              className="DataOriginsSearch"
              name="data-origin-name"
            />
            <div className="DataOriginsFilters">
              <Accordion title={context.msg.t('DataOriginsDialog.connection.type')}>
                <div className="BngForm">
                  {connections.map((connection) => {
                    const isChecked = !!filteredConnections.find((fc) => fc.name === connection.name);
                    return (
                      <div key={connection.name} className="DataOriginsFilterWrapper">
                        <BngCheckbox
                          onChange={(e) => toggleFilterConnection(connection, e.target.checked)}
                          className={`DataOriginsFilterTag ${connection.className}`}
                          field={{ value: isChecked }}
                          label={context.msg.t(connection.name)}
                        />
                      </div>
                    );
                  })}
                </div>
              </Accordion>
              <Accordion title={context.msg.t('DataOriginsDialog.categories')}>
                <Formik>
                  <BngForm className="DataOriginsTagForm">
                    {categories.map((category) => (
                      <div key={category.name} className="DataOriginsFilterWrapper">
                        {category.icon && <Icon type={Icon.MATERIAL} icon={category.icon} />}
                        <Field
                          className={'DataOriginsFilterTag'}
                          name={category.name}
                          label={context.msg.t(category.name)}
                          onChange={(e) => toggleFilterCategory(category, e.target.checked)}
                          component={BngCheckbox}
                        />
                      </div>
                    ))}
                  </BngForm>
                </Formik>
              </Accordion>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', overflow: 'auto' }}>
            <div className="DataOriginsCards">
              {integrationRequest ? (
                <BngIntegrationRequest
                  title={integrationRequest.name}
                  img={integrationRequest.logo}
                  loading={integrationRequestLoading}
                  onCancel={() => setIntegrationRequest(null)}
                  onSubmit={handleIntegrationRequestSubmit}
                />
              ) : (
                <>
                  {_.isEmpty(filteredIntegrations) ? (
                    <BngSuggestion
                      title={context.msg.t('DataOriginsDialog.not.found.title')}
                      message={context.msg.t('DataOriginsDialog.not.found.subtitle')}
                      inputTitleLabel={context.msg.t('DataOriginsDialog.not.found.input.label')}
                      inputTitlePlaceholder={context.msg.t('DataOriginsDialog.not.found.input.placeholder')}
                      onSubmit={handleSuggestionIntegrationSubmit}
                    />
                  ) : (
                    filteredIntegrations.map((origin, idx) => {
                      const isSelected =
                        currentSelection &&
                        origin.sourceType === currentSelection.sourceType &&
                        (origin.route ? origin.route === currentSelection.bimIntegrationName : true);

                      const isPremium =
                        Utils.Project.isDemo(context.project) &&
                        context.planSelectionEnabled &&
                        origin.keepModalOpen &&
                        origin.connectionType !== 'bim.integration.machine.learning';

                      const numberLabel = isConnections
                        ? origin.name === 'Database'
                          ? connectionsProps.countConnection.Database
                          : connectionsProps.countConnection[origin.route]
                        : '';

                      return (
                        <div key={idx} className="CardWrapper">
                          <BngHorizontalCard
                            title={origin.name}
                            text={handleOriginDescriptionTranslation(origin.description)}
                            image={origin.logo}
                            className={`${getClassNameForConnectionType(origin.connectionType)} ${
                              isSelected ? 'Selected' : ''
                            }`}
                            isBeta={origin.tagIsBeta}
                            isNew={origin.tagIsNew}
                            isComing={origin.tagIsComing}
                            showNumberInLabel={numberLabel}
                            isPremium={isPremium}
                            tooltip={isPremium ? context.msg.t('premium_origins_tooltip') : undefined}
                            onClick={() => {
                              if (isConnections) {
                                handleCreateConnection(origin);
                              } else if (isPremium) {
                                closeModal();
                                ReduxStore.dispatch(MODALS.open(ActivateBimDialog));
                              } else {
                                if (onSelect) {
                                  if (isSelected) {
                                    closeModal();
                                  } else {
                                    onSelect({
                                      origin,
                                      closeModal,
                                    });
                                  }
                                } else {
                                  if (!origin.keepModalOpen) {
                                    closeModal();
                                  }
                                  origin.onClick(projectId);
                                }
                              }
                            }}
                            preventScroll={false}
                          />
                        </div>
                      );
                    })
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
