import styles from './AdaAiButton.module.css';

import React, { useState } from 'react';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useTranslation from 'components/hooks/useTranslation';
import AdaAiChat from 'components/bng/adaAi/AdaAiChat';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

export default function AdaAiButton({ item, dashboardPath, filters, assistantKey }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const $dashCtx = useDashboardPageCtx();
  const [adaChat, setAdaChat] = useState({ messages: [] });

  return (
    <div className={`AdaAiButton ${styles.AdaAiButton}`}>
      <BngIconButton
        icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Ada AI" />}
        className={`AdaAi ${styles.AdaAi}`}
        text={t('sql.ada.ia.query.assistant.button')}
        onClick={async () => {
          try {
            const [assistant] = await Api.AdaAi.findAll(assistantKey);
            if (!assistant) {
              throw new Error(`Assistant with key "${assistantKey}" not found.`);
            }

            const metadataObject = await Api.Metadata.exportDashboardMetadata({
              content: dashboardPath,
              filters: !_.isEmpty($dashCtx?.filters) ? $dashCtx?.filters : filters,
              itemId: item?.id,
              queryResultLimit: assistant.props.queryResultLimit,
              params: {},
            });

            dispatch(
              MODALS.open(AdaAiChat, {
                initialMessages: adaChat.messages,
                initialAssistantKey: assistantKey,
                metadataObject,
                onMessages: ({ messages = [] }) => {
                  setAdaChat({
                    ...adaChat,
                    messages: messages.slice(),
                  });
                },
              })
            );
          } catch (e) {
            console.error('Error while trying to get the metadata', e);
            UiMsg.warn(t('ada.ai.get.the.metadata.error'));
          }
        }}
      />
    </div>
  );
}
