import './LineColorPicker.css';

import React from 'react';

import BngInputColor from 'components/bng/form/BngInputColor';
import Icon from 'components/ui/common/Icon';

function lightenDarkenColor(col, amt) {
  col = parseInt(col.slice(1), 16);
  return (
    '#' + (((col & 0x0000ff) + amt) | ((((col >> 8) & 0x00ff) + amt) << 8) | (((col >> 16) + amt) << 16)).toString(16)
  );
}

class LineColorPicker extends React.Component {
  static defaultProps = {
    options: [],
    showCustom: false,
    value: null,
    onChange: _.noop,
    label: null,
    addTransparentOptions: true,
  };

  render() {
    const { options, showCustom, value, label, onChange, ...props } = this.props;

    const found = options.find((o) => o.toLowerCase() === value?.toLowerCase());
    const isCustomValue = value && !found;

    return (
      <div className="BngLineColorPicker">
        {label && <label className="control-label">{label}</label>}

        <div className="BngLineColorPicker__container">
          {options.map((o, i) => (
            <div className="BngLineColorPicker__itemContainer" key={i}>
              <div
                className={`BngLineColorPicker__item ${
                  value?.toLowerCase() === o.toLowerCase() ? 'BngLineColorPicker__item--active' : ''
                }`}
                onClick={() => onChange(o)}
                style={{ backgroundColor: o, border: `1px solid ${lightenDarkenColor(o, -50)}` }}
              ></div>
            </div>
          ))}

          {showCustom && (
            <BngInputColor
              className={`BngLineColorPicker__customPicker ${
                isCustomValue && 'BngLineColorPicker__customPicker--active'
              }`}
              buttonIcon={
                !isCustomValue
                  ? ({ buttonSize }) => (
                      <div className="flex-center-items" style={{ width: buttonSize, height: buttonSize }}>
                        <Icon icon={'add'} />
                      </div>
                    )
                  : undefined
              }
              form={{ setFieldValue: (name, t) => onChange(t) }}
              field={{ value: isCustomValue && value, onChange: _.noop }}
              {...props}
            />
          )}
        </div>
      </div>
    );
  }
}

export default LineColorPicker;
