import styles from './QueryResultPreview.module.css';

import React, { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { dateComparator } from 'components/bng/pages/bigTable/functions';
import { AgGridReact } from 'components/bng/pages/bigTable/AGTable';
import useEventBus from 'components/hooks/useEventBus';
import Utils from 'components/Utils';

const columnFilter = (columnType) => {
  switch (columnType) {
    case 'Measure':
      return 'agNumberColumnFilter';
    case 'TimeDimension':
      return 'agDateColumnFilter';
    default:
      return 'agTextColumnFilter';
  }
};

export default function QueryResultPreview({ queryResult }) {
  const { values } = useFormikContext();

  const gridRef = useRef();

  const [colDef, setColDef] = useState([]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (_.isEmpty(queryResult)) {
      setRowData([]);
      setColDef([]);
    } else {
      const columns = [];
      const rows = [];
      const { fieldConfigs } = values;
      const fieldsNames = {};
      fieldConfigs.forEach((result) => {
        fieldsNames[result.fieldName] = result.caption;
        const temp = {
          field: result.caption,
          filter: columnFilter(result.dimensionType),
          filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
          },
          // headerComponent: PreviewCustomHeader, por enquanto vai ficar comentado
          fieldName: result.fieldName,
          suppressHeaderKeyboardEvent: (params) => ['ArrowLeft', 'ArrowRight'].includes(params.event.key),
          cellStyle: {
            fontSize: '11px',
          },
          headerClass: `${styles.ColumnHeader}`,
          resizable: true,
          width: 120,
        };

        if (result.dimensionType === 'TimeDimension') {
          temp.comparator = dateComparator;
          temp.valueFormatter = (data) => Utils.Date.formatDate(data.value);
          temp.filterParams = {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const filter = moment(filterLocalDateAtMidnight.getTime()).format('DD/MM/YYYY');
              const cell = moment(cellValue).format('DD/MM/YYYY');
              if (filter === cell) {
                return 0;
              } else if (filter < cellValue) {
                return -1;
              } else if (filter > cellValue) {
                return 1;
              }
            },
            buttons: ['apply', 'reset'],
          };
        }

        columns.push(temp);
      });

      queryResult.forEach((result, idx) => {
        rows.push({});
        result.forEach((r) => {
          rows[idx] = { ...rows[idx], [`${fieldsNames[r.value0]}`]: r.value1 };
        });
      });

      setColDef(columns);
      setRowData(rows);
    }
  }, [queryResult, values.fieldConfigs]);

  useEventBus(
    QueryResultPreview.EXPORT_CSV_EVENT,
    () => {
      if (!gridRef.current?.api) {
        return;
      }
      gridRef.current.api.exportDataAsCsv({
        skipHeader: false,
        skipFooters: true,
        skipGroups: true,
        fileName: 'export.csv',
      });
    },
    [gridRef.current]
  );

  return (
    <div className={`QueryResultPreview ag-theme-alpine ${styles.TablePreviewWrapper}`}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        rowHeight={20}
        headerHeight={15}
        columnDefs={colDef}
        suppressMovableColumns={true}
        suppressHeaderFocus={true}
      />
    </div>
  );
}

QueryResultPreview.EXPORT_CSV_EVENT = 'QueryResultPreview:EXPORT_CSV_EVENT';
