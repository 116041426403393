import './MenuButton.css';
import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from 'components/ContextEnhancer';
import Toggleable from 'components/ui/Toggleable';
import Icon from 'components/ui/common/Icon';

class MenuButtonSearch extends Toggleable {
  static propTypes = {
    actionDisable: PropTypes.bool,
    clearSearch: PropTypes.func,
    enableSearch: PropTypes.bool,
    onSearchChange: PropTypes.func,
    startOpen: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    actionDisable: false,
    startOpen: false,
    placeholder: null,
  };

  state = {
    open: false,
  };

  constructor(props) {
    super(props);
    this.state.open = props.startOpen ?? false;
    this.$textInput = React.createRef();
  }

  componentDidMount() {
    if (this.state.open) {
      this.focusOnInput();
    }
  }

  focusOnInput = () => {
    this.$textInput.current?.focus?.();
  };

  render() {
    const { actionDisable, value } = this.props;
    const { open } = this.state;

    return (
      <div
        style={actionDisable ? { marginRight: -80 } : {}}
        className={`search-menu-button-container ${open ? 'open' : ''}`}
      >
        {open ? (
          <div className="search-menu-button-container-enable">
            <Icon icon="search" className="search-menu-button-icon" />
            <input
              style={actionDisable ? { width: 330 } : { width: 250 }}
              type="text"
              className="search-menu-button-input"
              value={value}
              placeholder={
                this.props.placeholder ? this.props.placeholder : this.props.context.msg.t('search.field.placeholder')
              }
              onChange={(e) => {
                const searchTerm = e.target.value ?? '';
                this.props.onSearchChange(searchTerm);
              }}
              ref={this.$textInput}
            />
            <Icon
              icon="close"
              className="search-menu-button-icon-close"
              onClick={() => {
                this.props.clearSearch();
                this.setState({ open: false });
              }}
            />
          </div>
        ) : (
          <div className="search-menu-button-container-disable">
            <Icon
              icon="search"
              className="search-menu-button-icon"
              onClick={() => this.setState({ open: true }, this.focusOnInput)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ContextEnhancer(MenuButtonSearch);
