import styles from './NavPanel.module.css';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import useBimContext from 'components/hooks/useBimContext';
import UiBlocker from 'components/bng/ui/UiBlocker';
import BngTag from 'components/bng/ui/BngTag';

export const TAB_QUERY_PARAM = 'currentTab';
const ANCHOR_QUERY_PARAM = 'anchorId';

function findTab({ key, tabs = [], account, context }) {
  for (const tab of tabs) {
    if (tab.key === key) {
      const disabled = tab.shouldDisable?.({ account, context }) ?? false;
      if (disabled) {
        return null;
      } else {
        return tab;
      }
    }

    if (tab.children) {
      const childTab = findTab({ key, tabs: tab.children, account, context });
      if (childTab) {
        return childTab;
      }
    }
  }
  return null;
}

function TabNavItem({
  tab,
  onClick = _.noop,
  onExpandTab = _.noop,
  expanded = false,
  isSelected,
  expandOnClick = false,
  disabled = false,
  soonTag = false,
  account,
}) {
  const { t } = useTranslation();

  const containChildren = !_.isEmpty(tab.children);
  return (
    <>
      <div
        className={`TabNavItem ${styles.tabItem}  ${isSelected ? styles.selectedTab : ''} ${
          disabled ? 'disabled' : ''
        }`}
        onClick={() => {
          if (disabled) {
            return;
          }

          const fn = (expandOnClick ? onExpandTab : onClick) ?? _.noop;
          return fn(tab.key, null);
        }}
      >
        <div className={`${styles.tabTitle}`}>
          <Icon className={`${styles.tabIcon}`} icon={tab.icon} />
          <span>{t(tab.label)}</span>
        </div>
        {_.isNil(tab.component) && (
          <Icon className={`${styles.tabIcon}`} icon="new_releases" style={{ color: '#F98900', marginRight: '15px' }} />
        )}
        {!disabled && containChildren && (
          <BngIconButton
            icon={expanded ? 'expand_less' : 'expand_more'}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (!disabled) {
                onExpandTab(tab.key);
              }
            }}
          />
        )}
        {disabled && soonTag && <BngTag description={t('coming.soon')} className={styles.soonTag} />}
      </div>
      {containChildren &&
        tab.children
          .filter((tabChildren) => !tabChildren.shouldNotRender?.({ account }))
          .map((tabChildren, idx) => {
            const lastChild = idx + 1 === tab.children.length;
            return (
              <div
                key={idx}
                className={`${styles.tabChildren} ${expanded ? '' : styles.notVisible} ${
                  lastChild ? styles.lastChild : ''
                }`}
                onClick={() => onClick(tabChildren.key ?? tab.key, tabChildren.anchorId)}
              >
                <span>{t(tabChildren.title)}</span>
              </div>
            );
          })}
    </>
  );
}

export default function NavPanel({
  account,
  accountOpts,
  onSelectAccount,
  tabs = [],
  defaultTab = tabs[0],
  tabProps = {},
  accountsLoading = false,
  showSelector = true,
}) {
  const { t } = useTranslation();
  const context = useBimContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [expandedTabs, setExpandedTabs] = useState([]);
  const selectedTab =
    findTab({
      key: searchParams.get(TAB_QUERY_PARAM),
      tabs,
      account,
      context,
    }) || defaultTab;

  useEffect(() => {
    const queryTab = searchParams.get(TAB_QUERY_PARAM) || defaultTab.key;
    const anchorId = searchParams.get(ANCHOR_QUERY_PARAM);
    onSelectTab(queryTab, anchorId, false);

    if (anchorId) {
      document.getElementById(anchorId)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [searchParams]);

  const onSelectTab = (tabKey = defaultTab.key, anchorId, shouldUpdateQuery = true) => {
    const tab =
      findTab({
        key: tabKey,
        tabs,
        account: account,
        context: context,
      }) || defaultTab;

    const defaultAction = () => {
      if (shouldUpdateQuery) {
        searchParams.set(TAB_QUERY_PARAM, tab.key);
        if (anchorId) {
          searchParams.set(ANCHOR_QUERY_PARAM, anchorId);
        } else {
          searchParams.delete(ANCHOR_QUERY_PARAM);
        }
        setSearchParams(searchParams);
      }
    };

    if (tab.onSelect) {
      tab.onSelect({ defaultAction });
    } else if (!tab.shouldDisable?.({ account, context })) {
      defaultAction();
    }
  };

  const onExpandTab = (tabKey) => {
    if (expandedTabs.includes(tabKey)) {
      expandedTabs.splice(expandedTabs.indexOf(tabKey), 1);
    } else {
      expandedTabs.push(tabKey);
    }
    setExpandedTabs([...expandedTabs]);
  };

  const onlyOneAccount = showSelector ? accountOpts.length === 1 : 0;

  return (
    <>
      <div className={`NavPanel ${styles.NavPanel}`}>
        <div className={`${styles.accountTabs}`}>
          {tabs
            .filter((tab) => !tab.shouldNotRender?.({account, context}))
            .map((tab, idx) => {
              const disabled = tab.shouldDisable?.({ account, context }) ?? false;
              return (
                <TabNavItem
                  key={idx}
                  tab={tab}
                  onClick={onSelectTab}
                  onExpandTab={onExpandTab}
                  expanded={expandedTabs.includes(tab.key) && !_.isEmpty(tab.children)}
                  isSelected={selectedTab.key === tab.key}
                  disabled={disabled}
                  soonTag={tab.soonTag}
                  account={account}
                  expandOnClick={tab.expandOnClick}
                />
              );
            })}
        </div>
        {showSelector && (
          <BngSelectSearch
            options={accountOpts}
            form={{ setFieldValue: (name, accountId) => onSelectAccount(accountId) }}
            field={{
              value: account?.id,
              onChange: _.noop,
            }}
            previewComponent={({ toggleDropdown }) => {
              return (
                <UiBlocker block={accountsLoading} className={styles.accountsSpinner}>
                  <div
                    className={`AccountSelect ${styles.AccountSelect}`}
                    onClick={onlyOneAccount ? _.noop : toggleDropdown}
                  >
                    <div>{t('selected.account')}</div>
                    <div className={`${styles.accountName}`}>
                      {account.name}
                      {!onlyOneAccount && <Icon icon={'expand_more'} />}
                    </div>
                  </div>
                </UiBlocker>
              );
            }}
          />
        )}
      </div>
      <div className={`${styles.tabContent}`}>
        <selectedTab.component account={account} onSelectTab={onSelectTab} {...tabProps} />
      </div>
    </>
  );
}
