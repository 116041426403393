import styles from './AccStructuresTab.module.css';
import React, { useMemo, useState } from 'react';
import useFetchData from 'components/hooks/useFetchData';
import UiMsg from 'components/ui/UiMsg';
import useTranslation from 'components/hooks/useTranslation';
import Api from 'components/Api';
import UiBlocker from 'components/bng/ui/UiBlocker';
import BngTable from 'components/bng/ui/BngTable';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useBimContext from 'components/hooks/useBimContext';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import { ProjectType } from 'components/bng/project/NewProjectDialog';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import bngYup from 'components/bng/form/yup/BngYup';
import { TypeIcon } from 'components/bng/pages/admin/structures/StructuresPage';
import StandaloneBarChart from 'components/bng/analysis/echarts/StandaloneBarChart';

const buildStructuresColumns = ({ t }) => {
  return [
    {
      key: 'id',
      label: t('id'),
      sortable: true,
      size: 20,
      colClassName: styles.colEllipsis,
      render: (row) => {
        return (
          <div>
            <span>{row.origin.id}</span>
          </div>
        );
      },
    },
    {
      key: 'name',
      label: t('name'),
      sortable: true,
      size: 100,
      render: (row) => {
        return (
          <div className={styles.userNameWrapper}>
            <Icon icon={row.origin.icon} className={styles.structureIcon} />
            <span>{row.origin.caption}</span>
          </div>
        );
      },
    },
    {
      key: 'type',
      label: t('type'),
      sortable: true,
      size: 100,
      render: (row) => {
        return <span>{t(row.origin.type)}</span>;
      },
    },
    {
      key: 'tableSize',
      label: t('size'),
      sortable: true,
      size: 120,
      render: (row) => (row.origin.tableSize ? Utils.fileSizeSI(row.origin.tableSize, 0) : ''),
    },
    {
      key: 'project',
      label: t('project'),
      sortable: true,
      size: 120,
      render: (row) => {
        return <span>{row.project.displayName}</span>;
      },
    },
    {
      key: 'dataUpdatedAt',
      label: t('updated'),
      sortable: true,
      size: 160,
      render: (row) => {
        const dataUpdatedAt = row.origin.dataUpdatedAt ? moment(row.origin.dataUpdatedAt) : undefined;
        return (
          <div className={styles.dataUpdateWrapper}>
            {dataUpdatedAt && (
              <div className={styles.dataUpdate} title={dataUpdatedAt.format('DD/MM/YYYY HH:mm:ss')}>
                <Icon icon={'access_time'} className={styles.dataUpdateIcon} />
                <span className={'mr-2 no-wrap'}>{dataUpdatedAt.fromNow()}</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      key: 'openProject',
      label: '',
      sortable: false,
      size: 10,
      render: (row) => {
        return (
          <BngIconButton
            onClick={() => {
              const url = Api.Bng.accessProjectUrl(row.project.name, '/pages/project-selection/dataconfig.iface');
              window.location.replace(url);
            }}
            icon={'open_in_new'}
            className={styles.openProjectButton}
          />
        );
      },
    },
  ];
};

const filterRows = ({ tableSortMode, structures, filters }) => {
  Object.entries(tableSortMode).forEach(([prop, direction]) => {
    if (direction === 'NONE') return;

    structures = _.orderBy(
      structures,
      [
        (row) => {
          let value = prop === 'project' ? row.project.displayName : row.origin[prop];
          return _.isString(value) ? value.toLowerCase() : value;
        },
      ],
      [direction.toLowerCase()]
    );
  });

  const { structureType, project, projectType, enabled } = filters;

  if (structureType) {
    structures = structures.filter((structure) => structure.origin.type === structureType);
  }

  if (project && project.length > 0) {
    structures = structures.filter((structure) => project.some((p) => p === structure.project.id.toString()));
  }

  if (projectType && projectType.length > 0) {
    structures = structures.filter((structure) => projectType.some((p) => p === structure.project.projectType));
  }

  if (enabled?.length > 0) {
    structures = structures.filter((structure) => enabled === structure.project.enabled.toString());
  }
  return structures;
};

const getStructureType = (account) => (account?.countDataStructures ? '' : 'ANALYTICAL');

const StructureFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    structureType: yup.array().of(yup.string()),
    project: yup.array().of(yup.string()),
    projectType: yup.array().of(yup.string().nullable()),
    enabled: yup.boolean().nullable(),
  });
});

const StructuresFilterDropdown = ({ rows = [], onChange = _.noop, initialValues = [], filters = {} }) => {
  const context = useBimContext();

  const typeOpts = FilterOpts({
    options: TypeIcon,
    hasIcon: true,
  });

  const projectOpts = FilterOpts({
    useIdAsValue: true,
    options: _.uniqBy(
      rows.map((structure) => {
        const project = structure.project;
        return { id: project.id, label: Utils.Project.displayName(project) };
      }),
      'id'
    ),
  });

  const projectTypeOpts = FilterOpts({
    options: ProjectType,
  });

  const filterFields = [
    {
      name: 'structureType',
      label: context.msg.t('type'),
      options: typeOpts,
      className: styles.structureTypeFilter,
    },
    {
      name: 'project',
      label: context.msg.t('project'),
      options: projectOpts,
      multiple: true,
    },
    {
      name: 'projectType',
      label: context.msg.t('project_type'),
      options: projectTypeOpts,
      multiple: true,
    },
    {
      name: 'enabled',
      label: context.msg.t('project.status'),
      options: [
        {
          label: context.msg.t('enabled'),
          value: 'true',
        },
        {
          label: context.msg.t('inactive'),
          value: 'false',
        },
      ],
    },
  ];

  return (
    <FilterDropdown
      className={styles.AccStructuresTabFilter}
      fields={filterFields}
      dropdownSchema={StructureFilterDropdownSchema}
      initialFormValues={initialValues}
      onChange={onChange}
      selectedFilters={filters}
      popperClassName={styles.AccStructuresTabFilterPopper}
    />
  );
};

export default function AccStructuresTab({ account }) {
  const { t } = useTranslation();
  const [tableSortMode, setTableSortMode] = useState({ name: 'ASC' });
  const [filters, setFilters] = useState({
    structureType: getStructureType(account),
    project: null,
    projectType: ['Production'],
    enabled: 'true',
  });

  const { data: structures, isLoading: loadingOrigins } = useFetchData(async () => {
    try {
      const originsData = await Api.Account.fetchAccountOrigins(account.id);
      setFilters((currentFilters) => ({
        ...currentFilters,
        structureType: getStructureType(account),
      }));
      return originsData;
    } catch (e) {
      console.error('Error on fetch structures function()', e);
      UiMsg.error(t('error.fetching.data', [t('structures')]));
    }
  }, [account]);

  const { data: quotaUsage, isLoading: loadingQuota } = useFetchData(async () => {
    try {
      const quotaUsage = await Api.Account.fetchQuotaUsage({ accountId: account.id });

      const formattedQuota = {};

      const calculatedDate = moment().subtract(29, 'days');
      for (let i = 0; i < 30; i++) {
        const formattedDate = Utils.Date.formatDate(calculatedDate.toDate());
        const quotasForDate = quotaUsage.usage.filter((quota) => Utils.Date.formatDate(quota.date) === formattedDate);
        if (quotasForDate.length === 0 || !formattedQuota[formattedDate]) {
          formattedQuota[formattedDate] = {
            usage: 0,
            capacity: 0,
          };
        }
        quotasForDate.forEach((quota) => {
          formattedQuota[formattedDate].usage += parseFloat(Utils.formatBytesToGb(quota.usage));
        });
        formattedQuota[formattedDate].capacity = quotaUsage.capacity[calculatedDate.format('YYYY-MM-DD')] || 0;
        const quotaRequestValue = quotaUsage.quotaRequests
            .filter(request => formattedDate === Utils.Date.formatDate(request.createdAt))
            .reduce((currentValue, item) => currentValue + item.additionalQuota, 0);
        formattedQuota[formattedDate].capacity += Utils.formatMbToGb(quotaRequestValue);

        calculatedDate.add(1, 'days');
      }

      return formattedQuota;
    } catch (e) {
      console.error('Error on fetch quota usage function()', e);
      UiMsg.error(t('error.fetching.data', [t('transfer.quota')]));
    }
  }, [account]);

  const filteredRows = useMemo(
    () =>
      filterRows({
        tableSortMode,
        structures,
        filters,
      }),
    [structures, tableSortMode, filters]
  );

  const cols = useMemo(() => buildStructuresColumns({ t }), []);

  return (
    <UiBlocker block={loadingOrigins} className={`${styles.pageWrapper} h-100`}>
      <div className={`AccStructuresTab ${styles.AccStructuresTab}`}>
        {quotaUsage && (
          <section className={styles.historyGraphSection}>
            <span className={styles.sectionTitle}>{t('structure.history')}</span>
            <UiBlocker block={loadingQuota} className={styles.quotaGraphWrapper}>
              <StandaloneBarChart
                data={Object.values(quotaUsage).map((u) => u.usage)}
                xAxisLabels={Object.keys(quotaUsage)}
                className={styles.QuotaChart}
                xLabelFormatter={(text) => {
                  return text.slice(0, text.lastIndexOf('/'));
                }}
                yLabelFormatter={(text) => {
                  return `${text.toString().replaceAll('.', ',')} GB`;
                }}
                tooltipFormatter={({ data, name, lineData }) => {
                  return `<strong>${t('date')}:</strong> ${name} <br>
                  <strong>${t('data.usage')}:</strong> ${data.toFixed(2).replaceAll('.', ',')} GB <br>
                  <strong>${t('capacity')}:</strong> ${lineData.toFixed(2).replaceAll('.', ',')} GB`;
                }}
                lineChartData={Object.values(quotaUsage).map((u) => u.capacity)}
              />
            </UiBlocker>
          </section>
        )}
        <section className={styles.tableSection}>
          <div className={styles.tableTitleWrapper}>
            <span className={styles.sectionTitle}>{t('structure.details')}</span>
            <div>
              <span key={'ProjectCounter'} className={styles.structuresCount}>
                {`${filteredRows.length} ${t('structures')}`}
              </span>
              <StructuresFilterDropdown
                onChange={(values) => {
                  setFilters(values);
                }}
                initialValues={filters}
                rows={structures}
                filters={filters}
              />
            </div>
          </div>
          <div className={styles.structuresTable}>
            <BngTable
              cols={cols}
              rows={filteredRows}
              stickyHeader={true}
              showEmptyAlert={true}
              emptyAlertProps={{ message: t('no.structures') }}
              sortHandler={(props) => {
                setTableSortMode({
                  [props.col.key]: props.currentSortMode.nextMode.name,
                });
              }}
              sortMode={tableSortMode}
            />
          </div>
        </section>
      </div>
    </UiBlocker>
  );
}
